import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Footer } from '../../blocks/footer';
import { Header } from '../../blocks/header';

import '../../react-styles/css/policy.scoped.css';
import { baseTitle } from '../auth/login';

export function Guidelines() {
  useEffect(() => {
    document.title = `${baseTitle}出品禁止物のガイドライン`;
  }, []);

  return (
    <>
      <Header />
      <main className="main">
        <section className="guideline">
          <div className="inner">
            <nav className="links">
              <ul>
                <li className="link">
                  <Link to="/">ホーム</Link>
                </li>
                <li className="link">
                  <Link to="/terms_of_use">利用規約</Link>
                </li>
                <li className="link _current">
                  <p>出品禁止物のガイドライン</p>
                </li>
              </ul>
            </nav>
            <div className="policy">
              <h2 className="policy-tit">出品禁止物のガイドライン</h2>
              <div className="policy-inner">
                <p className="policy-txt _top">
                  このガイドライン（以下、「本利用規約」といいます。）は、合同会社Amary（以下、「当社」といいます。）がこのウェブサイト上で提供するサービス（以下、「本サービス」といいます。）の利用において、出品を禁止するものを記しています。
                  <br />
                  本サービスは、基本的には建設業における余剰建材の出品が主であり、その他本サービスで不適切であると判断した場合、運営者による投稿削除などの対象になります。
                </p>
                <ul className="policy-grouplist">
                  <li className="policy-grouppart">
                    ・
                    たばこ、麻薬、向精神薬、大麻、あへん、けしがら、覚せい剤、あへん吸煙具
                  </li>
                  <li className="policy-grouppart">
                    ・
                    指定薬物（医療等の用途に供するために輸入するものも含む）、医薬品、医療機器
                  </li>
                  <li className="policy-grouppart">
                    ・
                    けん銃、小銃、機関銃、砲、これらの銃砲弾及びけん銃部品、殺傷能力のある武器として使用できる物品
                  </li>
                  <li className="policy-grouppart">
                    ・ 爆発物、火薬類、危険物や安全性に問題がある物品
                  </li>
                  <li className="policy-grouppart">
                    ・
                    化学兵器の禁止及び特定物質の規制等に関する法律第2条第3項に規定する特定物質
                  </li>
                  <li className="policy-grouppart">
                    ・
                    感染症の予防及び感染症の患者に対する医療に関する法律第6条第20項に規定する一種病原体等及び同条第21項に規定する二種病原体等
                  </li>
                  <li className="policy-grouppart">
                    ・
                    貨幣、紙幣、銀行券、印紙、郵便切手又は有価証券の偽造品、変造品、模造品及び偽造カード（生カードを含む）、領収証や公的証明書類
                  </li>
                  <li className="policy-grouppart">
                    ・ 公安又は風俗を害すべき書籍、図画、彫刻物その他の物品
                  </li>
                  <li className="policy-grouppart">
                    ・児童ポルノやそれに類するとみなされる物品
                  </li>
                  <li className="policy-grouppart">
                    ・特許権、実用新案権、意匠権、商標権、著作権、著作隣接権、回路配置利用権又は育成者権を侵害する物品
                  </li>
                  <li className="policy-grouppart">
                    ・不正競争防止法第2条に掲げる行為を組成する物品、盗品など不正な経路で入手した物品
                  </li>
                  <li className="policy-grouppart">
                    ・犯罪や違法行為、不法行為に使用される可能性がある物品
                  </li>
                  <li className="policy-grouppart">
                    ・法令や条例、規則、規約にて、禁止や利用制限がある、または第三者との契約中の物品
                  </li>
                  <li className="policy-grouppart">
                    ・その他、当社が不適切と判断した物品
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
