import { Link } from 'react-router-dom';
import { TProduct } from '../../pages/items/edit';
import '../../react-styles/css/about.scoped.css';
import '../../react-styles/css/about_logged.scoped.css';

type TProps = {
  product: TProduct;
};

export function Nav({ product }: TProps) {
  return (
    <nav className="links">
      <ul>
        <li className="link">
          <Link to="/">ホーム</Link>
        </li>
        <li className="link">
          <Link to={`/items/search/${product.categories[0].id}`}>
            {product.categories[0]?.title}
          </Link>
        </li>
        {product.categories[1] && (
          <li className="link">
            <Link to={`/items/search/${product.categories[1].id}`}>
              {product.categories[1]?.title}
            </Link>
          </li>
        )}
        <li className="link _current">
          <p>{product.title}</p>
        </li>
      </ul>
    </nav>
  );
}
