import { TProduct } from '../../pages/items/edit';
import '../../react-styles/css/about.scoped.css';
import '../../react-styles/css/about_logged.scoped.css';

export function Details({ product }: { product: TProduct }) {
  return (
    <div className="product-details">
      <p className="product-subhead">商品説明</p>
      <div className="product-subcnt">
        <p>{product.description}</p>
      </div>
      {product.warrantyInformation && (
        <>
          <p className="product-subhead">保証の内容</p>
          <div className="product-subcnt">
            <p>{product.warrantyInformation}</p>
          </div>
        </>
      )}
      {product.costOfDeliveryDescription && (
        <>
          <p className="product-subhead">送料について(備考)</p>
          <div className="product-subcnt">
            <p>{product.costOfDeliveryDescription}</p>
          </div>
        </>
      )}
    </div>
  );
}
