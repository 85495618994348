import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { UserContext } from '../../../App';
import { Footer } from '../../../blocks/footer';
import { Header } from '../../../blocks/header';
import eyevisi from '../../../react-styles/img/common/common-icon_eyevisi.svg';
import eyehide from '../../../react-styles/img/common/common-icon_eyehide.svg';
import { ApiService } from '../../../services/api';

import '../../../react-styles/css/top.scoped.css';
import '../../../react-styles/css/login.scoped.css';

export const baseTitle = 'アマリー | ';

export function Login() {
  const { setToken, user } = useContext(UserContext);
  const [err, setErr] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = `${baseTitle}Users`;
  }, []);

  useEffect(() => {
    if (user) {
      navigate('/mypages');
    }
  }, [user]);

  async function handleSubmit(event: any) {
    try {
      event.preventDefault();
      const { email, password } = event.target;
      const data = await ApiService.postEvent('auth/login', null, {
        email: email.value,
        password: password.value,
      });
      localStorage.setItem('token', data.accessToken);
      setToken(data.accessToken);
    } catch (error: any) {
      console.log(error);
      if (error?.status === 401 || error?.statusCode === 400) {
        setErr(true);
      }
    }
  }

  const [show, setShow] = useState(false);

  return (
    <>
      <Header />
      <main className="main">
        <section className="login">
          <div className="inner">
            <nav className="links">
              <ul>
                <li className="link">
                  <Link to="/">ホーム</Link>
                </li>
                <li className="link _current">
                  <p>ログイン</p>
                </li>
              </ul>
            </nav>
            <h2 className="login-tit">ログイン</h2>
            {err && (
              <p className="__common_error_msg">
                メールアドレスかパスワードが違います
              </p>
            )}
            <form onSubmit={handleSubmit} id="login">
              <div className="login-item">
                <label htmlFor="email">メールアドレス</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="amary@amary.jp"
                  required
                />
              </div>
              <div className="login-item">
                <label htmlFor="password">パスワード</label>
                <span className="visibility">
                  <input
                    type={show ? 'text' : 'password'}
                    name="password"
                    id="password"
                    pattern="[a-zA-Z0-9]+"
                    required
                  />
                  <img
                    onClick={() => setShow((state) => !state)}
                    src={!show ? eyevisi : eyehide}
                    alt="表示"
                    width="18"
                    height="18"
                    loading="lazy"
                  />
                </span>
              </div>
              <div className="login-item">
                <Link to="/users/repassword">パスワードを忘れた場合</Link>
                {/* <div className="login-accept">
                  <input type="checkbox" name="keep" id="keep" />
                  <label htmlFor="keep">次回から自動でログインする</label>
                </div> */}
                <div className="login-submit">
                  <button type="submit" className="btn _bold">
                    ログイン
                  </button>
                </div>
              </div>
            </form>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
