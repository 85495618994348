import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Footer } from '../../blocks/footer';
import { Header } from '../../blocks/header';
import { Sidebar } from '../../blocks/sidebar';
import { MessageView, TMessage } from '../../blocks/workspace/message/view';
import { ApiService } from '../../services/api';

import '../../react-styles/css/mypage_message.scoped.css';

export type TChatResponse = {
  chatId: number;
  messages: TMessage[];
  paymentMethodCard: string;
  customer: {
    id: number;
    name: string;
  };
  seller: {
    id: number;
    name: string;
  };
  productId: number;
  productTitle: string;
  tradingStatus: null | 'SENT' | 'TRADING' | 'SALES' | 'CHATING';
  pageCount: number;
  page: number;
  onSale: boolean;
  withReport: false;
  cancelStatus: null | number;
};

export function MessageViewPage() {
  const [data, setData] = useState<TChatResponse>();
  const { id } = useParams();
  const [show, setShow] = useState(false);

  useEffect(() => {
    (async function () {
      try {
        if (id) {
          const data = await ApiService.getResources(
            `chat/get-chat/${id}/1`,
            null
          );
          setData(data);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  if (!data) return null;

  return (
    <>
      <Header show={show} setShow={setShow} mypage />
      <main className="main">
        <Sidebar show={show} mypage onClose={() => setShow(false)} />
        <div className="workspace">
          <div className="workspace-inner">
            <MessageView msgs={data} />
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
