import { useEffect, useState } from 'react';
import { Footer } from '../../blocks/footer';
import { Header } from '../../blocks/header';
import { Sidebar } from '../../blocks/sidebar';
import { Workspace } from '../../blocks/workspace';
import { Buy } from '../../blocks/workspace/buy/buy';
import { TItem } from '../../blocks/workspace/sell/onsale';
import { ApiService } from '../../services/api';

import '../../react-styles/css/mypage.scoped.css';

export type TData = {
  dealing: TItem[];
  done: TItem[];
};

export function BuyPage() {
  const [data, setData] = useState<TData>();
  const [show, setShow] = useState(false);

  useEffect(() => {
    (async function () {
      try {
        const dealing = await ApiService.getResources(
          'product/get-trading-customer',
          null
        );
        const done = await ApiService.getResources(
          'product/get-sales-customer',
          null
        );
        setData({
          dealing,
          done,
        });
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  if (!data) return null;

  return (
    <>
      <Header show={show} setShow={setShow} mypage />
      <main className="main">
        <Sidebar show={show} mypage onClose={() => setShow(false)} />
        <Workspace>
          <Buy data={data} />
        </Workspace>
      </main>
      <Footer />
    </>
  );
}
