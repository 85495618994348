export class ApiService {
  static _host = process.env.REACT_APP_SERVER_URL || '';
  static _apiBase = ApiService._host + '/';
  static _headers: any = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  static _token = '';

  static postEvent = async (url: string, token: string | null, body?: any) => {
    const headers = ApiService.createHeaders(token || this._token);
    const res = await fetch(ApiService._apiBase + url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(body),
    });
    const response = await res.json();
    if (res.ok) return response;
    if (!res.ok) {
      if (url !== 'auth/login' && response.statusCode === 401) {
        return ApiService.doLogout();
      }
      throw response;
    }
    return response;
  };
  private static createHeaders = (token: string | null) => {
    let headers: any = ApiService._headers;
    if (token) {
      headers = {
        ...headers,
        Authorization: `Bearer ${token}`,
      };
    }
    return headers;
  };
  static updateEvent = (url: string, token: string | null, body?: any) => {
    const headers = ApiService.createHeaders(token || this._token);
    return fetch(ApiService._apiBase + url, {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify(body),
    })
      .then((res) => {
        return res.json();
      })
      .catch((err) => {
        if (err.statusCode === 401) {
          return ApiService.doLogout();
        }
      });
  };
  static getResources = async (url: string, token: string | null) => {
    const headers = ApiService.createHeaders(token || this._token);
    const res = await fetch(ApiService._apiBase + url, {
      headers: headers,
    });
    try {
      const response = await res.json();
      if (res.ok) return response;
      if (!res.ok) {
        if (url !== 'auth/user' && response.statusCode === 401) {
          return ApiService.doLogout();
        }

        throw response;
      }
      return response;
    } catch (error) {
      if (!res.ok) {
        throw error;
      }
    }
  };

  static getResourcesApi = async (url: string, token: string | null) => {
    const headers = ApiService.createHeaders(token || this._token);
    const res = await fetch(url, {
      headers: headers,
    });
    const response = await res.json();
    if (!res.ok) {
      if (response.statusCode === 401) {
        return ApiService.doLogout();
      }
      throw response;
    }
    return response;
  };

  static deleteEvent = async (
    url: string,
    token: string | null,
    body?: any
  ) => {
    const headers = ApiService.createHeaders(token || this._token);
    const res = await fetch(ApiService._apiBase + url, {
      method: 'DELETE',
      headers: headers,
      body: JSON.stringify(body),
    });
    try {
      const response = await res.json();
      if (res.ok) return response;
      if (!res.ok) {
        if (response.statusCode === 401) {
          return ApiService.doLogout();
        }
        throw response;
      }
      return response;
    } catch (error) {
      if (res.ok) return;
    }
  };

  static setToken = (token: string) => {
    this._token = token;
  };

  static doLogout = () => {
    alert('通信に失敗しました。TOPページに戻ります。');
    location.href = '/';
  };
}
