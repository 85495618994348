import { Link } from 'react-router-dom';
import { Footer } from '../../blocks/footer';
import { Header } from '../../blocks/header';

export function NotFound() {
  return (
    <>
      <Header />
      <main className="main">
        <section className="error">
          <div className="inner">
            <h2 className="error-tit">Error 404</h2>
            <p className="error-txt">お探しのページは見つかりませんでした。</p>
            <Link to="/" className="error-btn">
              トップページへ戻る
            </Link>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
