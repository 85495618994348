import { useEffect, useState, ChangeEvent } from 'react';
import { Link } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ADDRAjax } from '../../addr';
import { useUserContext } from '../../App';
import { Footer } from '../../blocks/footer';
import { Header } from '../../blocks/header';
import { ApiService } from '../../services/api';
import { useNavigate } from 'react-router';

import '../../react-styles/css/newitem.scoped.css';
import '../../react-styles/css/about_logged.scoped.css';
import { Img } from '../../blocks/img';
import { useImgs } from '../../hooks/useImgs';
import { baseTitle } from '../auth/login';

const host = process.env.REACT_APP_SERVER_URL || 'http://localhost:8005';

const dd = new ADDRAjax('pref', 'city', 'area');
dd.JSONDATA = '/data';

const year = new Date().getFullYear();

export type TCat = {
  id: number;
  title: string;
  parent: number | null;
  image: string;
};

export function AddItem() {
  const [cats, setCats] = useState<TCat[] | null>(null);
  const [level2, setLevel2] = useState<TCat[] | null>(null);
  const [level3, setLevel3] = useState<TCat[] | null>(null);
  const navigate = useNavigate();
  const [err, setErr] = useState('');
  const [uploading, setUploading] = useState(false);

  const { token, company } = useUserContext();

  useEffect(() => {
    document.title = `${baseTitle}商品登録`;
  }, []);

  useEffect(() => {
    dd.init();
    if (company) {
      dd.setAddress(company.prefecture, company.city, company.area);
    }
  }, [company]);

  const { imgs, getSrc, onDropHandler, deleteImg, isImgsEmpty } = useImgs();

  useEffect(() => {
    (async function () {
      const cats = await ApiService.getResources(`category/get`, null);
      setCats(cats);
    })();
  }, []);

  function handleLevel1(e: ChangeEvent<HTMLSelectElement>) {
    const value = e.target.value;
    setLevel2(cats?.filter((cat) => cat.parent === +value) || null);
    setLevel3(null);
  }

  function handleLevel2(e: ChangeEvent<HTMLSelectElement>) {
    const value = e.target.value;
    setLevel3(cats?.filter((cat) => cat.parent === +value) || null);
  }

  async function handleSubmit(event: any) {
    event.preventDefault();
    if (isImgsEmpty()) {
      setErr('画像を選択してくださいしてください。');
      window.scrollTo(0, 0);
      return;
    }
    const {
      lv1Pulldown,
      lv3Pulldown,
      lv2Pulldown,
      title,
      description,
      pref,
      city,
      area,
      address,
      price,
      lots,
      jyoutai,
      weight,
      size,
      madeYear,
      userYear,
      number,
      guaranteeText,
      postage,
      postageBiko,
      useCard,
      period,
      disposal,
    } = event.target;

    console.log(event.target);

    if (lv1Pulldown.value === '0') {
      setErr('大カテゴリを選択してください。');
      window.scrollTo(0, 0);
      return;
    }

    if (pref.value === '都道府県') {
      setErr('都道府県を選択してください。');
      window.scrollTo(0, 0);
      return;
    }

    if (city.value === '郡市区') {
      setErr('郡市区を選択してください。');
      window.scrollTo(0, 0);
      return;
    }

    if (area.value === '町村') {
      setErr('町村を選択してください。');
      window.scrollTo(0, 0);
      return;
    }

    const category = [
      {
        id: +lv1Pulldown.value,
      },
    ];

    if (+lv2Pulldown.value) {
      category.push({
        id: +lv2Pulldown.value,
      });
      if (+lv3Pulldown.value) {
        category.push({
          id: +lv3Pulldown.value,
        });
      }
    }

    const body = {
      title: title.value,
      description: description?.value,
      prefecture: pref.value,
      city: city.value,
      area: area.value,
      address: address.value,
      price: +price.value,
      ...(lots.value && { amount: lots.value }),
      ...(jyoutai.value !== 'NO_CHOICE' && { condition: jyoutai.value }),
      ...(weight.value && { weight: weight.value }),
      ...(size.value && { size: size.value }),
      ...(madeYear.value && { year: madeYear.value }),
      ...(userYear.value && { useYear: userYear.value }),
      ...(number.value && { modelNumber: number.value }),
      ...(guaranteeText.value && { warrantyInformation: guaranteeText.value }),
      ...(postage.value && { costOfDelivery: postage.value }),
      ...(postageBiko.value && {
        costOfDeliveryDescription: postageBiko.value,
      }),
      ...(period.value && {
        period: +period.value,
      }),
      ...(disposal.checked && {
        isDeleteEndDate: disposal.checked,
      }),
      paymentMethod: useCard.value,
      category,
    };

    const data = new FormData();
    for (const { file } of imgs) {
      if (file) {
        data.append('file', file, file.name);
      }
    }
    try {
      setUploading(true);
      const response = await fetch(host + '/upload', {
        method: 'POST',
        body: data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const images: { url: string }[] = await response.json();
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      body.content = images.map((img) => ({ link: img.url }));
      await ApiService.postEvent('product/create', null, body);
      navigate('/');
    } catch (error) {
      console.log(error);
    }
    setUploading(false);
  }

  const [period, setPeriod] = useState(true);

  function toggleSwitch(e: ChangeEvent<HTMLInputElement>) {
    const checked = e.target.checked;
    setPeriod(!e.target.checked);
    const targetForm = document.getElementById('period');
    const targetCheckbox = document.getElementById('disposal');
    if (targetForm && targetCheckbox) {
      (targetForm as HTMLInputElement).disabled = !checked;
      (targetForm as HTMLInputElement).value = '';
      (targetCheckbox as HTMLInputElement).disabled = !checked;
      (targetCheckbox as HTMLInputElement).checked = false;
    }
  }

  useEffect(() => {
    const pm1 = document.getElementById('p-method-01');
    const pm2 = document.getElementById('p-method-02');
    const pm3 = document.getElementById('p-method-03');
    const price = document.getElementById('price');
    pm1?.addEventListener('click', () => {
      price?.setAttribute('min', '');
      price?.setAttribute('max', '');
    });
    pm2?.addEventListener('click', () => {
      price?.setAttribute('min', '1000');
      price?.setAttribute('max', '3000000');
    });
    pm3?.addEventListener('click', () => {
      price?.setAttribute('min', '1000');
      price?.setAttribute('max', '3000000');
    });
  }, []);

  return (
    <>
      <Header />
      <main className="main">
        <section className="newitem">
          <div className="inner">
            <nav className="links">
              <ul>
                <li className="link">
                  <Link to="/">ホーム</Link>
                </li>
                <li className="link">
                  <Link to="/mypages">マイページ</Link>
                </li>
                <li className="link _current">
                  <p>商品登録</p>
                </li>
              </ul>
            </nav>
            <h2 className="newitem-tit">商品登録</h2>
            <p className="__common_error_msg">{err}</p>
            <form className="form" id="newitem" onSubmit={handleSubmit}>
              {/*  category */}
              <div className="form-part">
                <p className="form-label">
                  カテゴリ<span className="_sub">（大カテゴリは必須）</span>
                </p>
                <div className="form-cnt flex-col">
                  <div className="form-icon">
                    <select
                      className="w-middle"
                      name="lv1"
                      id="lv1Pulldown"
                      onChange={handleLevel1}
                    >
                      <option value={0}>大カテゴリ*</option>
                      {cats
                        ?.filter((cat) => cat.parent === null)
                        .map((cat) => (
                          <option value={cat.id} key={cat.id}>
                            {cat.title}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="form-icon">
                    <select
                      className="w-middle"
                      name="lv2"
                      id="lv2Pulldown"
                      // disabled
                      onChange={handleLevel2}
                    >
                      <option value={0}>中カテゴリ</option>
                      {level2?.map((cat) => (
                        <option value={cat.id} key={cat.id}>
                          {cat.title}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-icon">
                    <select
                      className="w-middle"
                      name="lv3"
                      id="lv3Pulldown"
                      // disabled
                    >
                      <option value="" selected>
                        小カテゴリ
                      </option>
                      {level3?.map((cat) => (
                        <option value={cat.id} key={cat.id}>
                          {cat.title}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              {/*  title */}
              <div className="form-part">
                <p className="form-label">
                  商品名<span className="_sub">（必須、25文字以内）</span>
                </p>
                <input
                  type="text"
                  id="title"
                  name="title"
                  maxLength={25}
                  // defaultValue="<?php if(isset($items['Item']['name'])) echo $items['Item']['name']?>"
                  required
                />
              </div>
              {/*  description */}
              <div className="form-part">
                <p className="form-label">
                  商品説明
                  <span className="_sub">（必須、1,000文字以内）</span>
                </p>
                <textarea
                  name="description"
                  id="description"
                  maxLength={1000}
                  cols={30}
                  rows={10}
                  required
                  // defaultValue={
                  //   "<?php if(isset($items['Item']['description'])) echo $items['Item']['description']?>"
                  // }
                />
              </div>
              {/* pref, city, area, level3 */}
              <div className="form-part">
                <p className="form-label">
                  出品エリア
                  <span className="_sub">（必須）</span>
                </p>
                <div className="form-cnt flex-col">
                  <span className="form-icon">
                    <select className="w-middle" name="pref" id="pref">
                      <option value="都道府県" selected>
                        都道府県*
                      </option>
                    </select>
                  </span>
                  <span className="form-icon">
                    <select className="w-middle" name="city" id="city">
                      <option value="郡市区" selected>
                        郡市区
                      </option>
                    </select>
                  </span>
                  {/* 都道府県 の値に合わせて表示内容を絞れるとgood */}
                  <span className="form-icon">
                    <select className="w-middle" name="area" id="area">
                      <option value="町村" selected>
                        町村
                      </option>
                    </select>
                  </span>
                  <input
                    type="text"
                    autoComplete="address-level3"
                    name="address"
                    placeholder="番地以下を入力"
                    maxLength={100}
                    defaultValue={company ? company.address : ''}
                    required
                  />
                </div>
              </div>
              {/* payment */}
              <div className="form-part">
                <p className="form-label">
                  支払い方法<span className="_sub">(必須)</span>
                </p>
                <div className="form-cnt flex-col justify-center">
                  <ul className="form-parts">
                    <li className="form-part">
                      <input
                        type="radio"
                        name="useCard"
                        id="paymentOnlyCash"
                        value="0"
                        defaultChecked
                      />
                      <label
                        id="p-method-01"
                        className="form-unit"
                        htmlFor="paymentOnlyCash"
                      >
                        現金のみ
                      </label>
                    </li>
                    {company && (
                      <>
                        <li className="form-part">
                          <input
                            type="radio"
                            name="useCard"
                            id="paymentOnlyCard"
                            value="1"
                          />
                          <label
                            id="p-method-02"
                            className="form-unit"
                            htmlFor="paymentOnlyCard"
                          >
                            クレジットカードのみ
                          </label>
                        </li>
                        <li className="form-part">
                          <input
                            type="radio"
                            name="useCard"
                            id="paymentBoth"
                            value="2"
                          />
                          <label
                            id="p-method-03"
                            className="form-unit"
                            htmlFor="paymentBoth"
                          >
                            両方
                          </label>
                        </li>
                      </>
                    )}
                  </ul>
                  {company ? (
                    <p className="form-attention">
                      クレジットカード利用の場合、価格の
                      <span className="_bold">
                        下限は¥1,000から上限は¥3,000,000まで
                      </span>
                      となります。
                    </p>
                  ) : (
                    <p className="form-attention">
                      法人登録いただくとクレジットカードでの支払いを受けることができます。
                    </p>
                  )}

                  <p className="form-attention">
                    クレジットカード利用の場合、出品価格から出品手数料10%を差し引きますので、手数料を加味した金額設定をお願いします。
                  </p>
                </div>
              </div>
              {/* price */}
              <div className="form-part">
                <p className="form-label">
                  価格<span className="_sub">（必須、税抜）</span>
                </p>
                <div className="form-cnt flex-row gap-5">
                  <input
                    type="number"
                    className="w-small"
                    id="price"
                    name="price"
                    required
                  />
                  <label htmlFor="price">円</label>
                </div>
              </div>
              {/* deadline */}
              <div className="form-part">
                <p className="form-label">掲載期限</p>
                <div className="form-cnt flex-col sp-spacer">
                  <div className="form-item">
                    <input
                      type="checkbox"
                      name="flg"
                      id="flag"
                      data-status="2"
                      onChange={toggleSwitch}
                    />
                    <label className="form-unit" htmlFor="flag">
                      掲載期限の設定をする
                    </label>
                  </div>
                  <div className="form-item form-item-flex align-bottom gap-5">
                    <input
                      type="number"
                      className="w-small"
                      name="period"
                      id="period"
                      max={100}
                      min={1}
                      // defaultValue="<?php if(isset($items['Item']['period'])) echo $items['Item']['period']?>"
                      disabled={period}
                    />
                    <label htmlFor="period">日間</label>
                  </div>
                  <div className="form-item">
                    <input
                      type="checkbox"
                      name="disposal"
                      id="disposal"
                      disabled
                    />
                    <label className="form-unit" htmlFor="disposal">
                      掲載期限後は廃棄予定である
                    </label>
                  </div>
                </div>
              </div>
              {/* lot number */}
              <div className="form-part">
                <p className="form-label">
                  ロット数
                  <span className="_sub" />
                </p>
                <div className="form-cnt flex-row gap-5">
                  <input
                    type="number"
                    className="w-small"
                    id="lots"
                    name="lots"
                    max={9999}
                    min={0}
                    // defaultValue="<?php if(isset($items['Item']['lots'])) echo $items['Item']['lots']?>"
                  />
                  <label htmlFor="lots">個</label>
                </div>
              </div>
              {/* condition */}
              <div className="form-part">
                <p className="form-label">
                  商品の状態
                  <span className="_sub" />
                </p>
                <span className="form-icon">
                  <select className="w-middle" name="jyoutai" id="jyoutai">
                    <option value="" selected>
                      選択なし
                    </option>
                    <option value="NEW">新品</option>
                    <option value="USED">中古</option>
                    <option value="NEW_AND_OLD">新古</option>
                  </select>
                </span>
              </div>
              {/* weight */}
              <div className="form-part">
                <p className="form-label">
                  重さ
                  <span className="_sub" />
                </p>
                <input
                  id="weight"
                  name="weight"
                  pattern="^\d*\.?\d{1,2}$"
                  // defaultValue="<?php if(isset($items['Item']['weight'])) echo $items['Item']['weight']?>"
                  placeholder="○○kg"
                />
              </div>
              {/* size */}
              <div className="form-part">
                <p className="form-label">
                  サイズ
                  <span className="_sub" />
                </p>
                <input
                  type="text"
                  id="size"
                  name="size"
                  maxLength={100}
                  // defaultValue="<?php if(isset($items['Item']['size'])) echo $items['Item']['size']?>"
                  placeholder="○○cm×○○cm×○○cm"
                />
              </div>
              {/* madeYear */}
              <div className="form-part">
                <p className="form-label">
                  年式
                  <span className="_sub" />
                </p>
                <div className="form-cnt flex-row gap-5">
                  <select className="w-small" name="madeYear" id="test">
                    <option value="" selected>
                      選択なし
                    </option>
                    {Array.from({ length: 100 }, (_, i) => year - i).map(
                      (year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      )
                    )}
                  </select>
                  <label htmlFor="madeYear">年製</label>
                </div>
              </div>
              {/* used year */}
              <div className="form-part">
                <p className="form-label">
                  使用年数
                  <span className="_sub" />
                </p>
                <div className="form-cnt flex-row gap-5">
                  <input
                    className="w-small"
                    type="number"
                    id="userYear"
                    name="userYear"
                    max={100}
                    min={0}
                    // defaultValue="<?php if(isset($items['Item']['userYear'])) echo $items['Item']['userYear']?>"
                    placeholder="yy"
                  />
                  <label htmlFor="userYear">年使用</label>
                </div>
              </div>
              {/* model number */}
              <div className="form-part">
                <p className="form-label">
                  型番(品番)
                  <span className="_sub" />
                </p>
                <input
                  type="text"
                  id="number"
                  name="number"
                  maxLength={100}
                  // defaultValue="<?php if(isset($items['Item']['number'])) echo $items['Item']['number']?>"
                  placeholder="000-0000-0000"
                />
              </div>
              {/* Warranty */}
              <div className="form-part">
                <p className="form-label">
                  保証の内容<span className="_sub">（1,000文字以内）</span>
                </p>
                <textarea
                  name="guaranteeText"
                  // id="description"
                  id="guaranteeText"
                  maxLength={1000}
                  cols={30}
                  rows={10}
                  placeholder="自社商品の保証など"
                  // defaultValue={
                  //   "<?php if(isset($items['Item']['guaranteeText'])) echo $items['Item']['guaranteeText']?>"
                  // }
                />
              </div>
              {/* shipping charge */}
              <div className="form-part">
                <p className="form-label">
                  送料について
                  <span className="_sub" />
                </p>
                <span className="form-icon">
                  <select className="w-middle" name="postage" id="test">
                    <option value="" selected>
                      選択なし
                    </option>
                    <option value="ON">含む</option>
                    <option value="OFF">含まない</option>
                  </select>
                </span>
              </div>
              {/* Shipping charges (Remarks) */}
              <div className="form-part">
                <p className="form-label">
                  送料について(備考)
                  <span className="_sub">（1,000文字以内）</span>
                </p>
                <textarea
                  name="postageBiko"
                  // id="description"
                  id="postageBiko"
                  maxLength={1000}
                  cols={30}
                  rows={10}
                  placeholder="トラックによる配送を含む、含まないなど"
                  // defaultValue={
                  //   "<?php if(isset($items['Item']['postageBiko'])) echo $items['Item']['postageBiko']?>"
                  // }
                />
              </div>
              {/* images */}
              <div className="form-part">
                <p className="form-label">
                  商品画像<span className="_sub">（必須、５点まで）</span>
                </p>
                <div className="form-cnt flex-row" id="imgList">
                  <DndProvider backend={HTML5Backend}>
                    <ul className="form-imgs">
                      {imgs.map((img, index) => (
                        <Img
                          key={img.id}
                          img={img}
                          index={index}
                          deleteImg={deleteImg}
                          getSrc={getSrc}
                          onDropHandler={onDropHandler}
                        />
                      ))}
                    </ul>
                  </DndProvider>
                </div>
              </div>
              <p className="form-txt">
                出品禁止の商品・投稿ルールについては、
                <Link to="/doc/terms_of_use" target="_blank">
                  利用規約
                </Link>
                をご確認ください。
              </p>
              <div className="form-part">
                <button
                  type="submit"
                  disabled={uploading}
                  className="btn _large"
                  form="newitem"
                >
                  商品を掲載する
                </button>
              </div>
            </form>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
