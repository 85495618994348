import { createContext, useContext, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router';
import { Add } from './pages/auth/add';
import { ChangePwd } from './pages/auth/changepwd';
import { End } from './pages/auth/end';
import { Login } from './pages/auth/login';
import { Regist } from './pages/auth/regist';
import { Repwd } from './pages/auth/repwd';
import { Reset } from './pages/auth/reset';
import { AboutUs } from './pages/docs/about-us';
import { LegalStatement } from './pages/docs/legal-statement';
import { PrivacyPolicy } from './pages/docs/privacy-policy';
import { SitePolicy } from './pages/docs/site-policy';
import { Sitemap } from './pages/docs/sitemap';
import { TermsOfUse } from './pages/docs/terms-of-use';
import { Home } from './pages/home';
import { Search } from './pages/home/search';
import { AddItem } from './pages/items/add';
import { EditItem } from './pages/items/edit';
import { Msg } from './pages/msg';
import { MyPages } from './pages/my-pages';
import { BankPage } from './pages/my-pages/bank';
import { BuyPage } from './pages/my-pages/buy';
import { BuyDealedPage } from './pages/my-pages/buy-dealed';
import { BuyDealingPage } from './pages/my-pages/buy-dealing';
import { EditCompanyPage } from './pages/my-pages/edit-company';
import { LikePage } from './pages/my-pages/like';
import { MessagePage } from './pages/my-pages/message';
import { MessageViewPage } from './pages/my-pages/message-view';
import { SellDealedPage } from './pages/my-pages/sell-dealed';
import { SettingPage } from './pages/my-pages/setting';
import { TransferPage } from './pages/my-pages/transfer';
import { NotFound } from './pages/not-found/NotFound';
import { Product } from './pages/product';
import { ProfilePage } from './pages/profile';
import { ApiService } from './services/api';
import ProtectedRoutes from './services/ProtectedRoute';
import './react-styles/css/common.css';
import { Chat } from './blocks/chat';
import { Guidelines } from './pages/docs/guidelines';
import { Inquiry } from './pages/inquiry';

export type TUser = {
  id: number;
  name: string;
  prefecture: string;
  city: string;
  area: string;
  address: string;
  email: string;
  sex: string;
  dateOfBirth: string;
  bio: string;
  phone: number;
  certification: boolean;
};

type TUserContext = {
  user: TUser;
  company: any;
  token: string;
  setToken: (token: string) => void;
  logout: () => void;
  updateCompany: (company: any) => void;
  updateUser: (user: TUser) => void;
};

export const UserContext = createContext<TUserContext>({} as TUserContext);

export const useUserContext = () => useContext(UserContext);

function App() {
  const [user, setUser] = useState<any>(null);
  const [token, setToken] = useState('');
  const [company, setCompany] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setToken(token);
    } else {
      setUser(false);
    }
  }, []);

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  function logout() {
    setUser(false);
    setToken('');
    setCompany(null);
    localStorage.removeItem('token');
    navigate('/');
  }

  useEffect(() => {
    if (token) {
      (async function () {
        try {
          ApiService.setToken(token);
          const data = await ApiService.getResources('auth/user', null);
          try {
            const company = await ApiService.getResources(
              `company/${data.id}`,
              null
            );
            setCompany(company);
          } catch (error) {
            console.log(error);
          }
          setUser(data);
        } catch (error: any) {
          if (error.statusCode === 401) {
            logout();
          }
          console.log(error);
        }
      })();
    }
  }, [token]);

  if (user === null) return null;

  return (
    <UserContext.Provider
      value={{
        user,
        company,
        setToken: (token: string) => setToken(token),
        logout,
        token,
        updateCompany: (company) => setCompany(company),
        updateUser: (user: TUser) => setUser(user),
      }}
    >
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="users">
          <Route path="login" element={<Login />} />

          <Route path="regist" element={<Regist />} />
          <Route path="end" element={<End />} />
          <Route path="add" element={<Add />} />

          <Route
            path="view/:id"
            element={
              <ProtectedRoutes auth={user}>
                <ProfilePage />
              </ProtectedRoutes>
            }
          />

          <Route path="repassword" element={<Reset />} />
          <Route path="repassword1" element={<ChangePwd />} />
          <Route path="repwd" element={<Repwd />} />
        </Route>
        <Route path="items">
          <Route path="" element={<Home />} />
          <Route path="view/:id" element={<Product />} />
          <Route
            path="add"
            element={
              <ProtectedRoutes auth={user}>
                <AddItem />
              </ProtectedRoutes>
            }
          />
          <Route
            path="edit/:id"
            element={
              <ProtectedRoutes auth={user}>
                <EditItem />
              </ProtectedRoutes>
            }
          />
          <Route path="msg" element={<Msg />} />
          <Route path="inquiry" element={<Inquiry />} />
          <Route path="search" element={<Search />} />
          <Route path="search/:id" element={<Search />} />
        </Route>
        <Route path="mypages" element={<Chat />}>
          <Route
            path=""
            element={
              <ProtectedRoutes auth={user}>
                <MyPages />
              </ProtectedRoutes>
            }
          />
          <Route
            path="buy"
            element={
              <ProtectedRoutes auth={user}>
                <BuyPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="selcmtdealing"
            element={
              <ProtectedRoutes auth={user}>
                <MessagePage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="selcmtdealed"
            element={
              <ProtectedRoutes auth={user}>
                <SellDealedPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="buycmtdealing"
            element={
              <ProtectedRoutes auth={user}>
                <BuyDealingPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="buycmtdealed"
            element={
              <ProtectedRoutes auth={user}>
                <BuyDealedPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="view/:id"
            element={
              <ProtectedRoutes auth={user}>
                <MessageViewPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="like"
            element={
              <ProtectedRoutes auth={user}>
                <LikePage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="user"
            element={
              <ProtectedRoutes auth={user}>
                <SettingPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="edit_company"
            element={
              <ProtectedRoutes auth={user}>
                <EditCompanyPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="bank"
            element={
              <ProtectedRoutes auth={user}>
                <BankPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="transfer"
            element={
              <ProtectedRoutes auth={user}>
                <TransferPage />
              </ProtectedRoutes>
            }
          />
        </Route>
        <Route path="doc">
          <Route path="terms_of_use" element={<TermsOfUse />} />
          <Route path="privacypolicy" element={<PrivacyPolicy />} />
          <Route path="sitepolicy" element={<SitePolicy />} />
          <Route path="aboutus" element={<AboutUs />} />
          <Route path="sitemap" element={<Sitemap />} />
          <Route path="legalstatement" element={<LegalStatement />} />
          <Route path="guidelines" element={<Guidelines />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </UserContext.Provider>
  );
}

export default App;
