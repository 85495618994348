import update from 'immutability-helper';
import { ChangeEvent, useState } from 'react';
import { Item } from '../blocks/area/item';
import blank from '../react-styles/img/common/common-blank_img.png';

type TItem = {
  id: number;
  src: string;
  file?: File;
};

export function useImgs() {
  const [imgs, setImgs] = useState<TItem[]>([
    {
      id: 1,
      src: blank,
    },
    {
      id: 2,
      src: blank,
    },
    {
      id: 3,
      src: blank,
    },
    {
      id: 4,
      src: blank,
    },
    {
      id: 5,
      src: blank,
    },
  ]);

  const isImgsEmpty = () => imgs.every(({ src }) => src === blank);

  function getSrc(e: ChangeEvent<HTMLInputElement>, index: number) {
    if (e.target.files) {
      const fileData = e.target.files[0];
      if (fileData && fileData.type.startsWith('image/')) {
        if (fileData.size > 10000000) {
          alert('ファイルサイズが10MBを超えています！');
        } else {
          const newImgs = [...imgs];
          newImgs[index].src = URL.createObjectURL(fileData);
          newImgs[index].file = fileData;
          setImgs(newImgs);
        }
      }
    }
  }

  function deleteImg(e: React.MouseEvent<HTMLLabelElement>, index: number) {
    if (imgs[index].src !== blank) {
      e.preventDefault();
      if (window.confirm('画像を削除しますか')) {
        const newImgs = [...imgs];
        newImgs[index].src = blank;
        newImgs[index].file = undefined;
        setImgs(newImgs);
      }
    }
  }

  function onDropHandler(dragIndex: number, hoverIndex: number) {
    console.log(dragIndex, hoverIndex);
    setImgs((imgs) =>
      update(imgs, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, imgs[dragIndex]],
        ],
      })
    );
  }

  function init(content: { link: string }[]) {
    const blankImgs = [...imgs];
    const uploaded = content.map((item, index) => ({
      id: index + 1,
      src: item.link,
    }));
    setImgs([...uploaded, ...blankImgs.slice(uploaded.length)]);
  }

  return {
    imgs,
    getSrc,
    deleteImg,
    onDropHandler,
    init,
    isImgsEmpty,
  };
}
