import { Link } from 'react-router-dom';
import { useUserContext } from '../../App';

import '../../react-styles/css/mypage.scoped.css';
import { ReactComponent as ReactLogo } from '../../react-styles/img/common/verified.svg';

export function Workspace({ children }: { children: any }) {
  const { user } = useUserContext();

  return (
    <>
      <div className="workspace">
        <div className="workspace-inner">
          <nav className="links">
            <ul>
              <li className="link">
                <Link to="/">ホーム</Link>
              </li>
              <li className="link _current">
                <p>マイページ</p>
              </li>
            </ul>
          </nav>
          {/* links */}
          <div className="workspace-top sp">
            {user.certification && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: '7px',
                }}
              >
                <ReactLogo />
                <span
                  style={{
                    margin: '0 10px 0 3px',
                    color: '#FFC215',
                    fontSize: '12px',
                  }}
                >
                  認証済み
                </span>
              </div>
            )}
            <h2 className="workspace-usrname">{user.name}</h2>
            <Link to="/items/add" className="btn _sticky">
              商品を掲載する
            </Link>
          </div>
          {children}
          {/* corporate */}
        </div>
      </div>
      {/* workspace */}
    </>
  );
}
