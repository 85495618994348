import '../../react-styles/css/about.scoped.css';
// import '../../react-styles/css/about_logged.scoped.css';

import prev from '../../react-styles/img/about/about-modal_icon_prev.svg';
import next from '../../react-styles/img/about/about-modal_icon_next.svg';

type TProps = {
  images: { id: number; link: string }[];
  active: number | null;
  setActive: any;
};

export function Modal({ images, active, setActive }: TProps) {
  function handlePrev() {
    setActive((active: any) => {
      if (active > 0) {
        return active - 1;
      } else {
        return images.length - 1;
      }
    });
  }

  function handleNext() {
    setActive((active: any) => {
      if (active < images.length - 1) {
        return active + 1;
      } else {
        return 0;
      }
    });
  }

  return (
    <div
      className={`modal ${active !== null ? '_active' : ''}`}
      id="modal-area"
    >
      <div className="modal-inner">
        <span
          className="modal-pgnav _prev"
          id="modal-prev"
          onClick={handlePrev}
        >
          <img src={prev} alt="左矢印" width={15} height={30} loading="lazy" />
        </span>
        <span
          className="modal-pgnav _next"
          id="modal-next"
          onClick={handleNext}
        >
          <img src={next} alt="右矢印" width={15} height={30} loading="lazy" />
        </span>
        <span
          className="modal-close"
          id="modal-close"
          onClick={() => setActive(null)}
        />
        <ul className="modal-mainimgs">
          {images.map((img, index) => (
            <li
              className={`modal-mainimg ${active === index ? '_current' : ''}`}
              key={img.id}
            >
              <img
                src={img.link}
                alt=""
                width={453}
                height={453}
                loading="lazy"
              />
            </li>
          ))}
        </ul>
        <ul className="modal-subimgs">
          {images.map((img, index) => (
            <li
              className={`modal-subimg ${active === index ? '_current' : ''}`}
              key={img.id}
              onClick={() => setActive(index)}
            >
              <img
                src={img.link}
                alt=""
                width={40}
                height={53}
                loading="lazy"
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
