import { Link } from 'react-router-dom';
import { useUserContext } from '../../App';
import { ApiService } from '../../services/api';

import '../../react-styles/css/about.scoped.css';
// import '../../react-styles/css/about_logged.scoped.css';
import { TCommentResponse } from '../../pages/product';
import { formatDateTime } from '../workspace/sell/onsale';

export function Comment({
  comments,
  id,
  owner,
}: {
  comments: TCommentResponse;
  id: string;
  owner: number;
}) {
  const { user } = useUserContext();

  async function handleSubmit(event: any) {
    try {
      event.preventDefault();
      const { body } = event.target;
      await ApiService.postEvent('comments/create', null, {
        text: body.value,
        productId: +(id || 0),
      });
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  }

  if (!comments) return null;

  return (
    <section className="comment">
      <h3 className="comment-tit">投稿コメント</h3>
      <ul className="comment-list">
        {[...comments.data].map((comment) => (
          <li className="comment-item" key={comment.id}>
            <Link
              to={`/users/view/${comment.userId}`}
              className={`comment-head ${
                comment.userId === owner ? '_seller' : ''
              }`}
            >
              {comment.name}
            </Link>
            <div className="comment-cntbox">
              <p className="comment-cnt">{comment.text}</p>
              <p className="comment-date">
                投稿日：
                <time dateTime={comment.createdAt}>
                  {formatDateTime(comment.createdAt)}
                </time>
              </p>
            </div>
          </li>
        ))}
      </ul>
      <div className="comment-commit">
        {!user ? (
          <>
            <p>コメントを投稿するにはログインが必要です。</p>
            <Link to="/users/login" className="btn _small">
              ログイン
            </Link>
          </>
        ) : (
          <form onSubmit={handleSubmit} id="comment">
            <textarea
              name="body"
              cols={30}
              rows={10}
              placeholder="コメントを投稿する"
              maxLength={500}
              required
            ></textarea>
            <button type="submit" form="comment" className="btn _small">
              投稿する
            </button>
          </form>
        )}
      </div>
    </section>
  );
}
