import { Link } from 'react-router-dom';

import '../../react-styles/css/top.scoped.css';

import soldout from '../../react-styles/img/soldout.png';

export type TItem = {
  image: string;
  title: string;
  price: number;
  id: number;
  soldOut: boolean;
};

export const format = (number: number) =>
  new Intl.NumberFormat().format(number);

export function NewsItem({ item }: { item: TItem }) {
  return (
    <li className="news-item">
      <Link to={`/items/view/${item.id}`}>
        <picture className="item-img">
          <img
            src={item.image}
            alt="無垢フローリング材"
            width="700"
            height="400"
            loading="lazy"
          />
        </picture>
        {item.soldOut && (
          <div className="soldout">
            <img src={soldout} width="80" height="150" loading="lazy" />
          </div>
        )}
        <h3 className="item-name">{item.title}</h3>
        <p className="item-price">¥{format(item.price)}</p>
      </Link>
    </li>
  );
}
