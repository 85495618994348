import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Footer } from '../../blocks/footer';
import { Header } from '../../blocks/header';

import '../../react-styles/css/register.scoped.css';
import { baseTitle } from './login';

export function Repwd() {
  const location = useLocation();

  useEffect(() => {
    document.title = `${baseTitle}Users`;
  }, []);

  return (
    <>
      <Header />
      <main className="main">
        <section className="register">
          <div className="inner">
            <nav className="links">
              <ul>
                <li className="link">
                  <Link to="/">ホーム</Link>
                </li>
                <li className="link _current">
                  <p>パスワード変更</p>
                </li>
              </ul>
            </nav>
            <h2 className="register-tit _center">パスワード変更</h2>
            <div className="confirm">
              <h3 className="confirm-tit">メールを送信しました</h3>
              <p className="confirm-mail">
                {
                  //@ts-expect-error fix this
                  location.state.email
                }
              </p>
              <p className="confirm-txt _center">
                <span className="_red">
                  まだパスワード変更は完了していません
                </span>
                <br />
                上記アドレスに届くメール本文に記載されている
                <br />
                「メールアドレス認証」をクリックして
                <br />
                パスワード変更を完了してください
              </p>
              <div className="confirm-cnts _left">
                <p>※認証メールが届かない場合</p>
                <ol className="confirm-list">
                  <li className="confirm-item">
                    迷惑メールフォルダなど通常受信するフォルダ以外に認証メールが届いていないか確認する
                  </li>
                  <li className="confirm-item">
                    アマリーからのメール（@amary.jp）が受信許可されているか確認する
                  </li>
                  <li className="confirm-item">
                    会員登録の欄に記入いただいたメールアドレスに誤りがないか確認する
                  </li>
                </ol>
              </div>
              <div className="confirm-link">
                <Link to="/">トップページに戻る</Link>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
