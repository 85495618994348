import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { ApiService } from '../../services/api';

import '../../react-styles/css/about.scoped.css';
// import '../../react-styles/css/about_logged.scoped.css';
import { useState } from 'react';
import { useUserContext } from '../../App';

export function Btns({
  id,
  seller,
  checkChat,
  isFavorite,
  title,
  isTrading,
  soldOut,
}: {
  id: string;
  seller: number;
  checkChat: boolean;
  isFavorite: boolean;
  title: string;
  isTrading: boolean;
  soldOut: boolean;
}) {
  const [isLiked, setLiked] = useState(isFavorite);
  const { user } = useUserContext();
  const navigate = useNavigate();

  async function onLike() {
    if (!user) {
      navigate('/users/login');
    }

    try {
      if (isLiked) {
        await ApiService.deleteEvent('favorite/delete', null, {
          productId: +id,
        });
      } else {
        await ApiService.postEvent('favorite/create', null, {
          productId: +id,
        });
      }
      setLiked((isLiked) => !isLiked);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <nav className="btns">
      <ul className="btns-list">
        {!checkChat && !isTrading && !soldOut && (
          <li className="btns-item">
            <Link
              to={user ? '/items/msg' : '/users/login'}
              state={{ seller, productId: id, title }}
              className="btn _middle"
            >
              取引を始める
            </Link>
          </li>
        )}
        <li className="btns-item">
          <button className="btn _middle reset-btn" onClick={onLike}>
            {isLiked ? 'お気に入りから削除' : 'お気に入りに登録'}
          </button>
        </li>
      </ul>
    </nav>
  );
}
