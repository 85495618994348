import { useEffect, useRef, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import io from 'socket.io-client';
import { TMessage } from '../blocks/workspace/message/view';

export type TMessageBody = {
  sender: number;
  receiver: number;
  productId: number;
  text: string;
};

const SERVER_URL = process.env.REACT_APP_SERVER_URL + '/chat';

export function useSocket() {
  const socketRef = useRef<any>();
  const [messages, setMessages] = useState<TMessage[]>([]);
  const [notifications, setNotifications] = useState(0);

  useEffect(() => {
    socketRef.current = io(SERVER_URL);
    socketRef.current.on('joinedRoom', (item: any) => {
      console.log(item, 'room connected');
    });

    socketRef.current.on('msgToClient', (message: any) => {
      if (socketRef.current) {
        console.log('new message', message);
        const newMessage = {
          id: message.id,
          text: message.text,
          createdAt: message.createdAt,
          userId: message.sender.id,
          userName: message.sender.name,
        };
        setMessages((messages) => [...messages, newMessage]);
      }
    });

    socketRef.current.on(
      'listeningMessages',
      ({ count }: { count: number }) => {
        if (socketRef.current) {
          setNotifications(count);
        }
      }
    );
  }, []);

  const sendMessage = (messageBody: TMessageBody) => {
    if (socketRef.current) {
      socketRef.current.emit('msgToServer', messageBody);
    }
  };

  const joinRoom = (seller: number, customer: number, productId: number) => {
    if (socketRef.current) {
      socketRef.current.emit('joinRoom', { seller, customer, productId });
    }
  };

  const listenNotifications = (userId: number) => {
    if (socketRef.current) {
      socketRef.current.emit('joinListeningMessages', {
        userId,
      });
    }
  };

  function disconnect() {
    if (socketRef.current) socketRef.current.disconnect();
  }

  return {
    joinRoom,
    listenNotifications,
    notifications,
    sendMessage,
    messages,
    disconnect,
    setMessages: (messages: TMessage[]) => setMessages(messages),
    socket: socketRef.current,
  };
}
