import { useEffect, useState } from 'react';
import { ADDRAjax } from '../../addr';
import { useUserContext } from '../../App';
import { Footer } from '../../blocks/footer';
import { Header } from '../../blocks/header';
import { Sidebar } from '../../blocks/sidebar';
import { Workspace } from '../../blocks/workspace';
import { EditCompany } from '../../blocks/workspace/edit-company';
import { ApiService } from '../../services/api';

import '../../react-styles/css/mypage.scoped.css';

const dd = new ADDRAjax('pref', 'city', 'area');
dd.JSONDATA = '/data';

export function EditCompanyPage() {
  const { company } = useUserContext();
  const [show, setShow] = useState(false);

  useEffect(() => {
    dd.init();
  }, []);

  useEffect(() => {
    if (company) {
      dd.setAddress(company.prefecture, company.city, company.area);
    }
  }, [company]);

  return (
    <>
      <Header show={show} setShow={setShow} mypage />
      <main className="main">
        <Sidebar show={show} mypage onClose={() => setShow(false)} />
        <Workspace>
          <EditCompany />
        </Workspace>
      </main>
      <Footer />
    </>
  );
}
