import { Item, TItem } from './onsale';

import '../../../react-styles/css/mypage.scoped.css';
import { Link } from 'react-router-dom';
import { ApiService } from '../../../services/api';

export function Dealing({
  currentTab,
  items,
}: {
  currentTab: string;
  items: TItem[];
}) {
  async function handleDone(productId: number, customerId: number) {
    if (confirm('商品の引き渡しを完了します。よろしいですか？')) {
      try {
        await ApiService.postEvent('product/sent', null, {
          productId,
          customerId,
        });
        window.location.reload();
      } catch (error) {
        console.log(error);
      }
    }
  }

  return (
    <div
      className={`workspace-cnts sell-body dealing ${
        currentTab === 'dealing' ? '_current' : ''
      }`}
      data-tab="dealing"
    >
      <ul className="sell-list">
        {items.map((item) => (
          <Item key={item.id} item={item}>
            {item.customer && (
              <li className="item-part usr">
                <p>現在取引中のユーザー：</p>
                <Link to={`/users/view/${item.customer.id}`}>
                  {item.companyName} {item.customer.name}
                </Link>
              </li>
            )}
            {item.tradingStatus === 'TRADING' && (
              <li className="item-part">
                <button
                  onClick={() => handleDone(item.id, item.customer.id)}
                  className="btn _done"
                >
                  引き渡し完了
                </button>
              </li>
            )}
          </Item>
        ))}
        {/* 取引中商品 */}
      </ul>
    </div>
  );
}
