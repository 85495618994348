import { useUserContext } from '../../App';
import { ApiService } from '../../services/api';

import '../../react-styles/css/mypage.scoped.css';
import { useState } from 'react';

const replaceAt = function (
  string: string,
  index: number,
  replacement: string
) {
  return (
    string.substring(0, index) +
    replacement +
    string.substring(index + replacement.length)
  );
};

const industries = [
  '土木一式工事',
  '建築一式工事',
  '大工工事',
  '左官工事',
  'とび・大工・コンクリート工事',
  '石工事',
  '屋根工事',
  '電気工事',
  '管工事',
  'タイル・れんが・ブロック工事',
  '鋼構造物工事',
  '鉄筋工事',
  'ほ装工事',
  'しゅんせつ工事',
  '板金工事',
  'ガラス工事',
  '塗装工事',
  '防水工事',
  '内装仕上工事',
  '機械器具設置工事',
  '熱絶縁工事',
  '電気通信工事',
  '造園工事',
  'さく井工事',
  '建具工事',
  '水道施設工事',
  '消防施設工事',
  '清掃施設工事',
  '解体工事',
];

export function EditCompany() {
  const { user, company, updateCompany } = useUserContext();
  const [error, setError] = useState('');

  async function handleSubmit(event: any) {
    try {
      event.preventDefault();
      const {
        pref,
        city,
        area,
        address,
        corpname,
        corpdesc,
        corptel,
        fromtime,
        totime,
        industry,
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday,
        sunday,
        holiday,
      } = event.target;

      let closed = '00000000';

      closed = replaceAt(closed, 0, monday.checked ? '1' : '0');
      closed = replaceAt(closed, 1, tuesday.checked ? '1' : '0');
      closed = replaceAt(closed, 2, wednesday.checked ? '1' : '0');
      closed = replaceAt(closed, 3, thursday.checked ? '1' : '0');
      closed = replaceAt(closed, 4, friday.checked ? '1' : '0');
      closed = replaceAt(closed, 5, saturday.checked ? '1' : '0');
      closed = replaceAt(closed, 6, sunday.checked ? '1' : '0');
      closed = replaceAt(closed, 7, holiday.checked ? '1' : '0');

      const body = {
        title: corpname.value,
        prefecture: pref.value,
        city: city.value,
        area: area.value,
        address: address?.value || '',
        description: corpdesc.value,
        phone: corptel?.value || '',
        openingHoursFrom: fromtime.value,
        openingHoursTo: totime.value,
        industry: industry.value,
        weekend: closed,
      };
      const data = await ApiService.postEvent(
        `company/${company ? 'edit' : 'create'}`,
        null,
        body
      );
      if (company) {
        updateCompany(data);
      } else {
        const company = await ApiService.getResources(
          `company/${user.id}`,
          null
        );
        updateCompany(company);
      }
      setError('変更しました。');
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } catch (error) {
      console.log(error);
    }
  }

  if (!user) return null;

  return (
    <section className="workspace-sec corporate _current" id="corporate">
      <h2 className="workspace-tit">法人プロフィール</h2>
      <p className="__common_error_msg">{error}</p>
      <form className="form" onSubmit={handleSubmit}>
        <div className="form-item">
          <label htmlFor="corpname">会社名</label>
          <input
            type="text"
            name="data[Company][name]"
            className="_full"
            id="corpname"
            defaultValue={company?.title}
            required
          />
        </div>
        <div className="form-item">
          <label>会社所在地</label>
          <div className="form-cnt col">
            <span className="form-icon">
              <select name="pref" id="corpaddress01">
                <option value="都道府県" selected>
                  都道府県*
                </option>
              </select>
            </span>
            <span className="form-icon">
              <select name="city" id="corpaddress02">
                <option value="郡市区" selected>
                  郡市区
                </option>
              </select>
            </span>
            <span className="form-icon">
              <select name="area" id="corpaddress03">
                <option value="町村" selected>
                  町村
                </option>
              </select>
            </span>
            <input
              type="text"
              name="address"
              className="_full"
              id="address"
              autoComplete="address-level3"
              placeholder="番地以下を入力"
              defaultValue={company?.address}
            />
          </div>
        </div>
        <div className="form-item">
          <label htmlFor="corptel">
            電話番号<span>（問合わせ用）</span>
          </label>
          <input
            type="tel"
            name="data[Company][tel]"
            id="corptel"
            pattern="[0-9]+"
            minLength={7}
            maxLength={15}
            defaultValue={company?.phone}
          />
        </div>
        <div className="form-item">
          <label>営業時間</label>
          <div className="form-cnt time">
            <input
              type="time"
              name="from"
              className="_medium"
              id="fromtime"
              min="00:00"
              max="23:59"
              defaultValue={company?.openingHoursFrom}
            />
            <span>〜</span>
            <input
              type="time"
              name="to"
              className="_medium"
              id="totime"
              min="00:00"
              max="23:59"
              defaultValue={company?.openingHoursTo}
            />
          </div>
        </div>
        <div className="form-item">
          <fieldset>
            <p className="grouptit">定休日</p>
            {/* <?php if(!empty($company['Company']['closedDay']))$closedDay = explode(",", $company['Company']['closedDay']); else $closedDay =array() ;?> */}
            <div className="form-cnt">
              <div className="form-part">
                <input
                  type="checkbox"
                  name="closed"
                  value="1"
                  id="monday"
                  defaultChecked={!!+company?.weekend[0]}
                />
                <label className="closed" htmlFor="monday">
                  月曜日
                </label>
              </div>
              <div className="form-part">
                <input
                  type="checkbox"
                  name="data[Company][closed][]"
                  value="2"
                  id="tuesday"
                  defaultChecked={!!+company?.weekend[1]}
                />
                <label className="closed" htmlFor="tuesday">
                  火曜日
                </label>
              </div>
              <div className="form-part">
                <input
                  type="checkbox"
                  name="data[Company][closed][]"
                  value="3"
                  id="wednesday"
                  defaultChecked={!!+company?.weekend[2]}
                />
                <label className="closed" htmlFor="wednesday">
                  水曜日
                </label>
              </div>
              <div className="form-part">
                <input
                  type="checkbox"
                  name="data[Company][closed][]"
                  value="4"
                  id="thursday"
                  defaultChecked={!!+company?.weekend[3]}
                />
                <label className="closed" htmlFor="thursday">
                  木曜日
                </label>
              </div>
              <div className="form-part">
                <input
                  type="checkbox"
                  name="data[Company][closed][]"
                  value="5"
                  id="friday"
                  defaultChecked={!!+company?.weekend[4]}
                />
                <label className="closed" htmlFor="friday">
                  金曜日
                </label>
              </div>
              <div className="form-part">
                <input
                  type="checkbox"
                  name="data[Company][closed][]"
                  value="6"
                  id="saturday"
                  defaultChecked={!!+company?.weekend[5]}
                />
                <label className="closed" htmlFor="saturday">
                  土曜日
                </label>
              </div>
              <div className="form-part">
                <input
                  type="checkbox"
                  name="data[Company][closed][]"
                  value="7"
                  id="sunday"
                  defaultChecked={!!+company?.weekend[6]}
                />
                <label className="closed" htmlFor="sunday">
                  日曜日
                </label>
              </div>
              <div className="form-part">
                <input
                  type="checkbox"
                  name="data[Company][closed][]"
                  value="8"
                  id="holiday"
                  defaultChecked={!!+company?.weekend[7]}
                />
                <label className="closed" htmlFor="holiday">
                  祝祭日
                </label>
              </div>
            </div>
          </fieldset>
        </div>
        <div className="form-item">
          {/* <?php if(empty($company['Company']['industry'])) $company['Company']['industry']=0;?> */}
          <label htmlFor="industry">業種区分</label>
          <span className="form-icon">
            <select
              name="industry"
              id="industry"
              defaultValue={company?.industry || ''}
            >
              <option value="">選択してください</option>
              {industries.map((industry) => (
                <option key={industry} value={industry}>
                  {industry}
                </option>
              ))}
            </select>
          </span>
        </div>
        <div className="form-item">
          <label htmlFor="description">備考欄</label>
          <textarea
            name="description"
            className="_full"
            id="corpdesc"
            maxLength={255}
            cols={30}
            rows={10}
            defaultValue={company?.description}
          />
        </div>
        <div className="form-item submit">
          <button type="submit" className="btn _large">
            上記内容で登録する
          </button>
        </div>
      </form>
    </section>
  );
}
