import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { useUserContext } from '../../App';

export const list = [
  {
    id: '_sell',
    title: '売りたい',
    to: '/mypages',
  },
  {
    id: '_buy',
    title: '買いたい',
    to: '/mypages/buy',
  },
  {
    id: '_message',
    title: 'メッセージ管理',
    to: '/mypages/selcmtdealing',
  },
  {
    id: '_like',
    title: 'お気に入り',
    to: '/mypages/like',
  },
  {
    id: '_setting',
    title: '設定',
    to: '/mypages/user',
  },
  {
    id: '_corporate',
    title: '法人プロフィール',
    to: '/mypages/edit_company',
  },
  {
    id: '_corporate',
    title: '銀行口座登録',
    to: '/mypages/bank',
  },
  {
    id: '_corporate',
    title: '振込申請',
    to: '/mypages/transfer',
  },
];

import '../../react-styles/css/mypage.scoped.css';
import { useChatContext } from '../chat';

export function Sidebar(properties: any) {
  const { mypage, show, onClose, ...props } = properties;
  const location = useLocation();
  const { user, company } = useUserContext();
  const { notifications } = useChatContext();

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];
    if (mypage) {
      if (show) {
        body.classList.add('scrollstop');
      } else {
        body.classList.remove('scrollstop');
      }
    }
  }, [show, mypage]);

  return (
    <>
      {/* ↓ ここから追加 ↓ */}
      <span
        id="sidebarCloser"
        className={`${show ? '_show' : ''}`}
        {...props}
        onClick={onClose}
      />
      {/* ↑ ここまで追加 ↑ */}
      {/* ↓ 「id="sidebarToggle"」追加 ↓ */}
      <div
        className={`sidebar ${show ? '_open' : ''}`}
        id="sidebarToggle"
        {...props}
      >
        {/* ↑ 「id="sidebarToggle"」追加 ↑ */}
        <div className="sidebar-inner" {...props}>
          <div className="sidebar-top" {...props}>
            <span className="_name">{company?.title}</span>
            <h2 className="sidebar-usrname" {...props}>
              {user.name}
            </h2>
            <Link to="/items/add" className="btn _sticky" {...props}>
              商品を掲載する
            </Link>
          </div>
          <nav className="sidebar-nav" {...props}>
            <ul className="sidebar-links" {...props}>
              {list.map((item) => (
                <li
                  key={item.to}
                  className={`sidebar-link ${
                    item.to === location.pathname ? '_current' : ''
                  }`}
                  {...props}
                >
                  <Link to={item.to} id={item.id} {...props}>
                    {`${item.title} ${
                      item.id === '_message' ? `(${notifications})` : ''
                    }`}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
      {/* sidebar */}
    </>
  );
}
