import { formatDateTime, TItem } from '../sell/onsale';
import '../../../react-styles/css/mypage.scoped.css';
import { format } from '../../news/news-item';
import { Link } from 'react-router-dom';

export function Done({
  currentTab,
  items,
}: {
  currentTab: string;
  items: TItem[];
}) {
  return (
    <div
      className={`workspace-cnts buy-body done ${
        currentTab === 'done' ? '_current' : ''
      }`}
      data-tab="done"
    >
      <ul className="buy-list">
        {items.map((item) => (
          <li className="buy-item item" key={item.id}>
            <picture className="item-img">
              <Link to={`/items/view/${item.id}`}>
                <img
                  src={item.productContent && item.productContent[0].link}
                  alt="商品画像"
                  width={156}
                  height={208}
                  loading="lazy"
                />
              </Link>
            </picture>
            <ul className="item-info">
              <li className="item-part">
                <h3 className="item-name">
                  <Link to={`/items/view/${item.id}`}>{item.title}</Link>
                </h3>
              </li>
              <li className="item-part">
                <p className="item-price _red">¥{format(item.price)}</p>
              </li>
              <li className="item-part">
                <p className="item-seller">投稿者：</p>
                <Link to={`/users/view/${item.seller.id}`}>
                  {item.companyName} {item.seller.name}
                </Link>
              </li>
              <li className="item-part">
                <p className="item-post">投稿日：</p>
                <time dateTime={item.tradingCreated}>
                  {formatDateTime(item.tradingCreated)}
                </time>
              </li>
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
}
