import { useEffect, useState } from 'react';
import { Footer } from '../../blocks/footer';
import { Header } from '../../blocks/header';
import { Sidebar } from '../../blocks/sidebar';
import { Workspace } from '../../blocks/workspace';
import { BuyDealing } from '../../blocks/workspace/message/buy-dealing';
import { TChat } from '../../blocks/workspace/message/sell-dealing';
import { ApiService } from '../../services/api';

import '../../react-styles/css/mypage.scoped.css';

export function BuyDealingPage() {
  const [data, setData] = useState<TChat[]>();
  const [show, setShow] = useState(false);

  useEffect(() => {
    (async function () {
      try {
        const data = await ApiService.getResources(
          'chat/get-chats-customer',
          null
        );
        setData(data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  if (!data) return null;

  return (
    <>
      <Header show={show} setShow={setShow} mypage />
      <main className="main">
        <Sidebar show={show} mypage onClose={() => setShow(false)} />
        <Workspace>
          <BuyDealing items={data} />
        </Workspace>
      </main>
      <Footer />
    </>
  );
}
