import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Comment } from '../../blocks/comment';
import { Footer } from '../../blocks/footer';
import { Header } from '../../blocks/header';
import { Nav } from '../../blocks/nav';
import { ItemMain } from '../../blocks/product';
import { Btns } from '../../blocks/product/btns';
import { ApiService } from '../../services/api';

import '../../react-styles/css/about.scoped.css';
// import '../../react-styles/css/about_logged.scoped.css';
import { TUser, useUserContext } from '../../App';
import { TProduct } from '../items/edit';
import { baseTitle } from '../auth/login';

export type TExtProduct = TProduct & {
  user: TUser;
  checkChat: boolean;
  isFavorite: boolean;
  createdAt: string;
  isTrading: boolean;
  soldOut: boolean;
};

export type TComment = {
  id: number;
  userId: number;
  name: string;
  text: string;
  createdAt: string;
};

export type TCommentResponse = {
  data: TComment[];
};

export function Product() {
  const { id } = useParams();
  const [product, setProduct] = useState<TExtProduct | null>(null);
  const [comments, setComments] = useState<TCommentResponse | null>(null);
  const { user } = useUserContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (product) {
      document.title = `${baseTitle}${product?.title}のページ`;
    }
  }, [product]);

  useEffect(() => {
    (async function () {
      try {
        const data = await ApiService.getResources(`product/get/${id}`, null);
        setProduct(data);
      } catch (error: any) {
        console.log(error);
        if (error?.status === 400 && error?.message === 'product not found') {
          navigate('/404');
        }
      }
    })();
    (async function () {
      const data = await ApiService.getResources(`comments/get/${id}/1`, null);
      setComments(data);
    })();
  }, []);

  if (!product || !id) return null;

  return (
    <>
      <Header />
      <main className="main">
        <section className="product">
          <div className="inner">
            <Nav product={product} />
            <ItemMain product={product} />
          </div>
        </section>
        {/* product */}
        {user.id !== product.user.id && (
          <Btns
            soldOut={product.soldOut}
            id={id || ''}
            title={product.title}
            seller={product.user.id}
            checkChat={product.checkChat}
            isFavorite={product.isFavorite}
            isTrading={product.isTrading}
          />
        )}
        {/* btns */}
        {comments && (
          <Comment comments={comments} id={id} owner={product.user.id} />
        )}
      </main>
      <Footer />
    </>
  );
}
