import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router';
import { Footer } from '../../blocks/footer';
import { Header } from '../../blocks/header';
import { Profile } from '../../blocks/profile';
import { ProfileItems } from '../../blocks/profile-items';
import { ApiService } from '../../services/api';

import '../../react-styles/css/profile.scoped.css';
import { Link } from 'react-router-dom';
import { TUser } from '../../App';
import { baseTitle } from '../auth/login';

export type TAddress = {
  prefecture: string;
  city: string;
  area: string;
  address: string;
};

export type TCompany = TAddress & {
  title: string;
  description: string;
  openingHoursFrom: string;
  openingHoursTo: string;
  industry: string;
  weekend: string;
};

export type TProfile = TUser & { favoriteCount: number; company?: TCompany };

export function ProfilePage() {
  const { id } = useParams();
  const [user, setUser] = useState<TProfile | null>(null);
  const [products, setProducts] = useState<any>();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      document.title = `${baseTitle}${user?.name}のプロフィール`;
    }
  }, [user]);

  useEffect(() => {
    (async function () {
      try {
        const data = await ApiService.getResources(`user/${id}`, null);
        setUser(data);
      } catch (error: any) {
        if (error?.status === 400 && error?.message === 'user not found') {
          navigate('/404');
        }
      }
    })();
    (async function () {
      const data = await ApiService.getResources(
        `product/get-posts-user/${id}/1`,
        null
      );
      setProducts(data);
    })();
  }, []);

  if (!user || !products) return null;

  return (
    <>
      <Header />
      <main className="main">
        <div className="inner">
          <nav className="links">
            <ul>
              <li className="link">
                <Link to="/">ホーム</Link>
              </li>
              <li className="link _current">
                <p>{user.name}のプロフィール</p>
              </li>
            </ul>
          </nav>
          <Profile user={user} count={products.products.length} />
          <ProfileItems username={user.name} products={products} />
        </div>
      </main>
      <Footer />
    </>
  );
}
