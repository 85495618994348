import { Link } from 'react-router-dom';

import '../../../react-styles/css/mypage.scoped.css';
import { format } from '../../news/news-item';
import { addDays } from '../../product/summary';
import { formatDate, formatDateTime } from '../sell/onsale';

export type TChat = {
  id: number;
  name: string;
  customer?: {
    id: number;
    name: string;
    companyName: string | null;
  };
  seller?: {
    id: number;
    name: string;
    companyName: string | null;
  };
  product: {
    id: number;
    price: number;
    title: string;
    createdAt: string;
    productContent: {
      link: string;
    }[];
    period: number;
    image: string;
  };
  message: {
    readed: boolean;
    createdAt: string;
  };
};

const getType = (type: string) => (type === 'dealing' ? '取引中' : '売却済');

export const Item = ({
  item,
  type,
  user,
}: {
  item: TChat;
  type: 'dealing' | 'done';
  user: 'seller' | 'customer';
}) => (
  <li className="message-item item item-col">
    <div className="user">
      <p className={`user-label ${type}`}>{getType(type)}</p>
      <Link
        to={`/users/view/${
          user === 'seller' ? item.seller?.id : item.customer?.id
        }`}
        className="user-name"
      >
        {user === 'seller'
          ? item.seller?.companyName
            ? `${item.seller?.companyName} ${item.seller?.name}`
            : `${item.seller?.name}`
          : item.customer?.companyName
          ? `${item.customer?.companyName} ${item.customer?.name}`
          : `${item.customer?.name}`}
      </Link>
      <span className="new-lavel">
        {item.message && !item.message.readed && 'new'}
      </span>
    </div>
    <div className="item-box">
      <picture className="item-img">
        <img
          src={item.product.image}
          alt="商品画像"
          width={156}
          height={208}
          loading="lazy"
        />
      </picture>
      <ul className="item-info">
        <li className="item-part">
          <h3 className="item-name">{item.product.title}</h3>
        </li>
        <li className="item-part">
          <p className="item-price _red">¥{format(item.product.price)}</p>
        </li>
        <li className="item-part">
          <p className="item-post">投稿日：</p>
          <time dateTime="2021-05-01">
            {formatDate(item.product.createdAt)}
          </time>
        </li>
        <li className="item-part">
          <p className="item-period">掲載期間：</p>
          <time dateTime={item.product.createdAt}>
            {formatDate(item.product.createdAt)}
          </time>
          <span>~</span>
          {item.product.period && (
            <time
              dateTime={addDays(
                item.product.createdAt,
                item.product.period
              ).toDateString()}
            >
              {formatDate(addDays(item.product.createdAt, item.product.period))}
            </time>
          )}
        </li>
        <li className="item-part">
          <Link
            to={`/mypages/view/${item.id}`}
            state={{ id: item.product.id }}
            className="btn _message"
          >
            メッセージ確認
          </Link>
        </li>
        <li className="item-part">
          <p className="item-latest">
            最終コメント：
            <time dateTime="2021-05-01T10:23">
              {item.message && formatDateTime(item.message.createdAt)}
            </time>
          </p>
        </li>
      </ul>
    </div>
  </li>
);

export function SellDealing({ items }: { items: TChat[] }) {
  return (
    <section className="workspace-sec message _current" id="message">
      <h2 className="workspace-tit">メッセージ管理</h2>
      <nav className="tab">
        <ul className="tab-links">
          <li className="tab-link yellow _current">
            <Link to="/mypages/selcmtdealing" data-page="message">
              売りたい
            </Link>
          </li>
          <li className="tab-link yellow">
            <Link to="/mypages/buycmtdealing" data-page="message">
              買いたい
            </Link>
          </li>
        </ul>
      </nav>
      <div
        className="workspace-cnts message-body onsale _current"
        data-tab="onsale"
      >
        <div className="label">
          <p className="label-txt">ステータスで絞り込む：</p>
          <ul className="label-list">
            <li className="label-item dealing _active">
              <Link to="/mypages/selcmtdealing">取引中</Link>
            </li>
            <li className="label-item done">
              <Link to="/mypages/selcmtdealed">売却済</Link>
            </li>
          </ul>
        </div>
        <ul className="message-list">
          {items.map((item) => (
            <Item item={item} key={item.id} type="dealing" user="seller" />
          ))}
        </ul>
      </div>
      {/* 売りたい */}
    </section>
  );
}
