import { Link } from 'react-router-dom';
import logoFooter from '../../react-styles/img/common/common-logo_footer.svg';

export function Footer() {
  return (
    <footer className="footer">
      <div className="inner">
        <div className="footer-cnts">
          <Link to="/" className="footer-logo">
            <img
              src={logoFooter}
              alt="アマリー"
              width="145"
              height="33"
              loading="lazy"
            />
          </Link>
          <nav className="footer-cnt pc">
            <p className="footer-cnttit">その他のコンテンツ</p>
            <ul className="footer-links">
              <li className="footer-link">
                <Link to="/doc/terms_of_use">利用規約</Link>
              </li>
              <li className="footer-link">
                <Link to="/doc/privacypolicy">プライバシーポリシー</Link>
              </li>
              <li className="footer-link">
                <Link to="/doc/sitepolicy">サイトポリシー</Link>
              </li>
              {/* <li className="footer-link">
                <Link to="/">お問い合わせ</Link>
              </li> */}
              <li className="footer-link">
                <Link to="/doc/aboutus">運営会社</Link>
              </li>
              <li className="footer-link">
                <Link to="/doc/sitemap">サイトマップ</Link>
              </li>
              <li className="footer-link">
                <Link to="/doc/legalstatement">特定商取引法の表示</Link>
              </li>
            </ul>
          </nav>
          <nav className="footer-cnt sp">
            {/* <p className="footer-cnttit">会員サービス</p>
            <ul className="footer-links">
              <li className="footer-link">
                <Link to="/login">ログインページ</Link>
              </li>
              <li className="footer-link">
                <Link to="/register">新規会員登録</Link>
              </li>
              <li className="footer-link">
                <Link to="/mypage">マイページ</Link>
              </li>
            </ul> */}
            <p className="footer-cnttit">その他のコンテンツ</p>
            <ul className="footer-links">
              <li className="footer-link">
                <Link to="/doc/terms_of_use">利用規約</Link>
              </li>
              <li className="footer-link">
                <Link to="/doc/privacypolicy">プライバシーポリシー</Link>
              </li>
              <li className="footer-link">
                <Link to="/doc/sitepolicy">サイトポリシー</Link>
              </li>
              <li className="footer-link">
                <Link to="/doc/aboutus">運営会社</Link>
              </li>
              <li className="footer-link">
                <Link to="/doc/sitemap">サイトマップ</Link>
              </li>
              <li className="footer-link">
                <Link to="/doc/legalstatement">特定商取引法の表示</Link>
              </li>
            </ul>
          </nav>
        </div>
        <p className="copy">Copyright (C)&emsp;Amary all right reserved</p>
      </div>
    </footer>
  );
}
