import { useState } from 'react';
import { Footer } from '../../blocks/footer';
import { Header } from '../../blocks/header';
import { Sidebar } from '../../blocks/sidebar';
import { Workspace } from '../../blocks/workspace';
import { Setting } from '../../blocks/workspace/setting';

import '../../react-styles/css/mypage.scoped.css';

export function SettingPage() {
  const [show, setShow] = useState(false);

  return (
    <>
      <Header show={show} setShow={setShow} mypage />
      <main className="main">
        <Sidebar show={show} mypage onClose={() => setShow(false)} />
        <Workspace>
          <Setting />
        </Workspace>
      </main>
      <Footer />
    </>
  );
}
