import { FormEvent } from 'react';
import { useNavigate } from 'react-router';

import '../../react-styles/css/top.scoped.css';

export function MV() {
  const navigate = useNavigate();
  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      q: {
        value: string;
      };
    };
    const q = target.q.value;
    navigate(`/items/search?query=${q}`);
  }

  return (
    <section className="mv">
      <div className="inner">
        <h2 className="mv-txt">
          余ったら撮ってシェア
          <br />
          かんたん余剰建材
          <br className="sp" />
          マッチングサービス
        </h2>
        <form className="mv-search" id="mv-search" onSubmit={handleSubmit}>
          <input
            type="search"
            name="q"
            placeholder="ほしい資材名などをここに入力"
            maxLength={50}
            required
          />
          <button type="submit" form="mv-search">
            余っている資材を探す
          </button>
        </form>
      </div>
    </section>
  );
}
