import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Footer } from '../../blocks/footer';
import { Header } from '../../blocks/header';

import '../../react-styles/css/policy.scoped.css';
import { baseTitle } from '../auth/login';

export function LegalStatement() {
  useEffect(() => {
    document.title = `${baseTitle}特定商取引法に基づく表示`;
  }, []);

  return (
    <>
      <Header />
      <main className="main">
        <section className="legalstatement">
          <div className="inner">
            <nav className="links">
              <ul>
                <li className="link">
                  <Link to="/">ホーム</Link>
                </li>
                <li className="link _current">
                  <p>特定商取引法に基づく表示</p>
                </li>
              </ul>
            </nav>
            <div className="policy">
              <h2 className="policy-tit">特定商取引法に基づく表示</h2>
              <ul className="policy-list">
                <li className="policy-item">
                  <h3 className="policy-head">役務の対価</h3>
                  <p className="policy-cnt">
                    各商品に表示された価格に準じて1割
                  </p>
                </li>
                <li className="policy-item">
                  <h3 className="policy-head">役務の対価の支払い時期・方法</h3>
                  <p className="policy-cnt">
                    クレジットカード支払い時に、売り手に手数料を差し引いて支払うときに決済する
                  </p>
                </li>
                <li className="policy-item">
                  <h3 className="policy-head">商品代金以外に必要な費用</h3>
                  <ul className="policy-sublist">
                    <li className="policy-subitem">商品によっては配送費用</li>
                    <li className="policy-subitem">
                      支払方法により所定の手数料
                    </li>
                    <li className="policy-subitem">
                      インターネット利用時に、ご契約の通信業者への通信料
                    </li>
                    <li className="policy-subitem">
                      モバイル利用時に、ご契約の通信業者への通信料
                    </li>
                  </ul>
                </li>
                <li className="policy-item">
                  <h3 className="policy-head">役務の提供時期</h3>
                  <p className="policy-cnt">会員登録後、ただちに利用可能です</p>
                </li>
                <li className="policy-item">
                  <h3 className="policy-head">
                    申込みの撤回又は解除に関する事項
                  </h3>
                  <p className="policy-cnt">
                    役務の提供により、性質上返品不可です
                  </p>
                </li>
                <li className="policy-item">
                  <h3 className="policy-head">
                    事業者の氏名(名称)、住所、電話番号、通信販売の代表者
                  </h3>
                  <div className="policy-cnts">
                    <p className="policy-cnt">合同会社Amary</p>
                    <p className="policy-cnt">
                      〒231-0063 <br className="sp" />
                      神奈川県横浜市中区花咲町3-103-2 アマデウスビル7F
                    </p>
                    <p className="policy-cnt">代表者：山田元樹</p>
                    <p className="policy-cnt">
                      電話番号：<a href="tel:05036317700">050-3631-7700</a>
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
