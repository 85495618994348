import { createContext, useContext, useEffect, useState } from 'react';
import { TMessageBody, useSocket } from '../../hooks/useSocket';
import { Outlet } from 'react-router-dom';
import { useUserContext } from '../../App';
import { TMessage } from '../workspace/message/view';
import { baseTitle } from '../../pages/auth/login';
import { TBank } from '../workspace/bank';
import { ApiService } from '../../services/api';

type TChatContext = {
  notifications: number;
  joinRoom: (seller: number, customer: number, productId: number) => void;
  messages: TMessage[];
  sendMessage: (messageBody: TMessageBody) => void;
  setMessages: (messages: TMessage[]) => void;
  socket: any;
};

type TBankContext = {
  bank?: TBank;
  updateBank: (bank: TBank) => void;
};

export const ChatContext = createContext<TChatContext>({} as TChatContext);
export const useChatContext = () => useContext(ChatContext);

export const BankContext = createContext<TBankContext>({} as TBankContext);
export const useBankContext = () => useContext(BankContext);

export function Chat() {
  useEffect(() => {
    document.title = `${baseTitle}マイページ`;
  }, []);

  const {
    notifications,
    joinRoom,
    listenNotifications,
    disconnect,
    messages,
    setMessages,
    sendMessage,
    socket,
  } = useSocket();
  const { user } = useUserContext();

  useEffect(() => {
    listenNotifications(user.id);
    () => {
      disconnect();
    };
  }, []);

  const [bank, setBank] = useState<TBank>();

  useEffect(() => {
    (async function () {
      try {
        const data = await ApiService.getResources(
          'finance/get-bank-info',
          null
        );
        setBank(data);
      } catch (error: any) {
        console.log(error);
      }
    })();
  }, []);

  return (
    <ChatContext.Provider
      value={{
        notifications,
        joinRoom,
        messages,
        sendMessage,
        setMessages,
        socket,
      }}
    >
      <BankContext.Provider
        value={{ bank, updateBank: (bank: TBank) => setBank(bank) }}
      >
        <Outlet />
      </BankContext.Provider>
    </ChatContext.Provider>
  );
}
