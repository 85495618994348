import { Link } from 'react-router-dom';
import '../../react-styles/css/profile.scoped.css';
import { format } from '../news/news-item';
import soldout from '../../react-styles/img/soldout.png';

function Item({ product }: { product: any }) {
  return (
    <li className="item">
      <Link to={`/items/view/${product.id}`}>
        <div className="item-img">
          <img
            src={product.productContent[0]?.link}
            alt="無垢フローリング材"
            width={235}
            height={313}
            loading="lazy"
          />
          {product.soldOut && (
            <div className="soldout">
              <img src={soldout} width="80" height="150" loading="lazy" />
            </div>
          )}
        </div>
        <h3 className="item-name">{product.title}</h3>
        <p className="item-price">¥{format(product.price)}</p>
      </Link>
    </li>
  );
}

export function ProfileItems({
  products,
  username,
}: {
  products: any;
  username: string;
}) {
  return (
    <section className="items">
      <h2 className="items-tit">{username}が出品している商品</h2>
      <ul className="items-list">
        {products.products.map((product: any) => (
          <Item product={product} key={product.id} />
        ))}
      </ul>
    </section>
  );
}
