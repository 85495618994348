import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Footer } from '../../blocks/footer';
import { Header } from '../../blocks/header';

import '../../react-styles/css/policy.scoped.css';
import { baseTitle } from '../auth/login';

export function PrivacyPolicy() {
  useEffect(() => {
    document.title = `${baseTitle}プライバシーポリシー`;
  }, []);

  return (
    <>
      <Header />
      <main className="main">
        <section className="privacypolicy">
          <div className="inner">
            <nav className="links">
              <ul>
                <li className="link">
                  <Link to="/">ホーム</Link>
                </li>
                <li className="link _current">
                  <p>プライバシーポリシー</p>
                </li>
              </ul>
            </nav>
            <div className="policy">
              <h2 className="policy-tit">プライバシーポリシー</h2>
              <ol className="policy-list">
                <li className="policy-item">
                  <h3 className="policy-head">
                    プライバシーポリシーの適用範囲
                  </h3>
                  <p className="policy-cnt">
                    合同会社Amary(以下「当社」といいます)は、当社が取得した個人情報の取扱い
                    に関し、個人情報の保護に関する法律、個人情報保護に関するガイドライン等の指針、その他個人情報保護に関する関係法令を遵守します。
                  </p>
                </li>
                <li className="policy-item">
                  <h3 className="policy-head">個人情報の収集</h3>
                  <p className="policy-cnt">
                    当社は、当社が管理するインターネットによる情報提供サイト(以下「本サイト」といいます。)の運営に必要な範囲で、本サイトの法人利用者または一般利用者(以下
                    「ユーザー」といいます。)から、ユーザーに係る個人情報を適正に取得し、偽りやその他不正な手段によって取得することはありません。
                  </p>
                </li>
                <li className="policy-item">
                  <h3 className="policy-head">
                    収集する個人情報と個人情報の定義
                  </h3>
                  <p className="policy-cnt">
                    本サイトでは、個人情報についてユーザー個人に関する情報であって、当該情報を構成する氏名、住所、生年月日、性別その他の記述等により当該ユーザー個人を識別できる情報とします。
                  </p>
                </li>
                <li className="policy-item">
                  <h3 className="policy-head">個人情報の利用および目的</h3>
                  <p className="policy-cnt">
                    当社は、本人の同意がある場合および法令により認められている場合を除いて、以下の利用目的の範囲を超えて個人情報を取り扱うことはいたしません。
                  </p>
                  <ol className="policy-sublist">
                    <li className="policy-subitem">
                      ① 本サイトの運営、維持、管理
                    </li>
                    <li className="policy-subitem">
                      ② 本サイトを通じたサービスの提供及び紹介、改善
                    </li>
                    <li className="policy-subitem">
                      ③ 本サイトの品質向上のためのアンケート、キャンペーン
                    </li>
                    <li className="policy-subitem">
                      ④ 本サイトの有料サービス提供時の料金請求
                    </li>
                    <li className="policy-subitem">
                      ⑤ 利用規約違反に関する防止、解決
                    </li>
                    <li className="policy-subitem">
                      ⑥ 本サイト不正利用防止や安全性の確保のため
                    </li>
                    <li className="policy-subitem">⑦ アクセス状況の確認</li>
                    <li className="policy-subitem">
                      ⑧ 本サイトおよび第三者の広告の配信または表示
                    </li>
                  </ol>
                </li>
                <li className="policy-item">
                  <h3 className="policy-head">個人情報の外部委託</h3>
                  <p className="policy-cnt">
                    当社は、個人情報の取扱いを外部に委託することがあります。その場合には、適正な取り扱いを確保するための契約締結、実施状況の点検等を行います。
                  </p>
                </li>
                <li className="policy-item">
                  <h3 className="policy-head">第三者への情報提供</h3>
                  <p className="policy-cnt">
                    当社は、あらかじめ本人の同意を得ずに、個人情報を第三者に提供、開示いたしません。ただし、当社内のサービスの性質上、ユーザーの個人情報をユーザーに直接サービスを提供する当社の委託先である第三者に提供することが予定されています。その場合、利用目的の範囲内で、第三者への情報提供に同意を得ていることとします。
                  </p>
                </li>
                <li className="policy-item">
                  <h3 className="policy-head">
                    個人情報の管理および継続的改善
                  </h3>
                  <p className="policy-cnt">
                    当社は、本プライバシーポリシーを適宜見直し、その全部または一部を訂正することがあります。当社がこの改訂を行った場合、その旨を本プライバシーポリシーに明記します。
                  </p>
                </li>
                <li className="policy-item">
                  <h3 className="policy-head">
                    個人情報の訂正および利用停止、および開示の際の手数料
                  </h3>
                  <p className="policy-cnt">
                    当社では、個人情報の訂正、利用停止を本サイト内で行うことができるようにします。ただし、本サイト内で行うことができない場合は、下記のお問い合わせ先にて受け付けています。開示の際にかかる手数料は、ユーザーにいただく場合があります。ただし、法令に定める要件を満たさない等、開示等を拒絶する理由が認められる場合は、お申し出に添えない場合があります。
                  </p>
                </li>
                <li className="policy-item">
                  <h3 className="policy-head">個人情報の消去および破棄</h3>
                  <p className="policy-cnt">
                    当社は、個人情報の利用目的に照らしその必要性が失われたときは、個人情報を消去又は廃棄するものとし、当該消去及び廃棄は、外部流失等の危険を防止するために必要かつ適切な方法により、業務の遂行上必要な限りにおいて行います。
                  </p>
                </li>
                <li className="policy-item">
                  <h3 className="policy-head">外部サービスとの連携</h3>
                  <p className="policy-cnt">
                    本サイトにおいては、ユーザーが承諾することにより、第三者の運営する外部サービスのアカウントと接続、連携することができます。しかし、ユーザーが外部サービスに提供した情報については当社の管理下にあるものではなく、外部サービスの利用規約、プライバシーポリシーと当社の利用規約、プライバシーポリシー等がそれぞれ別に適用されます。
                  </p>
                </li>
                <li className="policy-item">
                  <h3 className="policy-head">クッキー(Cookie)等の利用</h3>
                  <p className="policy-cnt">
                    Cookieの使用を許可するかにつきましては、ユーザーが設定できます。また、Cookieの使用を禁止するようにブラウザの設定を変更することも可能です。ただし、Cookieの使用を禁止した場合、本サイトを正常に利用できない場合があります。
                  </p>
                </li>
                <li className="policy-item">
                  <h3 className="policy-head">その他免責</h3>
                  <p className="policy-cnt">
                    以下の場合、第三者による個人情報の取得に関し、当社は何ら責任を負いません。
                  </p>
                  <ol className="policy-sublist">
                    <li className="policy-subitem">
                      ①
                      ユーザー自らが別の手段を用いて他のユーザーに個人情報を明らかにした場合
                    </li>
                    <li className="policy-subitem">
                      ②
                      ユーザー自らが入力した情報またはその他ユーザーが入力した情報により、予期せずに本人が特定できてしまった場合
                    </li>
                    <li className="policy-subitem">
                      ③
                      ご本人以外のユーザーが個人を行き別できる情報を入手した場合
                    </li>
                  </ol>
                </li>
                <li className="policy-item">
                  <h3 className="policy-head">お問い合わせ先</h3>
                  <div className="policy-cnts">
                    <p className="policy-cnt">合同会社Amary 個人情報担当者宛</p>
                    <p className="policy-cnt">
                      〒231-0063
                      <br />
                      神奈川県横浜市中区花咲町3-103-2 アマデウスビル7F
                    </p>
                  </div>
                </li>
                <li className="policy-item">
                  <h3 className="policy-head">作成および改訂日</h3>
                  <p className="policy-cnt">
                    作成:2021年5月7日
                    <br />
                    改訂:2021年07月22日
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
