import { useState } from 'react';
import '../../react-styles/css/about.scoped.css';
import { Modal } from './modal';
// import '../../react-styles/css/about_logged.scoped.css';

export function Images({ images }: { images: { id: number; link: string }[] }) {
  const [current, setCurrent] = useState(0);
  const [active, setActive] = useState<number | null>(null);

  return (
    <>
      <div className="product-imgs">
        <ul className="product-mainimgs">
          {images.map((img, index) => (
            <li
              className={`product-mainimg ${
                current === index ? '_current' : ''
              }`}
              key={img.id}
              onClick={() => setActive(index)}
            >
              <img
                src={img.link}
                alt=""
                width={453}
                height={453}
                loading="lazy"
              />
            </li>
          ))}
        </ul>
        <ul className="product-subimgs">
          {images.map((img, index) => (
            <li
              className={`product-subimg ${
                current === index ? '_current' : ''
              }`}
              key={img.id}
              onClick={() => setCurrent(index)}
            >
              <img
                src={img.link}
                alt=""
                width={40}
                height={53}
                loading="lazy"
              />
            </li>
          ))}
        </ul>
      </div>
      <Modal images={images} active={active} setActive={setActive} />
    </>
  );
}
