import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Footer } from '../../blocks/footer';
import { Header } from '../../blocks/header';
import { ApiService } from '../../services/api';

import '../../react-styles/css/register.scoped.css';

import eyevisi from '../../react-styles/img/common/common-icon_eyevisi.svg';
import eyehide from '../../react-styles/img/common/common-icon_eyehide.svg';
import { Link } from 'react-router-dom';
import { baseTitle } from './login';

export function ChangePwd() {
  const [searchParams] = useSearchParams();
  const [confirm, setConfirm] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    document.title = `${baseTitle}Users`;
  }, []);

  async function handleSubmit(event: React.SyntheticEvent) {
    event.preventDefault();
    const target = event.target as typeof event.target & {
      password: { value: string };
      confirm: { value: string };
    };
    const { password, confirm } = target;

    if (confirm.value !== password.value) {
      setError('同じパスワードを入力してください。');
      return;
    }
    try {
      const data = await ApiService.postEvent('auth/recover-password', null, {
        token: searchParams.get('token'),
        password: password.value,
      });
      setConfirm(data.email);
    } catch (error) {
      setError('error');
    }
  }

  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  if (confirm) {
    return (
      <>
        <Header />
        <main className="main">
          <section className="register">
            <div className="inner">
              <nav className="links">
                <ul>
                  <li className="link">
                    <Link to="/">ホーム</Link>
                  </li>
                  <li className="link _current">
                    <p>パスワード変更完了</p>
                  </li>
                </ul>
              </nav>
              <h2 className="register-tit _center">パスワード変更完了</h2>
              <div className="confirm">
                <h3 className="confirm-tit">
                  パスワードの変更を受け付けました
                </h3>
                <p className="confirm-mail">{confirm}</p>
                <div className="confirm-link">
                  <Link to="/">トップページに戻る</Link>
                </div>
              </div>
            </div>
          </section>
        </main>
        <Footer />
      </>
    );
  }

  return (
    <>
      <Header />
      <main className="main">
        <section className="register">
          <div className="inner">
            <nav className="links">
              <ul>
                <li className="link">
                  <Link to="/">ホーム</Link>
                </li>
                <li className="link _current">
                  <p>パスワード再設定</p>
                </li>
              </ul>
            </nav>
            <h2 className="register-tit">パスワード再設定</h2>
            <p className="__common_error_msg">{error}</p>
            <form onSubmit={handleSubmit} id="register">
              <div style={{ display: 'none' }}>
                <input type="hidden" name="_method" defaultValue="POST" />
              </div>
              <div className="register-items _top">
                <div className="register-item">
                  <label htmlFor="password">
                    パスワード（半角英数字 8文字〜20文字）
                    <span className="_red">*</span>
                  </label>
                  <span className="visibility">
                    <input
                      type={show ? 'text' : 'password'}
                      name="password"
                      id="password"
                      pattern="(?:(?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*"
                      minLength={8}
                      maxLength={20}
                      required
                      autoComplete="new-password"
                      onInvalid={() =>
                        setError(
                          'パスワードは英大文字、英小文字、数字を全て含むものを設定してください'
                        )
                      }
                      onInput={() => setError('')}
                    />
                    <img
                      onClick={() => setShow((state) => !state)}
                      src={!show ? eyevisi : eyehide}
                      alt="表示"
                      width={18}
                      height={18}
                      loading="lazy"
                    />
                  </span>
                </div>
                <div className="register-item">
                  <label>
                    パスワード（確認のため再度入力）
                    <span className="_red">*</span>
                  </label>
                  <span className="visibility">
                    <input
                      type={showConfirm ? 'text' : 'password'}
                      name="confirm"
                      id="confirm"
                      pattern="(?:(?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*"
                      minLength={8}
                      maxLength={20}
                      required
                      onInvalid={() =>
                        setError(
                          'パスワードは英大文字、英小文字、数字を全て含むものを設定してください'
                        )
                      }
                      onInput={() => setError('')}
                    />
                    <img
                      onClick={() => setShowConfirm((state) => !state)}
                      src={!showConfirm ? eyevisi : eyehide}
                      alt="表示"
                      width={18}
                      height={18}
                      loading="lazy"
                    />
                  </span>
                </div>
              </div>
              <div className="register-items _bottom">
                <div className="register-item">
                  <div className="register-submit">
                    <button type="submit" form="register" className="btn _bold">
                      パスワード再設定
                    </button>
                  </div>
                </div>
              </div>
              <input
                type="hidden"
                name="data[User][token]"
                defaultValue="<?php echo $token;?>"
              />
            </form>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
