import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import '../../react-styles/css/newitem.scoped.css';

type TProps = {
  img: {
    id: number;
    src: any;
  };
  index: number;
  deleteImg: any;
  getSrc: any;
  onDropHandler: any;
};

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export function Img({ img, index, deleteImg, getSrc, onDropHandler }: TProps) {
  const ref = useRef<HTMLLIElement>(null);
  const [, drop] = useDrop<DragItem>({
    accept: 'image',
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      if (!clientOffset) return;
      // Get pixels to the top
      const hoverClientX = clientOffset.x - hoverBoundingRect.left;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
        return;
      }

      // Time to actually perform the action
      onDropHandler(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [, drag] = useDrag({
    type: 'image',
    item: () => {
      return { id: img.id, index };
    },
  });

  drag(drop(ref));

  return (
    <li className="form-img" ref={ref}>
      <label htmlFor={`image${img.id}`} onClick={(e) => deleteImg(e, index)}>
        <img src={img.src} width={70} height={92} loading="lazy" />
      </label>
      <input
        type="file"
        name={`image${img.id}`}
        id={`image${img.id}`}
        accept=".png,.jpg,.jpeg"
        onChange={(e) => getSrc(e, index)}
        style={{ display: 'none' }}
      />
    </li>
  );
}
