import { NewsItem } from './news-item';

import '../../react-styles/css/top.scoped.css';
import { TData } from '../../pages/home/search';
import { Link, useSearchParams } from 'react-router-dom';
import { usePaginatorContext } from '../../pages/home';

import '../../react-styles/css/top.scoped.css';
import '../../react-styles/css/results.scoped.css';
import { useParams } from 'react-router';

type TProps = {
  isSearch?: boolean;
};

function Paginator({ isSearch }: TProps) {
  const { currentPage, pageCount } = usePaginatorContext();
  const [searchParams] = useSearchParams();
  const query = searchParams.get('query');

  const { id } = useParams();

  const path = isSearch
    ? id
      ? `/items/search/${id}?page=`
      : `/items/search?query=${query}&page=`
    : '/items?page=';

  return (
    <nav className="news-pgnav">
      {currentPage > 1 && (
        <Link to={`${path}${currentPage - 1}`} className="_prev pc">
          前の16件
        </Link>
      )}
      <ul className="news-links">
        {Array.from({ length: pageCount }, (_, i) => i + 1).map((page) => (
          <li
            className={`news-link ${page === currentPage ? '_current' : ''}`}
            key={page}
          >
            {page === currentPage ? (
              <p>{page}</p>
            ) : (
              <Link to={`${path}${page}`}>{page}</Link>
            )}
          </li>
        ))}
        {/* <li className="news-link _insert">
          <p>…</p>
        </li> */}
      </ul>
      {currentPage < pageCount && (
        <Link to={`${path}${currentPage + 1}`} className="_next pc">
          次の16件
        </Link>
      )}
    </nav>
  );
}

export function News({
  data,
  isSearch,
  q,
  isCat,
}: {
  data: TData;
  isSearch?: boolean;
  q?: string | null;
  isCat?: boolean;
}) {
  return (
    <section className="news">
      <div className="inner">
        <h2 className="news-tit">
          {isSearch ? `${data.title || q}の検索結果` : '新着の商品'}
        </h2>
        {data.pageCount > 0 ? (
          <>
            <ul className="news-list">
              {(data.data || data.products).map((product: any) => (
                <NewsItem item={product} key={product.id} />
              ))}
            </ul>
            <Paginator isSearch={isSearch} />
          </>
        ) : (
          <>
            <ul className="news-list"></ul>
            <div className="results-cntarea">
              <p className="results-noitem">該当する商品がありません。</p>
            </div>
          </>
        )}
      </div>
    </section>
  );
}
