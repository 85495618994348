import { Link } from 'react-router-dom';
import { TParsedCat } from '.';
import catImg from '../../react-styles/img/top/top-category_img01.jpg';

import '../../react-styles/css/top.scoped.css';

export function Item({ item }: { item: TParsedCat }) {
  return (
    <li className="category-item">
      <picture className="category-img">
        <img
          src={item.item.image || catImg}
          alt="安全保護具・作業服・安全靴"
          width="640"
          height="427"
          loading="lazy"
        />
      </picture>
      <div className="category-cnt">
        <h4 className="category-head">
          <Link to={`/items/search/${item.item.id}`}>{item.item.title}</Link>
        </h4>
        <ul className="category-links">
          {item.children.map((item) => (
            <li className="category-link" key={item.id}>
              <Link to={`/items/search/${item.id}`}>{item.title}</Link>
            </li>
          ))}
        </ul>
      </div>
    </li>
  );
}
