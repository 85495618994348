import { useNavigate, useSearchParams } from 'react-router-dom';
import { Footer } from '../../../blocks/footer';
import { Header } from '../../../blocks/header';
import { ApiService } from '../../../services/api';

import '../../../react-styles/css/register.scoped.css';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { baseTitle } from '../login';
import { useUserContext } from '../../../App';

export function Add() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { user, updateUser } = useUserContext();
  const [error, setError] = useState('');

  useEffect(() => {
    document.title = `${baseTitle}Users`;
  }, []);

  async function handleSubmit(event: any) {
    try {
      event.preventDefault();
      const { year, month, date, gender } = event.target;
      const token = searchParams.get('jwt');
      const code = searchParams.get('token');
      await ApiService.postEvent(`auth/end_registration`, token, {
        code,
        dateOfBirth: `${year.value}/${month.value}/${date.value}`,
        sex: gender.value,
      });
      navigate('/users/login');
      if (user) {
        const data = await ApiService.getResources('auth/user', null);
        updateUser(data);
      }
    } catch (error: any) {
      console.log(error);
      if (error?.statusCode === 401) {
        setError('JWT is Invalid');
      }
    }
  }

  return (
    <>
      <Header />
      <main className="main">
        <section className="register">
          <div className="inner">
            <nav className="links">
              <ul>
                <li className="link">
                  <Link to="/">ホーム</Link>
                </li>
                <li className="link _current">
                  <p>本人情報の登録</p>
                </li>
              </ul>
            </nav>
            <h2 className="register-tit">本人情報の登録</h2>
            <p className="__common_error_msg">{error}</p>
            <form onSubmit={handleSubmit} id="auth">
              <div className="register-items _top">
                <div className="register-item">
                  <label htmlFor="year">生年月日</label>
                  <div className="register-cnt">
                    <select name="year" id="year">
                      <option value="" selected>
                        年
                      </option>
                      {Array.from({ length: 84 }, (_, i) => i + 1920).map(
                        (year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        )
                      )}
                    </select>
                    <select name="month" id="month">
                      <option value="" selected>
                        月
                      </option>
                      {Array.from({ length: 12 }, (_, i) => i + 1).map(
                        (year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        )
                      )}
                    </select>
                    <select name="date" id="date">
                      <option value="" selected>
                        日
                      </option>
                      {Array.from({ length: 31 }, (_, i) => i + 1).map(
                        (year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                </div>
                <div className="register-item">
                  <label htmlFor="gender">性別</label>
                  <select name="gender" id="gender">
                    <option value="" selected>
                      選択してください
                    </option>
                    <option value="男性" selected>
                      男性
                    </option>
                    <option value="女性">女性</option>
                  </select>
                </div>
              </div>
              <div className="register-items _bottom">
                <div className="register-item">
                  <div className="register-submit">
                    <button type="submit" form="auth" className="btn _bold">
                      無料会員登録
                    </button>
                  </div>
                  <Link to="/" className="btn _bold">
                    スキップする
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
