import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ADDRAjax } from '../../addr';
import { UserContext } from '../../App';
import { ApiService } from '../../services/api';

import '../../react-styles/css/mypage.scoped.css';

const dd = new ADDRAjax('pref', 'city', 'area');
dd.JSONDATA = '/data';

export function Setting() {
  const { user, updateUser } = useContext(UserContext);
  const [error, setError] = useState('');

  useEffect(() => {
    dd.init();
  }, []);

  useEffect(() => {
    if (user) {
      dd.setAddress(user.prefecture, user.city, user.area);
    }
  }, [user]);

  async function handleSubmit(event: any) {
    try {
      event.preventDefault();
      const {
        pref,
        city,
        area,
        address,
        name,
        gender,
        year,
        month,
        date,
        description,
        tel,
      } = event.target;
      const body = {
        name: name.value,
        prefecture: pref?.value,
        city: city?.value,
        area: area?.value,
        address: address?.value || '',
        sex: gender?.value || '',
        dateOfBirth: `${year.value}/${month.value}/${date.value}`,
        bio: description.value,
        phone: tel?.value || '',
      };
      const data = await ApiService.postEvent('user/edit', null, body);
      updateUser(data);
      setError('変更しました。');
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <section className="workspace-sec setting _current" id="setting">
      <h2 className="workspace-tit">設定</h2>
      <p className="__common_error_msg">{error}</p>
      <form onSubmit={handleSubmit} className="form">
        <div className="form-item">
          <label htmlFor="email">登録メールアドレス</label>
          {user.email}
        </div>
        <div className="form-item">
          <label htmlFor="name">
            ユーザー名<span>（10文字以内）</span>
          </label>
          <input
            type="text"
            name="name"
            id="name"
            // defaultValue="sample"
            defaultValue={user.name}
            maxLength={10}
            required
          />
        </div>
        <div className="form-item">
          <label htmlFor="gender">性別</label>
          <span className="form-icon">
            <select
              name="gender"
              className="_small"
              id="gender"
              defaultValue={user.sex}
            >
              <option value="男性" selected>
                男性
              </option>
              <option value="女性">女性</option>
            </select>
          </span>
        </div>
        <div className="form-item">
          <label>お住まいの地域</label>
          <div className="form-cnt col">
            <span className="form-icon">
              <select name="pref" id="pref">
                <option value="" selected>
                  都道府県
                </option>
              </select>
            </span>
            <span className="form-icon">
              <select name="city" id="city">
                <option value="" selected>
                  郡市区
                </option>
              </select>
            </span>
            <span className="form-icon">
              <select name="area" id="area">
                <option value="" selected>
                  町村
                </option>
              </select>
            </span>
            <input
              id="address"
              type="text"
              className="_full"
              autoComplete="address-level3"
              placeholder="番地以下を入力"
              defaultValue={user.address}
            />
          </div>
        </div>
        <div className="form-item">
          <label htmlFor="year">
            生年月日<span>（非公開）</span>
          </label>
          <div className="form-cnt">
            <span className="form-icon">
              <select
                name="year"
                className="_small"
                id="year"
                defaultValue={user.dateOfBirth?.split('-')[0]}
              >
                {Array.from({ length: 84 }, (_, i) => i + 1920).map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </span>
            <span>年</span>
            <span className="form-icon">
              <select
                name="month"
                className="_small"
                id="month"
                defaultValue={+user.dateOfBirth?.split('-')[1]}
              >
                {Array.from({ length: 12 }, (_, i) => i + 1).map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </span>
            <span>月</span>
            <span className="form-icon">
              <select
                name="date"
                className="_small"
                id="date"
                defaultValue={+user.dateOfBirth?.split('-')[2]}
              >
                {Array.from({ length: 31 }, (_, i) => i + 1).map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </span>
            <span>日</span>
          </div>
        </div>
        <div className="form-item">
          <label htmlFor="description">
            自己紹介
            <span>
              （電話番号やメールアドレスなど直接連絡の取れる個人情報は記載しないでください）
            </span>
          </label>
          <textarea
            name="description"
            className="_full"
            id="description"
            maxLength={15000}
            cols={30}
            rows={10}
            defaultValue={user.bio}
          />
        </div>
        <div className="form-item">
          <label htmlFor="tel">
            電話番号<span>（問合わせ用）</span>
          </label>
          <input
            type="tel"
            name="tel"
            id="tel"
            pattern="[0-9]+"
            minLength={7}
            maxLength={15}
            defaultValue={user.phone}
          />
        </div>
        <div className="form-item submit">
          <button type="submit" className="btn _large">
            登録情報を変更する
          </button>
        </div>
      </form>
      <div className="setting-links">
        <p className="setting-link">
          パスワードを変更する場合は<Link to="/users/repassword">こちら</Link>
        </p>
      </div>
    </section>
  );
}
