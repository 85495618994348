import { useLocation, useNavigate } from 'react-router';
import { Footer } from '../../blocks/footer';
import { Header } from '../../blocks/header';
import { ApiService } from '../../services/api';

import '../../react-styles/css/newitem.scoped.css';
import '../../react-styles/css/inquiry.scoped.css';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { baseTitle } from '../auth/login';

export function Msg() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = `${baseTitle}出品者に問い合わせる`;
  }, []);

  async function handleSubmit(event: any) {
    try {
      event.preventDefault();
      const { content } = event.target;
      await ApiService.postEvent('chat/create-chat', null, {
        text: content.value,
        //@ts-expect-error fix this
        seller: location.state.seller,
        //@ts-expect-error fix this
        productId: location.state.productId,
      });
      navigate('/');
    } catch (error) {
      console.log(error);
    }
  }
  //@ts-expect-error fix this
  const id = location.state.productId;
  //@ts-expect-error fix this
  const title = location.state.title;

  if (!location.state) return null;

  return (
    <>
      <Header />
      <main className="main">
        <section className="inquiry">
          <div className="inner">
            <nav className="links">
              <ul>
                <li className="link">
                  <Link to="/">ホーム</Link>
                </li>
                <li className="link">
                  <Link to={`/items/view/${id}`}>{title}</Link>
                </li>
                <li className="link _current">
                  <p>出品者に問い合わせる</p>
                </li>
              </ul>
            </nav>
            <h2 className="inquiry-tit">出品者に問い合わせる</h2>
            <form onSubmit={handleSubmit} className="form" id="inquiry">
              <div className="form-item">
                <label htmlFor="content">問い合わせ内容</label>
                <textarea
                  name="body"
                  className="_full"
                  id="content"
                  cols={30}
                  rows={10}
                  placeholder="相手が認識できる自分のニックネームや名前を最初に書きましょう。いくらで売って欲しいのか、どうして欲しいのかどのような方法で商品の受け渡しと支払いをしたいかを書きましょう。"
                ></textarea>
              </div>
              <div className="form-item terms">
                <div className="register-accept">
                  <input type="checkbox" name="confirm" id="accept" required />
                  <label htmlFor="accept">
                    アマリーの
                    <Link
                      to="/doc/terms_of_use"
                      className="register-link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      利用規約
                    </Link>
                    、
                    <Link
                      to="/doc/privacypolicy"
                      className="register-link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      プライバシーポリシー
                    </Link>
                    に同意します。
                  </label>
                </div>
                <div className="form-submit">
                  <button type="submit" form="inquiry" className="btn _large">
                    送信する
                  </button>
                </div>
              </div>
            </form>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
