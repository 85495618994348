import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ApiService } from '../../services/api';

import '../../react-styles/css/mypage.scoped.css';
import { format } from '../news/news-item';

export function Like() {
  const [likes, setLikes] = useState<any>();

  useEffect(() => {
    (async function () {
      const data = await ApiService.getResources(`favorite/get/1`, null);
      setLikes(data);
    })();
  }, []);

  async function onRemove(productId: number) {
    try {
      await ApiService.deleteEvent('favorite/delete', null, {
        productId,
      });
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  }

  if (!likes) return null;

  return (
    <section className={`workspace-sec like _current`} id="like">
      <h2 className="workspace-tit">お気に入りの商品</h2>
      <ul className="like-list">
        {likes.data.map((like: any) => (
          <li className="like-item litem" key={like.id}>
            <Link to={`/items/view/${like.id}`} className="litem-link">
              <div className="litem-img">
                <img
                  src={like.productContent[0].link}
                  alt="商品画像"
                  width={235}
                  height={313}
                  loading="lazy"
                />
              </div>
              <h4 className="litem-name">{like.title}</h4>
              <p className="litem-price _red">¥{format(like.price)}</p>
            </Link>
            <button
              onClick={() => onRemove(like.id)}
              className="litem-delete link-btn"
            >
              お気に入り解除
            </button>
          </li>
        ))}
      </ul>
    </section>
  );
}
