import { Link, useNavigate } from 'react-router-dom';
import { Footer } from '../../../blocks/footer';
import { Header } from '../../../blocks/header';
import { ApiService } from '../../../services/api';

import '../../../react-styles/css/register.scoped.css';
import { baseTitle } from '../login';
import { useEffect, useState } from 'react';

export function Reset() {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = `${baseTitle}Users`;
  }, []);

  const [error, setError] = useState('');

  async function handleSubmit(event: any) {
    event.preventDefault();
    const { email } = event.target;
    try {
      await ApiService.postEvent('auth/recover-password-send-mail', null, {
        email: email.value,
      });
      navigate('/users/repwd', { state: { email: email.value } });
    } catch (error: any) {
      if (error.statusCode === 401) {
        setError('ユーザが存在しません。');
      } else {
        setError(error.message);
      }
    }
  }

  return (
    <>
      <Header />
      <main className="main">
        <section className="register">
          <div className="inner">
            <nav className="links">
              <ul>
                <li className="link">
                  <Link to="/">ホーム</Link>
                </li>
                <li className="link">
                  <Link to="/users/login">ログイン</Link>
                </li>
                <li className="link _current">
                  <p>パスワード再設定</p>
                </li>
              </ul>
            </nav>
            <h2 className="register-tit">パスワード再設定</h2>
            {error && <p className="__common_error_msg">{error}</p>}
            <form onSubmit={handleSubmit} id="reset">
              <div className="register-items _top">
                <div className="register-item">
                  <label htmlFor="email">メールアドレス</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="amary@amary.jp"
                    required
                  />
                </div>
                <div className="register-item">
                  <div className="register-submit">
                    <button type="submit" form="reset" className="btn _bold">
                      送信する
                    </button>
                  </div>
                </div>
                {/* <div className="register-item">
                  <p className="register-txt">
                    ログインは<Link to="/users/login">こちら</Link>
                  </p>
                  <p className="register-txt">
                    アマリーの会員登録をされていない方は
                    <Link to="/users/regist">こちら</Link>から
                    <br className="sp" />
                    無料会員登録
                  </p>
                </div> */}
              </div>
            </form>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
