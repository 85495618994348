/* eslint-disable no-irregular-whitespace */
import { useEffect, useState } from 'react';
import { useUserContext } from '../../App';
import { ApiService } from '../../services/api';

import '../../react-styles/css/mypage.scoped.css';
import { formatDateTime } from './sell/onsale';
import { useBankContext } from '../chat';

type TFinance = {
  id: number;
  date: string;
  amount: number;
  status: string;
};

const getDate = (date: string) => {
  const d = new Date(date);
  let m: string | number = d.getMonth() + 1;
  m = m < 10 ? '0' + m : m;
  return `${d.getFullYear()}${m}${d.getDate()}`;
};

const getStatus = (status: string, id: number, date: string) => {
  switch (status) {
    case 'RECEIPT':
      return `カード：売り上げ確定(注文ID${getDate(date)}${id})`;
    case 'FAILED':
      return `振込申請のキャンセル(注文ID${getDate(date)}${id})`;
    case 'SUCCESS':
      return `振込完了(注文ID:${getDate(date)}${id})`;
    case 'PENDING':
      return `振込申請(注文ID:${getDate(date)}${id})`;
  }
};

export function Transfer() {
  const { user, company } = useUserContext();
  const { bank } = useBankContext();

  const [data, setData] = useState<TFinance[]>([]);
  const [balance, setBalance] = useState(0);
  const [error, setError] = useState('');

  useEffect(() => {
    (async function () {
      try {
        const balance = await ApiService.getResources(
          `finance/get-balance`,
          null
        );
        setBalance(balance);
        const data = await ApiService.getResources(`finance/get-history`, null);
        setData(data);
      } catch (error: any) {
        console.log(error);
      }
    })();
  }, []);

  async function handleWithdraw() {
    try {
      await ApiService.postEvent('finance/withdraw', null);
      setBalance(0);
    } catch (error: any) {
      console.log(error);
      setError(error?.message || 'withdraw error');
    }
  }

  if (!user) return null;

  return (
    <section className="workspace-sec corporate _current" id="corporate">
      <h2 className="workspace-tit">振込申請</h2>
      {company && (
        <div className="earning-box">
          <p className="earning-tit">現在の売上金</p>
          <div className="earning-money">¥{balance}</div>
        </div>
      )}
      <div className="post" id="furikomi">
        {/* <?php if ($is_bank == 1 && $userinfo['point'] > 0) : ?>
		        <?php endif; ?> */}
        {balance > 0 && company && bank && (
          <div className="post-item submit transfer-submit">
            <button onClick={handleWithdraw} name="furikomi" className="btn">
              振込申請をする
            </button>
          </div>
        )}
      </div>
      {error && <p className="__common_error_msg">{error}</p>}
      <h3 className="workspace-subtit">取引履歴</h3>

      <table className="trdhist">
        <tbody>
          <tr>
            <th>日付</th>
            <th>概要</th>
            <th>金額</th>
          </tr>
          {data.map((item) => (
            <tr key={item.date}>
              <td>
                {/* <?= date('Y年m月d日 H:i',  strtotime($item['FurikomiLog']['created'])) ?> */}
                {formatDateTime(item.date)}
              </td>
              <td>{getStatus(item.status, item.id, item.date)}</td>
              <td>
                {/* ¥<?= $item['FurikomiLog']['kingaku'] ?> */}¥{item.amount}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* <?php if ($this->Paginator->hasPage()) : ?>
	
	<nav className="news-pgnav">
		<?php
		if ($this->Paginator->hasPrev()) {
			echo $this->Paginator->prev('前の15件', array('className' => '_prev'));
		}
		?>
		<ul className="news-links">
			<?php echo $this->Paginator->numbers(array('separator' => '　', 'className' => 'news-link', 'tag' => 'li', 'currentTag' => 'p', 'currentclassName' => '_current')) ?>
		</ul>
		<?php if ($this->Paginator->hasNext()) echo $this->Paginator->next('次の15件', array('className' => '_next')) ?>
	</nav>
<?php endif; ?> */}
    </section>
  );
}
