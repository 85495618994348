import { createContext, useContext, useEffect, useState } from 'react';
// import { Area } from '../../blocks/area';
import { Category } from '../../blocks/category';
import { Footer } from '../../blocks/footer';
import { Header } from '../../blocks/header';
import { MV } from '../../blocks/mv';
import { News } from '../../blocks/news';
import { ApiService } from '../../services/api';

import '../../react-styles/css/top.scoped.css';
import { TItem } from '../../blocks/news/news-item';
import { useSearchParams } from 'react-router-dom';
import { baseTitle } from '../auth/login';

export type TData = {
  data: TItem[];
  products: TItem[];
  page: number;
  pageCount: number;
};

type TPaginatorContext = {
  currentPage: number;
  pageCount: number;
  setPage: (page: number) => void;
};

export const PaginatorContext = createContext<TPaginatorContext>(
  {} as TPaginatorContext
);

export const usePaginatorContext = () => useContext(PaginatorContext);

export function Home() {
  const [product, setProduct] = useState<TData | null>(null);
  const [searchParams] = useSearchParams();
  const [currentPage, setPage] = useState(+(searchParams.get('page') || 1));

  useEffect(() => {
    document.title = `${baseTitle}余剰建材マッチングサービス`;
  }, []);

  useEffect(() => {
    setPage(+(searchParams.get('page') || 1));
  }, [searchParams]);

  useEffect(() => {
    (async function () {
      const data = await ApiService.getResources(
        `product/get-all/${currentPage}`,
        null
      );
      setProduct(data);
    })();
  }, [currentPage]);

  if (!product) return null;

  return (
    <>
      <Header />
      <main className="main">
        <MV />
        {
          <PaginatorContext.Provider
            value={{
              currentPage,
              pageCount: product.pageCount,
              setPage: (page: number) => setPage(page),
            }}
          >
            <News data={product} />
          </PaginatorContext.Provider>
        }
        <Category />
        {/* <Area /> */}
      </main>
      <Footer />
    </>
  );
}
