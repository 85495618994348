import { useLocation, useNavigate } from 'react-router';
import { Footer } from '../../blocks/footer';
import { Header } from '../../blocks/header';
import { ApiService } from '../../services/api';

import '../../react-styles/css/newitem.scoped.css';
import '../../react-styles/css/inquiry.scoped.css';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { baseTitle } from '../auth/login';

export function Inquiry() {
  const location = useLocation();
  const navigate = useNavigate();
  const [error, setError] = useState('');

  const state = location.state as { id: string } | undefined;

  useEffect(() => {
    if (!state) {
      navigate('/404');
    }
  }, []);

  useEffect(() => {
    document.title = `${baseTitle}管理者に問い合わせ`;
  }, []);

  async function handleSubmit(event: any) {
    if (state) {
      try {
        event.preventDefault();
        const { body } = event.target;
        await ApiService.postEvent('chat/report', null, {
          text: body.value,
          idChat: state.id,
        });
        navigate('/mypages');
      } catch (error: any) {
        if (error?.statusCode === 500) {
          setError('500: Internal server error');
          scrollTo(0, 0);
        }
        console.log(error);
      }
    }
  }

  return (
    <>
      <Header />
      <section className="inquiry">
        <div className="inner">
          <nav className="links">
            <ul>
              <li className="link">
                <Link to="/">ホーム</Link>
              </li>
              <li className="link _current">
                <p>管理者に問い合わせる</p>
              </li>
            </ul>
          </nav>
          <h2 className="inquiry-tit">管理者に問い合わせる</h2>
          <p className="__common_error_msg">{error}</p>
          <form onSubmit={handleSubmit} className="form" id="inquiry">
            <div className="form-item">
              <label htmlFor="content">問い合わせ内容</label>
              <textarea
                name="body"
                className="_full"
                id="content"
                cols={30}
                rows={10}
                placeholder="相手が認識できる自分のニックネームや名前を最初に書きましょう。いくらで売って欲しいのか、どうして欲しいのかどのような方法で商品の受け渡しと支払いをしたいかを書きましょう。"
              ></textarea>
            </div>
            <div className="form-item">
              <p>
                <Link to="/doc/terms_of_use">利用規約</Link>と
                <Link to="/doc/privacypolicy">プライバシーポリシー</Link>
                に同意し、
              </p>
              <div className="form-submit">
                <button type="submit" form="inquiry" className="btn _large">
                  送信する
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
      <Footer />
    </>
  );
}
