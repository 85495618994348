import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import '../../../react-styles/css/mypage.scoped.css';
import { ApiService } from '../../../services/api';
import { format } from '../../news/news-item';
import { addDays } from '../../product/summary';

export type TItem = {
  id: number;
  title: string;
  createdAt: string;
  price: number;
  productContent: any;
  period: number;
  favoriteCount: number;
  isCanceled: boolean;
  customer: {
    id: number;
    name: string;
  };
  seller: {
    id: number;
    name: string;
  };
  tradingStatus: 'SENT' | 'TRADING' | 'SALES';
  tradingCreated: string;
  companyName: string | undefined;
};

export const formatDateTime = (date: string) =>
  new Date(date).toLocaleString('ja-JP', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });

export const formatDate = (date: string | Date) =>
  (date instanceof Date ? date : new Date(date)).toLocaleString('ja-JP', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

export const Item = ({
  item,
  children,
  isDone,
}: {
  item: TItem;
  children?: ReactNode;
  isDone?: boolean;
}) => (
  <li className={`sell-item item ${isDone ? 'sold' : ''}`}>
    <picture className="item-img">
      <Link to={`/items/view/${item.id}`}>
        <img
          src={item.productContent[0]?.link}
          alt="商品画像"
          width={156}
          height={208}
          loading="lazy"
        />
      </Link>
    </picture>
    <ul className="item-info">
      <li className="item-part">
        <h3 className="item-name">
          <Link to={`/items/view/${item.id}`}>{item.title}</Link>
        </h3>
      </li>
      <li className="item-part">
        <p className="item-price _red">¥{format(item.price)}</p>
      </li>
      <li className="item-part">
        <p className="item-post">投稿日：</p>
        <time dateTime="2021-05-06">{formatDateTime(item.createdAt)}</time>
      </li>
      <li className="item-part">
        <p className="item-period">掲載期間：</p>
        <time dateTime={item.createdAt}>{formatDate(item.createdAt)}</time>
        <span>~</span>
        {item.period && (
          <time dateTime={addDays(item.createdAt, item.period).toDateString()}>
            {addDays(item.createdAt, item.period).toLocaleDateString()}
          </time>
        )}
      </li>
      {!isDone && (
        <li className="item-part">
          <p className="item-like">お気に入り数：</p>
          <p>
            <span>{item.favoriteCount}</span>件
          </p>
        </li>
      )}
      {children}
    </ul>
  </li>
);

export function OnSale({
  currentTab,
  items,
}: {
  currentTab: string;
  items: any[];
}) {
  async function handleToEnd(id: number) {
    try {
      await ApiService.postEvent(`product/change-cancel/${id}`, null);
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div
      className={`workspace-cnts sell-body onsale ${
        currentTab === 'onsale' ? '_current' : ''
      }`}
      data-tab="onsale"
    >
      <ul className="sell-list">
        {items.map((item: any) => (
          <Item item={item} key={item.id}>
            <li className="item-part">
              <Link to={`/items/edit/${item.id}`} className="item-edit">
                登録情報を編集
              </Link>
            </li>
            <li className="item-part">
              <button
                onClick={() => handleToEnd(item.id)}
                className="item-edit link-btn"
              >
                掲載終了にする
              </button>
            </li>
          </Item>
        ))}
        {/* 販売中商品 */}
      </ul>
    </div>
  );
}
