import { Details } from './details';
import { Summary } from './summary';

export function ItemMain({ product }: { product: any }) {
  return (
    <>
      <Summary product={product} />
      <Details product={product} />
    </>
  );
}
