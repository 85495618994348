import { useState } from 'react';
import { TData } from '../../../pages/my-pages';
import { Dealing } from './dealing';
import { Done } from './done';
import { OnSale } from './onsale';

import '../../../react-styles/css/mypage.scoped.css';
import { Link } from 'react-router-dom';

export function Sell({ data: { onsale, dealing, done } }: { data: TData }) {
  const [currentTab, setTab] = useState('onsale');

  return (
    <section className="workspace-sec sell _current" id="sell">
      {/* <p class="__common_error_msg"><?php echo $error ?></p> */}
      <h2 className="workspace-tit">出品管理</h2>
      <nav className="tab">
        <ul className="tab-links">
          <li
            className={`tab-link yellow ${
              currentTab === 'onsale' ? '_current' : ''
            }`}
          >
            <Link
              to="#onsale"
              data-page="sell"
              onClick={() => setTab('onsale')}
            >
              販売中
            </Link>
          </li>
          <li
            className={`tab-link green ${
              currentTab === 'dealing' ? '_current' : ''
            }`}
          >
            <Link
              to="#dealing"
              data-page="sell"
              onClick={() => setTab('dealing')}
            >
              取引中
            </Link>
          </li>
          <li
            className={`tab-link red ${
              currentTab === 'done' ? '_current' : ''
            }`}
          >
            {/* ↓ 「<br class="sp">」追加 ↓ */}
            <Link to="#done" data-page="sell" onClick={() => setTab('done')}>
              売却済・
              <br className="sp" />
              掲載終了
            </Link>
            {/* ↑ 「<br class="sp">」追加 ↑ */}
          </li>
        </ul>
      </nav>
      <OnSale currentTab={currentTab} items={onsale} />
      {/* 販売中 */}
      <Dealing currentTab={currentTab} items={dealing} />
      {/* 取引中 */}
      <Done currentTab={currentTab} items={done} />
      {/* 売却済・掲載終了 */}
    </section>
  );
}
