import logo from '../../react-styles/img/common/common-logo_img.svg';
import logoHeader from '../../react-styles/img/common/common-logo_header.svg';
import iconSearchSp from '../../react-styles/img/common/common-icon_search_sp.svg';
import iconSearch from '../../react-styles/img/common/common-icon_search.svg';
import { UserContext } from '../../App';
import { FormEvent, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';

import '../../react-styles/css/top.scoped.css';

export function Header(properties: any) {
  const { user, logout } = useContext(UserContext);
  const navigate = useNavigate();

  const { show, mypage, setShow, ...props } = properties;

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      q: {
        value: string;
      };
    };
    const q = target.q.value;
    navigate(`/items/search?query=${q}`);
  }

  return (
    <header className="header" {...props}>
      <div className="header-inner" {...props}>
        <form
          className="header-search pc"
          id="header-search"
          onSubmit={handleSubmit}
          {...props}
        >
          <input
            type="search"
            name="q"
            placeholder="キーワード検索"
            pattern="[^\x22\x27]*"
            required
          />
          <button type="submit" form="header-search">
            <img
              src={iconSearch}
              alt="虫眼鏡アイコン"
              width="18"
              height="18"
              loading="lazy"
            />
          </button>
        </form>
        <div className="header-icon sp" {...props}>
          <label htmlFor="search-toggle">
            <img
              src={iconSearchSp}
              alt="検索アイコン"
              width="69"
              height="78"
              loading="lazy"
            />
          </label>
        </div>
        <h1 className="header-logo" {...props}>
          <Link to="/">
            <img
              src={logo}
              alt="アマリー アイコン"
              width="48"
              height="48"
              loading="lazy"
            />
            <img
              src={logoHeader}
              alt="アマリー"
              width="180"
              height="40"
              loading="lazy"
            />
          </Link>
        </h1>
        <nav className={`header-btns ${mypage ? 'pc' : ''}`} {...props}>
          {!user ? (
            <>
              <Link
                to="/users/login"
                className="header-btn"
                id="login"
                {...props}
              >
                <span>ログイン</span>
              </Link>
              <Link
                to="/users/regist"
                className="header-btn"
                id="regist"
                {...props}
              >
                <span>会員登録</span>
              </Link>
            </>
          ) : (
            <>
              <Link to="/mypages" className="header-btn" id="mypage" {...props}>
                <span>マイページ</span>
              </Link>
              <button
                onClick={logout}
                className="header-btn link-btn"
                id="logout"
                {...props}
              >
                <span>ログアウト</span>
              </button>
            </>
          )}
        </nav>
        {mypage && (
          <div className="drawer" {...props}>
            <input
              id="drawer-checkbox"
              type="checkbox"
              name="drawer"
              {...props}
              checked={show}
              onChange={(e) => setShow(e.target.checked)}
            />
            <label id="drawer-icon" htmlFor="drawer-checkbox" {...props}>
              <span {...props}></span>
            </label>
            <label id="drawer-close" htmlFor="drawer-checkbox"></label>
          </div>
        )}
      </div>
      <div className="header-search sp" {...props}>
        <input type="checkbox" id="search-toggle" />
        <form id="header-searchsp" role="search" onSubmit={handleSubmit}>
          <input
            type="search"
            name="q"
            placeholder="ほしい資材名などをここに入力"
            pattern="[^\x22\x27]*"
            required
          />
        </form>
      </div>
    </header>
  );
}
