import { Link } from 'react-router-dom';
import { Images } from './images';
import { TExtProduct } from '../../pages/product';
import { useUserContext } from '../../App';

import '../../react-styles/css/about.scoped.css';
// import '../../react-styles/css/about_logged.scoped.css';
import { format } from '../news/news-item';
import { formatDate } from '../workspace/sell/onsale';
import { ReactComponent as ReactLogo } from '../../react-styles/img/common/verified.svg';

export function addDays(date: string, days: number) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export function Summary({ product }: { product: TExtProduct }) {
  const { user } = useUserContext();

  return (
    <div className="product-summary">
      <div className="product-head">
        <h2 className="product-tit">{product.title}</h2>
        <p className="product-price">
          {!product.soldOut ? (
            <span className="product-price">¥{format(product.price)}</span>
          ) : (
            <>
              <span className="product-price del">
                ¥{format(product.price)}
              </span>
              <span className="label_soldout">売り切れ</span>
            </>
          )}
          {(product.paymentMethod === '1' || product.paymentMethod === '2') && (
            <span className="label_card">カード決済OK</span>
          )}
        </p>
        <ul className="product-list">
          {(user || product.user.certification) && (
            <li className="product-item">
              {product.user.certification && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <ReactLogo />
                  <span
                    style={{
                      margin: '0 10px 0 3px',
                      color: '#FFC215',
                      fontSize: '12px',
                    }}
                  >
                    認証済み
                  </span>
                </div>
              )}
              {user && (
                <Link
                  to={`/users/view/${product.user.id}`}
                  className="product-cnt"
                >
                  {product.user.name}
                </Link>
              )}
            </li>
          )}
          <li className="product-item">
            <span className="_head">取引場所：</span>
            <p className="product-cnt">{`${product.prefecture} ${product.city} ${product.area} ${product.address}`}</p>
          </li>
          <li className="product-item">
            <span className="_head">掲載期間：</span>
            <p className="product-cnt">
              <>
                {formatDate(product.createdAt)}〜
                {product.period
                  ? formatDate(addDays(product.createdAt, product.period))
                  : ''}
              </>
            </p>
          </li>
          <li className="product-item"></li>
          <li className="product-item">
            <span className="_head">
              <b>製品概要</b>
            </span>
          </li>
          {product.amount > 0 && (
            <li className="product-item">
              <span className="_head">ロット数：</span>
              <p className="product-cnt">{product.amount}</p>
            </li>
          )}
          {product.condition && (
            <li className="product-item">
              <span className="_head">商品の状態：</span>
              <p className="product-cnt">{product.condition}</p>
            </li>
          )}
          <li className="product-item">
            <span className="_head">重さ：</span>
            <p className="product-cnt">
              {!!product.weight && `${product.weight} kg`}
            </p>
          </li>
          <li className="product-item">
            <span className="_head">サイズ：</span>
            <p className="product-cnt">{product.size}</p>
          </li>
          <li className="product-item">
            <span className="_head">年式：</span>
            <p className="product-cnt">{product.year}</p>
          </li>
          <li className="product-item">
            <span className="_head">使用年数：</span>
            <p className="product-cnt">{product.useYear}</p>
          </li>
          <li className="product-item">
            <span className="_head">型番(品番)：</span>
            <p className="product-cnt">{product.modelNumber}</p>
          </li>
        </ul>
      </div>
      {/* 商品画像エリア ※ class,id,連番のdata-keyを維持して下さい。 */}
      <Images images={product.productContent} />
      {/* モーダルエリア ※ class,id,連番のdata-keyを維持して下さい。 */}
    </div>
  );
}
