import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Footer } from '../../blocks/footer';
import { Header } from '../../blocks/header';

import '../../react-styles/css/policy.scoped.css';
import { baseTitle } from '../auth/login';

export function AboutUs() {
  useEffect(() => {
    document.title = `${baseTitle}運営会社`;
  }, []);

  return (
    <>
      <Header />
      <main className="main">
        <section className="aboutus">
          <div className="inner">
            <nav className="links">
              <ul>
                <li className="link">
                  <Link to="/">ホーム</Link>
                </li>
                <li className="link _current">
                  <p>運営会社</p>
                </li>
              </ul>
            </nav>
            <div className="policy">
              <h2 className="policy-tit">運営会社</h2>
              <table className="policy-table">
                <tbody>
                  <tr className="policy-row">
                    <th className="policy-th">運営会社</th>
                    <td className="policy-td">合同会社Amary</td>
                  </tr>
                  <tr className="policy-row">
                    <th className="policy-th">代表者名</th>
                    <td className="policy-td">山田元樹</td>
                  </tr>
                  <tr className="policy-row">
                    <th className="policy-th">所在地</th>
                    <td className="policy-td">
                      〒231-0063
                      <br />
                      神奈川県横浜市中区花咲町3-103-2 アマデウスビル7F
                    </td>
                  </tr>
                  <tr className="policy-row">
                    <th className="policy-th">運営内容</th>
                    <td className="policy-td">
                      建設業における余剰建材のシェアリングサービスの運営
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
