import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Footer } from '../../blocks/footer';
import { Header } from '../../blocks/header';

import '../../react-styles/css/policy.scoped.css';
import { baseTitle } from '../auth/login';

export function Sitemap() {
  useEffect(() => {
    document.title = `${baseTitle}サイトマップ`;
  }, []);

  return (
    <>
      <Header />
      <main className="main">
        <section className="sitemap">
          <div className="inner">
            <nav className="links">
              <ul>
                <li className="link">
                  <Link to="/">ホーム</Link>
                </li>
                <li className="link _current">
                  <p>サイトマップ</p>
                </li>
              </ul>
            </nav>
            <div className="policy-body">
              <h2 className="policy-tit">サイトマップ</h2>
              <ul className="policy-list">
                <li className="policy-item">
                  <h3 className="policy-cnthead">商品を探す</h3>
                  <ul className="policy-cntlist">
                    <li className="policy-part">
                      <Link to="/">トップページ</Link>
                    </li>
                  </ul>
                </li>
                <li className="policy-item">
                  <h3 className="policy-cnthead">会員サービス</h3>
                  <ul className="policy-cntlist">
                    <li className="policy-part">
                      <Link to="/users/login">ログインページ</Link>
                    </li>
                    <li className="policy-part">
                      <Link to="/users/regist">新規会員登録</Link>
                    </li>
                    <li className="policy-part">
                      <Link to="/mypages">マイページ</Link>
                    </li>
                  </ul>
                </li>
                <li className="policy-item">
                  <h3 className="policy-cnthead">ヘルプ＆ガイド</h3>
                  <ul className="policy-cntlist">
                    <li className="policy-part">
                      <Link to="/doc/terms_of_use">利用規約</Link>
                    </li>
                    <li className="policy-part">
                      <Link to="/doc/privacypolicy">プライバシーポリシー</Link>
                    </li>
                    <li className="policy-part">
                      <Link to="/doc/sitepolicy">サイトポリシー</Link>
                    </li>
                    <li className="policy-part">
                      <Link to="/doc/aboutus">運営会社</Link>
                    </li>
                    <li className="policy-part">
                      <Link to="/doc/legalstatement">特定商取引法の表示</Link>
                    </li>
                    <li className="policy-part">
                      <Link to="/doc/guidelines">特定品の出品ガイド</Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
