import { Item } from './item';

import '../../react-styles/css/top.scoped.css';
import { useEffect, useState } from 'react';
import { ApiService } from '../../services/api';
import { TCat } from '../../pages/items/add';

export type TParsedCat = { item: TCat; children: TCat[] };

export function Category() {
  const [cats, setCats] = useState<TParsedCat[] | null>(null);

  useEffect(() => {
    (async function () {
      try {
        const cats: TCat[] = await ApiService.getResources(
          `category/get`,
          null
        );
        const base = cats?.filter((cat) => cat.parent === null);
        const parsed = base.map((item) => {
          const children = cats.filter((cat) => cat.parent === item.id);
          return {
            item,
            children,
          };
        });
        setCats(parsed);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return (
    <section className="category">
      <div className="inner">
        <h2 className="category-tit">カテゴリ一覧</h2>
        <ul className="category-list">
          {cats?.map((item) => (
            <Item item={item} key={item.item.id} />
          ))}
        </ul>
      </div>
    </section>
  );
}
