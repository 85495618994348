import React, { useEffect, useRef, useState } from 'react';
import { ApiService } from '../../services/api';

import '../../react-styles/css/mypage.scoped.css';
import { useBankContext } from '../chat';

const banks = [
  {
    id: 1,
    bank: '三菱UFJ銀行',
  },
  {
    id: 2,
    bank: 'みずほ銀行',
  },
  {
    id: 3,
    bank: 'りそな銀行',
  },
  {
    id: 4,
    bank: '埼玉りそな銀行',
  },
  {
    id: 5,
    bank: '三井住友銀行',
  },
  {
    id: 6,
    bank: 'PayPay銀行',
  },
  {
    id: 7,
    bank: '楽天銀行',
  },
  {
    id: 8,
    bank: 'ゆうちょ銀行',
  },
  {
    id: 99,
    bank: '該当銀行がない',
  },
];

const kids = [
  {
    id: 1,
    type: '普通預金',
  },
  {
    id: 2,
    type: '当座預金',
  },
  {
    id: 3,
    type: '貯蓄預金',
  },
];

const labels: Record<string, string> = {
  'account-number': '口座番号',
  'department-number': '支店コード',
  sei: '口座名義（セイ）',
  mei: '口座名義（メイ）',
};

const messages: Record<string, string> = {
  'account-number': 'は7文字以内で入力してください。',
  'department-number': 'は3文字以内で入力してください。',
  sei: 'カタカナのみで入力してください',
  mei: 'カタカナのみで入力してください',
};

export type TBank = {
  nameBank: string;
  accountNumber: string;
  departmentNumber: string;
  type: string;
  firstName: string;
  lastName: string;
};

export default function Bank() {
  const { bank, updateBank } = useBankContext();
  const [error, setError] = useState('');

  const inputs = useRef<HTMLInputElement[]>([]);

  async function handleSubmit(event: React.SyntheticEvent) {
    setError('');
    try {
      event.preventDefault();
      console.log(inputs.current[0].id);
      let errors = false;
      inputs.current.forEach(
        ({ validity: { patternMismatch, valueMissing }, id }) => {
          if (patternMismatch) {
            setError((error) => error + `${labels[id]}${messages[id]}\n`);
            errors = true;
          }
          if (valueMissing) {
            setError((error) => error + `${labels[id]}の入力は必須です\n`);
            errors = true;
          }
        }
      );
      if (errors) return;
      const target = event.target as typeof event.target & {
        name: { value: string };
        oname: { value: string };
        kids: { value: string };
        code: { value: string };
        number: { value: string };
        sei: { value: string };
        mei: { value: string };
      };

      if (target.name.value === '該当銀行がない' && !target.oname.value) {
        setError('該当する銀行がない場合は、銀行名を入力してください。');
        return;
      }

      const body = {
        departmentNumber: target.code.value,
        accountNumber: target.number.value,
        nameBank: target.oname.value || target.name.value,
        type: target.kids.value,
        lastName: target.mei.value,
        firstName: target.sei.value,
      };
      if (!bank) {
        await ApiService.postEvent('finance/create', null, body);
      } else {
        await ApiService.postEvent('finance/edit', null, body);
      }
      updateBank(body);
      setError('変更しました。');
    } catch (error) {
      console.log(error);
    }
  }

  const [isCustom, setCustom] = useState(false);

  useEffect(() => {
    if (bank) {
      const isCustom = !banks.some((item) => item.bank === bank.nameBank);
      setCustom(isCustom);
    }
  }, [bank]);

  return (
    <section className="workspace-sec corporate _current" id="corporate">
      <h2 className="workspace-tit">銀行口座登録</h2>
      <p style={{ whiteSpace: 'pre-line' }} className="__common_error_msg">
        {error}
      </p>
      <form className="form" onSubmit={handleSubmit} noValidate>
        <div className="form-item">
          <label>銀行名</label>
          <div className="form-cnt col">
            <span className="form-icon">
              <select name="name" id="corpaddress01">
                {banks.map((item) => (
                  <option
                    value={item.bank}
                    key={item.bank}
                    selected={bank?.nameBank === item.bank}
                  >
                    {item.bank}
                  </option>
                ))}
              </select>
            </span>
          </div>
        </div>
        <div className="form-item">
          <label htmlFor="corpname">
            該当する銀行がない場合は、銀行名を入力してください。
          </label>
          <input
            type="text"
            name="oname"
            placeholder="銀行名"
            className="_full"
            id="corpname"
            defaultValue={isCustom ? bank?.nameBank : undefined}
          />
        </div>
        <div className="form-item">
          <label>口座種別</label>
          <div className="form-cnt col">
            <span className="form-icon">
              <select name="kids" id="corpaddress01">
                {kids.map((item) => (
                  <option
                    value={item.id}
                    key={item.type}
                    selected={+(bank?.type || 0) === item.id}
                  >
                    {item.type}
                  </option>
                ))}
              </select>
            </span>
          </div>
        </div>
        <div className="form-item">
          <label htmlFor="department-number">支店コード</label>
          <input
            type="tel"
            name="code"
            placeholder="例)123(数字3桁)"
            id="department-number"
            pattern="[0-9]{3}"
            defaultValue={bank?.departmentNumber}
            required
            ref={(ref) => {
              if (ref) {
                inputs.current[0] = ref;
              }
            }}
          />
        </div>
        <div className="form-item">
          <label htmlFor="acoount-number">口座番号</label>
          <input
            type="tel"
            name="number"
            placeholder="例)1234567(数字7桁)"
            id="account-number"
            pattern="[0-9]{7}"
            defaultValue={bank?.accountNumber}
            required
            ref={(ref) => {
              if (ref) {
                inputs.current[1] = ref;
              }
            }}
          />
        </div>
        <div className="form-item">
          <label htmlFor="sei">口座名義（セイ）</label>
          <input
            type="text"
            name="sei"
            placeholder="例)ヤマダ"
            className="_full"
            id="sei"
            pattern="[\u30A1-\u30F6]*"
            defaultValue={bank?.firstName}
            required
            ref={(ref) => {
              if (ref) {
                inputs.current[2] = ref;
              }
            }}
          />
        </div>
        <div className="form-item">
          <label htmlFor="mei">口座名義（メイ）</label>
          <input
            type="text"
            name="mei"
            placeholder="例)アヤ"
            className="_full"
            id="mei"
            required
            pattern="[\u30A1-\u30F6]*"
            defaultValue={bank?.lastName}
            ref={(ref) => {
              if (ref) {
                inputs.current[3] = ref;
              }
            }}
          />
        </div>
        <div className="form-item submit">
          <button type="submit" className="btn _large">
            上記内容で登録する
          </button>
        </div>
      </form>
    </section>
  );
}
