import { ChangeEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ADDRAjax } from '../../addr';
import { Footer } from '../../blocks/footer';
import { Header } from '../../blocks/header';
import { ApiService } from '../../services/api';
import { TCat } from './add';
import { useImgs } from '../../hooks/useImgs';
import { Img } from '../../blocks/img';
import blank from '../../react-styles/img/common/common-blank_img.png';

import '../../react-styles/css/newitem.scoped.css';
import '../../react-styles/css/about_logged.scoped.css';
import { useUserContext } from '../../App';

const host = process.env.REACT_APP_SERVER_URL || 'http://localhost:8005';

const dd = new ADDRAjax('pref', 'city', 'area');
dd.JSONDATA = '/data';

const year = new Date().getFullYear();

type TCategory = {
  id: number;
  title: string;
};

export type TProduct = {
  categories: TCategory[];
  title: string;
  description: string;
  prefecture: string;
  city: string;
  area: string;
  address: string;
  price: number;
  period?: number;
  condition?: string;
  weight?: number;
  size?: string;
  useYear?: number;
  modelNumber?: number;
  warrantyInformation?: string;
  costOfDelivery?: string;
  costOfDeliveryDescription?: string;
  amount: number;
  paymentMethod: string;
  year: number;
  content: { link: string }[];
  productContent: {
    id: number;
    link: string;
  }[];
  isDeleteEndDate: boolean;
};

export function EditItem() {
  const [item, setItem] = useState<TProduct | null>();
  const [cats, setCats] = useState<TCat[] | null>(null);
  const [level2, setLevel2] = useState<TCat[] | null>(null);
  const [level3, setLevel3] = useState<TCat[] | null>(null);
  const { id } = useParams();

  const { token, company } = useUserContext();

  const [loaded, setLoaded] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async function () {
      const data: TProduct = await ApiService.getResources(
        `product/get/${id}`,
        null
      );
      const cats: TCat[] = await ApiService.getResources(`category/get`, null);
      setItem(data);
      setCats(cats);
      if (data.categories[0]) {
        setLevel2(cats.filter((cat) => cat.parent === data.categories[0].id));
      }
      if (data.categories[1]) {
        setLevel3(cats.filter((cat) => cat.parent === data.categories[1].id));
      }
      setLoaded(true);
    })();
  }, []);

  useEffect(() => {
    if (loaded && item) {
      dd.init();
      dd.setAddress(item.prefecture, item.city, item.area);
    }
  }, [item, loaded]);

  const { imgs, getSrc, onDropHandler, deleteImg, init } = useImgs();

  useEffect(() => {
    if (item) {
      init(item.productContent);
    }
  }, [item]);

  function handleLevel1(e: ChangeEvent<HTMLSelectElement>) {
    const value = e.target.value;
    setLevel2(cats?.filter((cat) => cat.parent === +value) || null);
    setLevel3(null);
  }

  function handleLevel2(e: ChangeEvent<HTMLSelectElement>) {
    const value = e.target.value;
    setLevel3(cats?.filter((cat) => cat.parent === +value) || null);
  }

  const [period, setPeriod] = useState(true);

  function toggleSwitch(e: ChangeEvent<HTMLInputElement>) {
    const checked = e.target.checked;
    setPeriod(!e.target.checked);
    const targetForm = document.getElementById('period');
    const targetCheckbox = document.getElementById('disposal');
    // if (targetForm && targetCheckbox) {
    if (targetForm) {
      (targetForm as HTMLInputElement).disabled = !checked;
      (targetForm as HTMLInputElement).value = '';
      // (targetCheckbox as HTMLInputElement).disabled = !checked;
      (targetCheckbox as HTMLInputElement).checked = false;
    }
  }

  const [error, setError] = useState('');

  async function handleSubmit(event: any) {
    event.preventDefault();
    const {
      lv1Pulldown,
      lv3Pulldown,
      lv2Pulldown,
      name,
      description,
      pref,
      city,
      area,
      address,
      price,
      lots,
      jyoutai,
      weight,
      size,
      madeYear,
      userYear,
      number,
      guaranteeText,
      postage,
      postageBiko,
      period,
      useCard,
      disposal,
      flag,
    } = event.target;

    console.log(flag.checked);

    const body = {
      title: name.value,
      description: description.value,
      prefecture: pref.value,
      city: city.value,
      area: area.value,
      address: address.value,
      price: price.value,
      paymentMethod: useCard.value,
      ...(lots.value && { amount: lots.value }),
      ...(jyoutai.value && { condition: jyoutai.value }),
      ...(weight.value && { weight: weight.value }),
      ...(size.value && { size: size.value }),
      ...(madeYear.value && { year: madeYear.value }),
      ...(userYear.value && { useYear: userYear.value }),
      ...(number.value && { modelNumber: number.value }),
      ...(guaranteeText.value && { warrantyInformation: guaranteeText.value }),
      ...(postage.value && { costOfDelivery: postage.value }),
      ...(postageBiko.value && {
        costOfDeliveryDescription: postageBiko.value,
      }),
      ...(period.value && {
        period: +period.value,
      }),
      ...(!flag.checked && {
        period: 0,
      }),
      isDeleteEndDate: disposal.checked,
      category: [
        {
          id: +lv1Pulldown.value,
        },
        {
          id: +lv2Pulldown.value,
        },
        {
          id: +lv3Pulldown.value,
        },
      ],
    };

    const data = new FormData();
    const indexes = [];
    let i = 0;
    const noEmpty = imgs.filter((img) => img.src !== blank);
    for (const { file } of noEmpty) {
      if (file) {
        data.append('file', file, file.name);
        indexes.push(i);
      }
      i++;
    }

    try {
      setLoading(true);
      const response = await fetch(host + '/upload', {
        method: 'POST',
        body: data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const images: { url: string }[] = await response.json();

      let i = 0;
      const content = noEmpty.map((img) => {
        if (img.file) {
          return {
            link: images[i].url,
          };
          i++;
        }
        return {
          link: img.src,
        };
      });

      body.content = content;

      try {
        await ApiService.postEvent(`product/edit/${id}`, null, body);
        setError('変更しました。');
        scrollTo(0, 0);
      } catch (error: any) {
        if (error?.statusCode === 500) {
          setError('500: Internal server error');
          scrollTo(0, 0);
        }
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }

  if (!loaded || !item || !cats) return null;

  return (
    <>
      <Header />
      <main className="main">
        <section className="newitem">
          <div className="inner">
            <nav className="links">
              <ul>
                <li className="link">
                  <Link to="/">ホーム</Link>
                </li>
                <li className="link">
                  <Link to="/mypages">マイページ</Link>
                </li>
                <li className="link _current">
                  <p>商品編集</p>
                </li>
              </ul>
            </nav>
            <h2 className="newitem-tit">商品編集</h2>
            {error && <p className="__common_error_msg">{error}</p>}
            {/*?php endif; ?*/}
            <form className="form" id="newitem" onSubmit={handleSubmit}>
              <div className="form-part">
                <p className="form-label">
                  カテゴリ<span className="_sub">（大カテゴリは必須）</span>
                </p>
                <div className="form-cnt flex-col">
                  <div className="form-icon">
                    <select
                      className="w-middle"
                      name="lv1"
                      id="lv1Pulldown"
                      onChange={handleLevel1}
                      defaultValue={item.categories[0]?.id}
                    >
                      <option value={0}>大カテゴリ*</option>
                      {cats
                        .filter((cat) => cat.parent === null)
                        .map((cat) => (
                          <option
                            value={cat.id}
                            key={cat.id}
                            // selected={item.categories[0].id === cat.id}
                          >
                            {cat.title}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="form-icon">
                    <select
                      className="w-middle"
                      name="lv2"
                      id="lv2Pulldown"
                      onChange={handleLevel2}
                      defaultValue={item.categories[1]?.id}
                    >
                      <option value={0}>中カテゴリ</option>
                      {level2?.map((cat) => (
                        <option value={cat.id} key={cat.id}>
                          {cat.title}
                        </option>
                      ))}
                    </select>
                    <select
                      id="lv2Pulldown_val"
                      style={{ display: 'none' }}
                      disabled
                    >
                      <option value={0}>中カテゴリ</option>
                    </select>
                  </div>
                  <div className="form-icon">
                    <select
                      className="w-middle"
                      name="lv3"
                      id="lv3Pulldown"
                      defaultValue={item.categories[2]?.id}
                    >
                      <option value="">小カテゴリ</option>
                      {level3?.map((cat) => (
                        <option value={cat.id} key={cat.id}>
                          {cat.title}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="form-part">
                <p className="form-label">
                  商品名<span className="_sub">（必須、25文字以内）</span>
                </p>
                <input
                  type="text"
                  id="title"
                  name="name"
                  maxLength={25}
                  defaultValue={item.title}
                />
              </div>
              <div className="form-part">
                <p className="form-label">
                  商品説明<span className="_sub">（必須、1,000文字以内）</span>
                </p>
                <textarea
                  name="description"
                  id="description"
                  maxLength={1000}
                  cols={30}
                  rows={10}
                  required
                  defaultValue={item.description}
                />
              </div>
              <div className="form-part">
                <p className="form-label">
                  出品エリア
                  <span className="_sub">（必須）</span>
                </p>
                <div className="form-cnt flex-col">
                  <span className="form-icon">
                    <select className="w-middle" name="pref">
                      <option value="都道府県">都道府県*</option>
                    </select>
                  </span>
                  <span className="form-icon">
                    <select className="w-middle" name="city">
                      <option value="郡市区" selected>
                        郡市区
                      </option>
                    </select>
                  </span>
                  {/* 都道府県 の値に合わせて表示内容を絞れるとgood */}
                  <span className="form-icon">
                    <select className="w-middle" name="area">
                      <option value="町村" selected>
                        町村
                      </option>
                    </select>
                  </span>
                  <input
                    type="text"
                    autoComplete="address-level3"
                    name="address"
                    placeholder="番地以下を入力"
                    defaultValue={item.address}
                    maxLength={100}
                    required
                  />
                </div>
              </div>
              <div className="form-part">
                <p className="form-label">
                  支払い方法<span className="_sub">(必須)</span>
                </p>
                <div className="form-cnt flex-col justify-center">
                  <ul className="form-parts">
                    <li className="form-part">
                      <input
                        type="radio"
                        name="useCard"
                        id="paymentOnlyCash"
                        value="0"
                        defaultChecked={item.paymentMethod === '0'}
                      />
                      <label
                        id="p-method-01"
                        className="form-unit"
                        htmlFor="paymentOnlyCash"
                      >
                        現金のみ
                      </label>
                    </li>
                    {company && (
                      <>
                        <li className="form-part">
                          <input
                            type="radio"
                            name="useCard"
                            id="paymentOnlyCard"
                            defaultChecked={item.paymentMethod === '1'}
                            value="1"
                          />
                          <label
                            id="p-method-02"
                            className="form-unit"
                            htmlFor="paymentOnlyCard"
                          >
                            クレジットカードのみ
                          </label>
                        </li>
                        <li className="form-part">
                          <input
                            type="radio"
                            name="useCard"
                            id="paymentBoth"
                            value="2"
                            defaultChecked={item.paymentMethod === '2'}
                          />
                          <label
                            id="p-method-03"
                            className="form-unit"
                            htmlFor="paymentBoth"
                          >
                            両方
                          </label>
                        </li>
                      </>
                    )}
                  </ul>
                  {company ? (
                    <p className="form-attention">
                      クレジットカード利用の場合、価格の
                      <span className="_bold">
                        下限は¥1,000から上限は¥3,000,000まで
                      </span>
                      となります。
                    </p>
                  ) : (
                    <p className="form-attention">
                      法人登録いただくとクレジットカードでの支払いを受けることができます。
                    </p>
                  )}

                  <p className="form-attention">
                    クレジットカード利用の場合、出品価格から出品手数料10%を差し引きますので、手数料を加味した金額設定をお願いします。
                  </p>
                </div>
              </div>
              <div className="form-part">
                <p className="form-label">
                  価格<span className="_sub">（必須、税抜）</span>
                </p>
                <div className="form-cnt flex-row gap-5">
                  <input
                    type="number"
                    className="w-small"
                    id="price"
                    name="price"
                    max={100000000000}
                    min={0}
                    defaultValue={item.price}
                    required
                  />
                  <label htmlFor="price">円</label>
                </div>
              </div>
              <div className="form-part">
                <p className="form-label">掲載期限</p>
                <div className="form-cnt flex-col sp-spacer">
                  <div className="form-item">
                    <input
                      type="checkbox"
                      name="flg"
                      id="flag"
                      data-status=""
                      defaultChecked={!!item.period}
                      onChange={toggleSwitch}
                    />
                    <label className="form-unit" htmlFor="flag">
                      掲載期限の設定をする
                    </label>
                  </div>
                  <div className="form-item form-item-flex align-bottom gap-5">
                    <input
                      type="number"
                      className="w-small"
                      name="period"
                      id="period"
                      defaultValue={item.period}
                      max={100}
                      min={1}
                      disabled={!item.period}
                    />
                    <label htmlFor="period">日間</label>
                  </div>
                  <div className="form-item">
                    <input
                      type="checkbox"
                      name="disposal"
                      id="disposal"
                      disabled={period}
                      defaultChecked={item.isDeleteEndDate}
                    />
                    <label className="form-unit" htmlFor="disposal">
                      掲載期限後は廃棄予定である
                    </label>
                  </div>
                </div>
              </div>
              <div className="form-part">
                <p className="form-label">
                  ロット数
                  <span className="_sub" />
                </p>
                <div className="form-cnt flex-row gap-5">
                  <input
                    type="number"
                    className="w-small"
                    id="lots"
                    name="lots"
                    max={9999}
                    min={0}
                    defaultValue={item.amount}
                  />
                  <label htmlFor="lots">個</label>
                </div>
              </div>
              <div className="form-part">
                <p className="form-label">
                  商品の状態
                  <span className="_sub" />
                </p>
                <span className="form-icon">
                  <select className="w-middle" name="jyoutai" id="jyoutai">
                    <option value="" selected>
                      選択なし
                    </option>
                    <option value="NEW" selected={item.condition === 'NEW'}>
                      新品
                    </option>
                    <option value="USED" selected={item.condition === 'USED'}>
                      中古
                    </option>
                    <option
                      value="NEW_AND_OLD"
                      selected={item.condition === 'NEW_AND_OLD'}
                    >
                      新古
                    </option>
                  </select>
                </span>
              </div>
              <div className="form-part">
                <p className="form-label">
                  重さ
                  <span className="_sub" />
                </p>
                <input
                  id="weight"
                  name="weight"
                  pattern="^\d*\.?\d{1,2}$"
                  defaultValue={item.weight}
                  placeholder="○○kg"
                />
              </div>
              <div className="form-part">
                <p className="form-label">
                  サイズ
                  <span className="_sub" />
                </p>
                <input
                  type="text"
                  id="title"
                  name="size"
                  maxLength={1000}
                  defaultValue={item.size}
                  placeholder="○○cm×○○cm×○○cm"
                />
              </div>
              <div className="form-part">
                <p className="form-label">
                  年式
                  <span className="_sub" />
                </p>
                <div className="form-cnt flex-row gap-5">
                  <select className="w-small" name="madeYear" id="madeYear">
                    <option value="">選択なし</option>
                    {Array.from({ length: 100 }, (_, i) => year - i).map(
                      (year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      )
                    )}
                  </select>
                  <label htmlFor="madeYear">年製</label>
                </div>
              </div>
              <div className="form-part">
                <p className="form-label">
                  使用年数
                  <span className="_sub" />
                </p>
                <div className="form-cnt flex-row gap-5">
                  <input
                    className="w-small"
                    type="number"
                    id="userYear"
                    name="userYear"
                    max={100}
                    min={0}
                    defaultValue={item.useYear}
                    placeholder="yy"
                  />
                  <label htmlFor="userYear">年使用</label>
                </div>
              </div>
              <div className="form-part">
                <p className="form-label">
                  型番(品番)
                  <span className="_sub" />
                </p>
                <input
                  type="text"
                  id="title"
                  name="number"
                  maxLength={100}
                  defaultValue={item.modelNumber}
                  placeholder="000-0000-0000"
                />
              </div>
              <div className="form-part">
                <p className="form-label">
                  保証の内容<span className="_sub">（1,000文字以内）</span>
                </p>
                <textarea
                  name="guaranteeText"
                  // id="description"
                  maxLength={1000}
                  cols={30}
                  rows={10}
                  placeholder="自社商品の保証など"
                  defaultValue={item.warrantyInformation}
                />
              </div>
              <div className="form-part">
                <p className="form-label">
                  送料について
                  <span className="_sub" />
                </p>
                <span className="form-icon">
                  <select className="w-middle" name="postage" id="test">
                    <option value="" selected>
                      選択なし
                    </option>
                    <option value="ON" selected={item.costOfDelivery === 'ON'}>
                      含む
                    </option>
                    <option
                      selected={item.costOfDelivery === 'OFF'}
                      value="OFF"
                    >
                      含まない
                    </option>
                  </select>
                </span>
              </div>
              <div className="form-part">
                <p className="form-label">
                  送料について(備考)
                  <span className="_sub">（1,000文字以内）</span>
                </p>
                <textarea
                  name="postageBiko"
                  // id="description"
                  maxLength={1000}
                  cols={30}
                  rows={10}
                  placeholder="トラックによる配送を含む、含まないなど"
                  defaultValue={item.costOfDeliveryDescription}
                />
              </div>
              <div className="form-part">
                <p className="form-label">
                  商品画像<span className="_sub">（必須、５点まで）</span>
                </p>
                <div className="form-cnt flex-row" id="imgList">
                  <DndProvider backend={HTML5Backend}>
                    <ul className="form-imgs">
                      {imgs.map((img, index) => (
                        <Img
                          key={img.id}
                          img={img}
                          index={index}
                          deleteImg={deleteImg}
                          getSrc={getSrc}
                          onDropHandler={onDropHandler}
                        />
                      ))}
                    </ul>
                  </DndProvider>
                </div>
              </div>
              <p className="form-txt">
                出品禁止の商品・投稿ルールについては、
                <Link to="/doc/terms_of_use" target="_blank">
                  利用規約
                </Link>
                をご確認ください。
              </p>
              <div className="form-part">
                <button type="submit" className="btn _large" form="newitem">
                  商品を掲載する
                </button>
              </div>
            </form>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
