import { useState } from 'react';
import { TData } from '../../../pages/my-pages/buy';
import { Dealing } from './dealing';
import { Done } from './done';

import '../../../react-styles/css/mypage.scoped.css';
import { Link } from 'react-router-dom';

export function Buy({ data: { dealing, done } }: { data: TData }) {
  const [currentTab, setTab] = useState('dealing');

  return (
    <section className="workspace-sec buy _current" id="buy">
      <h2 className="workspace-tit">購入品管理</h2>
      <nav className="tab">
        <ul className="tab-links">
          <li
            className={`tab-link green ${
              currentTab === 'dealing' ? '_current' : ''
            }`}
          >
            <Link
              to="#dealing"
              data-page="buy"
              onClick={() => setTab('dealing')}
            >
              取引中
            </Link>
          </li>
          <li
            className={`tab-link red ${
              currentTab === 'done' ? '_current' : ''
            }`}
          >
            <Link to="#done" data-page="buy" onClick={() => setTab('done')}>
              購入済
            </Link>
          </li>
        </ul>
      </nav>
      <Dealing currentTab={currentTab} items={dealing} />
      {/* 取引中 */}
      <Done currentTab={currentTab} items={done} />
      {/* 購入済 */}
    </section>
  );
}
