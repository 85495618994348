import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Footer } from '../../blocks/footer';
import { Header } from '../../blocks/header';

import '../../react-styles/css/policy.scoped.css';
import { baseTitle } from '../auth/login';

export function TermsOfUse() {
  useEffect(() => {
    document.title = `${baseTitle}利用規約`;
  }, []);

  return (
    <>
      <Header />
      <main className="main">
        <section className="tos">
          <div className="inner">
            <nav className="links">
              <ul>
                <li className="link">
                  <Link to="/">ホーム</Link>
                </li>
                <li className="link _current">
                  <p>利用規約</p>
                </li>
              </ul>
            </nav>
            <div className="policy">
              <h2 className="policy-tit">利用規約</h2>
              <div className="policy-inner">
                <p className="policy-txt _top">
                  この利用規約（以下、「本利用規約」といいます。）は、合同会社Amary（以下、「当社」といいます。）がこのウェブサイト上で提供するサービス（以下、「本サービス」といいます。）の利用条件を定めるものです。
                  <br />
                  当社がユーザー登録を行った本サービスおよび取引の利用者（以下、「ユーザー」といいます。）には、本利用規約に従って本サービスをご利用いただきます。
                </p>
                <section className="policy-sec">
                  <h3 className="policy-sectit">
                    第1条（本サービスの内容と、本利用規約の適用）
                  </h3>
                  <ol className="policy-seclist">
                    <li className="policy-secitem">
                      1.
                      本利用規約は、ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されるものとします。
                    </li>
                    <li className="policy-secitem">
                      2. 本サービスは、オンラインの商品マッチングサービスです。
                      <br />
                      ユーザー間の物品の売買の場や機会を提供するものですが、ユーザー間の売買契約、出品、購入、受け渡し等の保証等に関しては、
                      <br />
                      全て当事者であるユーザー間の自己責任とし、当社は自ら売買を行うものではなく、また売買委託もうけません。
                      <br />
                      当社は、本利用規約中に別段の定めがある場合を除き、売買契約の取消や解約、解除、返品、返金、保守、保証などユーザー間の取引の遂行には一切関与しません。
                    </li>
                    <li className="policy-secitem">
                      3.
                      当社は本サービスに関し、本利用規約のほか、ご利用にあたってのルール等、各種の定め（以下、「個別規定」といいます。）をすることがあります。
                      <br />
                      これら個別規定はその名称のいかんに関わらず、本利用規約の一部を構成するものとします。
                    </li>
                    <li className="policy-secitem">
                      4.
                      本利用規約の規定が前条の個別規定の規定と矛盾する場合には、個別規定において特段の定めなき限り、個別規定の規定が優先されるものとします。
                    </li>
                  </ol>
                </section>
                {/* 01 */}
                <section className="policy-sec">
                  <h3 className="policy-sectit">第2条（利用登録）</h3>
                  <ol className="policy-seclist">
                    <li className="policy-secitem">
                      1.
                      本サービスにおいては、登録希望者が本利用規約に同意の上、当社の定める方法によって利用登録を申請し、当社がこれを所定の審査をし、承認することによって、利用登録が完了するものとします。なお、未成年者のユーザーは、親権者の同意等を得て本サービスに登録した場合でも、本サービスを利用することは認められず、商品の掲載等を行ってはなりません。
                    </li>
                    <li className="policy-secitem _box">
                      <p>
                        2.
                        当社は、利用登録の申請者に以下の事由があると判断した場合、利用登録の申請を承認しないことがあり、その理由については一切の開示義務を負わないものとします。
                      </p>
                      <ol>
                        <li>
                          (1)利用登録の申請に際して虚偽の事項を届け出た場合
                        </li>
                        <li>
                          (2)本利用規約に違反したことがある者からの申請である場合
                        </li>
                        <li>(3)未成年者が親権者の同意等を得ていない場合</li>
                        <li>
                          (4)反社会的勢力等である、または反社会的勢力等との何らかの交流若しくは関与を行っていると当社が判断した場合
                        </li>
                        <li>
                          (5)その他、当社が利用登録を相当でないと判断した場合
                        </li>
                      </ol>
                    </li>
                  </ol>
                </section>
                {/* 02 */}
                <section className="policy-sec">
                  <h3 className="policy-sectit">
                    第3条（ユーザーIDおよびパスワードの管理）
                  </h3>
                  <ol className="policy-seclist">
                    <li className="policy-secitem">
                      1.
                      ユーザーは、自己の責任において、本サービスのユーザーIDおよびパスワードを適切に管理するものとします。
                    </li>
                    <li className="policy-secitem">
                      2.
                      ユーザーは、いかなる場合にも、ユーザーIDおよびパスワードを第三者に譲渡または貸与し、もしくは第三者と共用することはできません。
                      <br />
                      当社は、ユーザーIDとパスワードの組み合わせが登録情報と一致してログインされた場合には、そのユーザーIDを登録しているユーザー自身による利用とみなします。
                    </li>
                    <li className="policy-secitem">
                      3.
                      ユーザーID及びパスワードが第三者によって使用されたことによって生じた損害は、当社に故意又は重大な過失がある場合を除き、
                      <br />
                      当社は一切の責任を負わないものとします。
                    </li>
                    <li className="policy-secitem">
                      4.
                      ユーザー登録内容の変更については、当社所定の書式によって当社に届け出ることにより行うものとします。
                      <br />
                      ユーザーが登録内容変更の届け出を怠ったことに起因して、希望するサービスが提供されない際にはユーザーの自己責任とし、当社は一切の責任を負わないものとします。
                    </li>
                  </ol>
                </section>
                {/* 03 */}
                <section className="policy-sec">
                  <h3 className="policy-sectit">
                    第4条（利用料金および支払方法）
                  </h3>
                  <ol className="policy-seclist">
                    <li className="policy-secitem">
                      1.
                      ユーザーは、本サービスの有料部分の対価として、当社が別途定め、本ウェブサイトに表示する利用料金を、当社が指定する方法により支払うものとします。
                    </li>
                    <li className="policy-secitem">
                      2.
                      ユーザーが利用料金の支払を遅滞した場合には、ユーザーは年14.6％の割合による遅延損害金を支払うものとします。
                    </li>
                  </ol>
                </section>
                {/* 04 */}
                <section className="policy-sec">
                  <h3 className="policy-sectit">第5条（禁止事項）</h3>
                  <p className="policy-sectxt">
                    ユーザーは、本サービスの利用にあたり、以下の行為をしてはなりません。
                    禁止行為を行った場合、又は禁止行為を行うおそれがあると当社が判断した場合、当社はユーザーの掲載内容について削除あるいは非表示を講じることがあります。
                    <br />
                    また、ユーザーの以後の利用を禁止する場合があります。
                    <br />
                    当社は、本条に基づき当社が行った措置に基づきユーザーに生じた損害について一切の責任を負いません。
                  </p>
                  <ol className="policy-seccnts">
                    <li className="policy-seccnt">
                      (1)法令または公序良俗に反する行為
                    </li>
                    <li className="policy-seccnt">(2)犯罪行為に関連する行為</li>
                    <li className="policy-seccnt">
                      (3)本サービスの内容等、本サービスに含まれる著作権、商標権ほか知的財産権を侵害する行為
                    </li>
                    <li className="policy-seccnt">
                      (4)当社、ほかのユーザー、またはその他第三者のサーバーまたはネットワークの機能を破壊したり、妨害したりする行為
                    </li>
                    <li className="policy-seccnt">
                      (5)本サービスによって得られた情報を商業的に利用する行為
                    </li>
                    <li className="policy-seccnt">
                      (6)当社のサービスの運営を妨害するおそれのある行為
                    </li>
                    <li className="policy-seccnt">
                      (7)不正アクセスをし、またはこれを試みる行為
                    </li>
                    <li className="policy-seccnt">
                      (8)他のユーザーに関する個人情報等を収集または蓄積する行為
                    </li>
                    <li className="policy-seccnt">
                      (9)不正な目的を持って本サービスを利用する行為
                    </li>
                    <li className="policy-seccnt">
                      (10)本サービスの他のユーザーまたはその他の第三者に不利益、損害、不快感を与える行為
                    </li>
                    <li className="policy-seccnt">
                      (11)他のユーザーに成りすます行為
                    </li>
                    <li className="policy-seccnt">
                      (12)当社が許諾しない本サービス上での宣伝、広告、勧誘、または営業行為
                    </li>
                    <li className="policy-seccnt">
                      (13)面識のない異性との出会いを目的とした行為
                    </li>
                    <li className="policy-seccnt">
                      (14)当社のサービスに関連して、反社会的勢力に対して直接または間接に利益を供与する行為
                    </li>
                    <li className="policy-seccnt">
                      (15)その他、当社が不適切と判断する行為
                    </li>
                  </ol>
                </section>
                {/* 05 */}
                <section className="policy-sec">
                  <h3 className="policy-sectit">
                    第6条（本サービスの提供の停止、中断等）
                  </h3>
                  <ol className="policy-seclist">
                    <li className="policy-secitem">
                      1.
                      当社は、以下のいずれかの事由があると判断した場合、ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。
                    </li>
                    <li className="policy-secitem _box">
                      <p>
                        2.
                        当社は、本サービスの提供の停止または中断により、ユーザーまたは第三者が被ったいかなる不利益または損害についても、一切の責任を負わないものとします。
                      </p>
                      <ol>
                        <li>
                          (1)本サービスにかかるコンピュータシステムの保守点検または更新、工事を行う場合
                        </li>
                        <li>
                          (2)地震、落雷、火災、停電または天災などの不可抗力により、本サービスの提供が困難となった場合
                        </li>
                        <li>
                          (3)コンピュータまたは通信回線等の設備が事故により停止した場合や障害が発生した場合
                        </li>
                        <li>
                          (4)その他、当社が本サービスの提供が困難と判断した場合
                        </li>
                      </ol>
                    </li>
                  </ol>
                </section>
                {/* 06 */}
                <section className="policy-sec">
                  <h3 className="policy-sectit">
                    第7条（利用制限および登録抹消）
                  </h3>
                  <ol className="policy-seclist">
                    <li className="policy-secitem _box">
                      <p>
                        1.
                        当社は、ユーザーが以下のいずれかに該当する場合には、事前の通知なく、ユーザーに対して、本サービスの全部もしくは一部の利用を制限し、またはユーザーとしての登録を抹消することができるものとします。
                      </p>
                      <ol>
                        <li>(1)本利用規約のいずれかの条項に違反した場合</li>
                        <li>(2)登録事項に虚偽の事実があることが判明した場合</li>
                        <li>(3)料金等の支払債務の不履行があった場合</li>
                        <li>(4)当社からの連絡に対し、一定期間返答がない場合</li>
                        <li>
                          (5)本サービスについて、最終の利用から一定期間利用がない場合
                        </li>
                        <li>
                          (6)その他、当社が本サービスの利用を適当でないと判断した場合
                        </li>
                      </ol>
                    </li>
                    <li className="policy-secitem">
                      2.
                      当社は、本条に基づき当社が行った行為によりユーザーに生じた損害について、一切の責任を負いません。
                    </li>
                  </ol>
                </section>
                {/* 07 */}
                <section className="policy-sec">
                  <h3 className="policy-sectit">第8条（退会）</h3>
                  <p className="policy-sectxt">
                    ユーザーは、当社の定める退会手続により、本サービスから退会できるものとします。
                  </p>
                </section>
                {/* 08 */}
                <section className="policy-sec">
                  <h3 className="policy-sectit">
                    第9条（保証の否認および免責事項）
                  </h3>
                  <ol className="policy-seclist">
                    <li className="policy-secitem">
                      1.
                      当社は、本サービスに事実上または法律上の瑕疵（安全性、信頼性、正確性、完全性、有効性、特定の目的への適合性、セキュリティなどに関する欠陥、エラーやバグ、権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。
                    </li>
                    <li className="policy-secitem">
                      2.
                      当社は、本サービスに起因してユーザーに生じたあらゆる損害について一切の責任を負いません。
                      ただし、本サービスに関する当社とユーザーとの間の契約（本利用規約を含みます。）が消費者契約法に定める消費者契約となる場合、この免責規定は適用されません。
                    </li>
                    <li className="policy-secitem">
                      3.
                      前項ただし書に定める場合であっても、当社は、当社の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害のうち特別な事情から生じた損害（当社またはユーザーが損害発生につき予見し、または予見し得た場合を含みます。）について一切の責任を負いません。
                      <br />
                      また、当社の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害の賠償は、ユーザーから当該損害が発生した月に受領した利用料の額を上限とします。
                    </li>
                    <li className="policy-secitem">
                      4.
                      本サービス利用の際に発生した、電話会社又は各種通信業者より請求される接続に関する費用は、ユーザーが自己責任において管理するものとし、当社は、いかなる保証も行わないものとします。
                    </li>
                    <li className="policy-secitem">
                      5.
                      ユーザーは、本サービスを利用することが、ユーザーに適用のある法令、業界団体の内部規則等に違反するか否かを自己の責任と費用に基づいて調査するものとし、当社は、ユーザーによる本サービスの利用が、ユーザーに適用のある法令、業界団体の内部規則等に適合することを何ら保証するものではありません。
                    </li>
                    <li className="policy-secitem">
                      6.
                      本サービスから他のウェブサイトへのリンク又は他のウェブサイトから本サービスへのリンクが提供されている場合でも、当社は、当該ウェブサイト及びそこから得られる情報に関して如何なる理由に基づいても一切の責任を負わないものとします。
                    </li>
                    <li className="policy-secitem">
                      7.
                      当社は、本サービスに関して、ユーザーと他のユーザーまたは第三者との間において生じた取引、連絡または紛争等について一切責任を負いません。
                    </li>
                  </ol>
                </section>
                {/* 09 */}
                <section className="policy-sec">
                  <h3 className="policy-sectit">
                    第10条（サービス内容の変更等）
                  </h3>
                  <p className="policy-sectxt">
                    当社は、ユーザーに通知することなく、本サービスの内容を変更しまたは本サービスの提供を中止することができるものとし、これによってユーザーに生じた損害について一切の責任を負いません。
                  </p>
                </section>
                {/* 10 */}
                <section className="policy-sec">
                  <h3 className="policy-sectit">第11条（商品の出品）</h3>
                  <ol className="policy-seclist">
                    <li className="policy-secitem">
                      1.
                      出品者ユーザーは、当社所定の手続に従い商品の出品を行うものとします。
                      <br />
                      当社は、特定品の出品ガイドについて基準を設け、審査を行い、出品者ユーザーが基準に合致しない場合には、当該出品方法の利用を制限することができます。
                    </li>
                    <li className="policy-secitem">
                      2. 出品者ユーザーは
                      <Link
                        to="/doc/guidelines"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ガイドの禁止されている出品物
                      </Link>
                      （以下「出品禁止物」といいます。）に記載された商品の出品ができないことについて、予め了承します。
                      <br />
                      出品禁止物に該当する商品を出品した場合は、出品者ユーザーの故意又は過失に関わらず、本規約違反行為とみなします。
                    </li>
                    <li className="policy-secitem">
                      3.
                      ユーザーは、商品を出品する際に、真に売却する意思のない出品、その商品情報だけでは正しく商品を理解できない又は混乱する可能性のある出品、商品説明で十分な説明を行わない出品等を行ってはなりません。
                      <br />
                      また、出品者ユーザーは、出品する商品と関係のない画像等を当該出品情報として掲載してはいけません。
                    </li>
                    <li className="policy-secitem">
                      4.
                      ユーザーは、出品にあたっては、古物営業法、特定商取引に関する法律、不当景品類及び不当表示防止法、不正競争防止法、商標法、著作権法その他の法令を遵守しなければなりません。
                    </li>
                    <li className="policy-secitem">
                      5.
                      出品に関して、当社が本規約又は加盟店規約に違反する又は不適切であると合理的な理由に基づき判断した場合、当社は、その出品やその出品に対して発生していた購入行為等を当社の判断で取消すことができるものとします。
                      <br />
                      本項に基づく措置によってユーザーに生じる損害について、当社の故意又は過失に起因する場合を除き、当社は責任を負わないものとします。
                    </li>
                    <li className="policy-secitem">
                      6.
                      ユーザーの出品等によって、ユーザー及び第三者に生じる損害につき、当社の故意又は過失に起因する場合を除き、当社は責任を負わないものとします。
                    </li>
                  </ol>
                </section>
                {/* 11 */}
                <section className="policy-sec">
                  <h3 className="policy-sectit">第12条（商品の購入）</h3>
                  <ol className="policy-seclist">
                    <li className="policy-secitem">
                      1.
                      ユーザーは、当社の定める手続により購入の意思をもって、注文を行うものとします。
                    </li>
                    <li className="policy-secitem">
                      2.
                      ユーザーは、購入する意思のない注文、当社の判断でいたずら目的と見受けられる注文を行うことはできません。
                    </li>
                    <li className="policy-secitem">
                      3.
                      当社が定める取引開始可能な時間内に、当社の管理するサーバーに商品代金に関するデータ、販売意思データなどの当社所定のデータの到達が確認できなかった場合、注文は無効になるものとし、ユーザーは予めこれを承諾するものとします。
                    </li>
                    <li className="policy-secitem">
                      4.
                      出品者ユーザーは、自らの出品物を購入することはできません。
                      <br />
                      出品を取り下げたい場合は、ユーザーは、当社所定の手続に従って行うものとします。
                    </li>
                    <li className="policy-secitem">
                      5.
                      ユーザーの注文、購入等によって、ユーザー及び第三者に生じる損害につき、当社の故意又は過失に起因する場合を除き、当社は責任を負わないものとします。
                    </li>
                  </ol>
                </section>
                {/* 12 */}
                <section className="policy-sec">
                  <h3 className="policy-sectit">
                    第13条（支払および取引の実行、中止）
                  </h3>
                  <ol className="policy-seclist">
                    <li className="policy-secitem">
                      1.
                      購入者ユーザーが出品された特定の商品の購入完了手続をした時をもって当該商品の売買契約が成立するものとします。
                      <br />
                      出品者ユーザー及び購入者ユーザーは、売買契約に基づき発生した権利義務を第三者に譲渡、担保提供その他の処分することはできないものとします。
                    </li>
                    <li className="policy-secitem">
                      2.
                      購入者ユーザーは、出品者ユーザーに対して、当社所定の手続・方法に従い、商品代金及び配送や手続等その他必要となる費用等（以下、これらを合わせて「商品代金等」といいます。）を支払うものとします。
                    </li>
                    <li className="policy-secitem">
                      3.
                      出品者ユーザーと購入者ユーザーとの間で商品等に関してトラブルが発生した場合、当事者の費用と責任によって解決されるものとします。
                    </li>
                    <li className="policy-secitem">
                      4.
                      当社は、円滑な運営のために必要と判断した場合、出品者ユーザーと購入者ユーザーのトラブルに介入する場合があります。
                    </li>
                    <li className="policy-secitem">
                      5.
                      出品者ユーザーと購入者ユーザーの合意がある場合その他の特段の事情のある場合を除き、商品の購入手続後のキャンセルはできないものとします。
                    </li>
                    <li className="policy-secitem">
                      6.
                      出品者ユーザーの出品した商品が通常有する性質を欠いており、かつそのことについて説明のない場合、商品説明と実際の商品が明らかに異なるものである場合、数量不足の場合、商品の配送時に出品者ユーザーの過失により商品が破損した場合などの事態は出品者ユーザーが責任を負うものとし、出品者ユーザーの責任と費用によって、返金、商品の返品、修理、交換等の対応を行うものとします。
                    </li>
                    <li className="policy-secitem _box">
                      <p>
                        7.
                        当社は、以下の各号の事由が発生した場合、本サービスにおける出品者ユーザーと購入者ユーザーの取引を中止することができます。
                      </p>
                      <ol>
                        <li>
                          (1)当社の定める期限までに、出品者ユーザーが商品の発送手続を行わなかった場合、または購入者ユーザーが商品の引き取り手続きを行わなかった場合
                        </li>
                        <li>
                          (2)出品者ユーザーが商品の発送後、正当な理由なく購入者ユーザー、当社、決済事業者又は収納代行業者からの連絡に応答しない場合または、購入者ユーザーが商品の引き取り後、正当な理由なく出品者ユーザーが連絡に応答しない場合
                        </li>
                        <li>
                          (3)当社が本サービスの個別の取引について取引中止が適当であると判断した場合
                        </li>
                      </ol>
                    </li>
                    <li className="policy-secitem">
                      8.
                      当社は、前項第2号、第3号の場合、一定の対応期間の経過をもって、出品者ユーザーおよび購入者ユーザーが当該商品の所有権を放棄したものとみなします。
                    </li>
                    <li className="policy-secitem">
                      9.
                      取引中止となった出品者ユーザーと購入者ユーザーの取引は、初めから売買契約が成立しなかったものとみなします。
                      <br />
                      この場合、当社は出品者ユーザー、購入者ユーザー、決済事業者又は収納代行業者からの要請に基づき、返金又は返品に関して一定の措置をとることがあります。
                    </li>
                    <li className="policy-secitem">
                      10.
                      購入者ユーザーは、当社が別途オンライン決済の利用を認めた商品では、本サービスが提供するオンラインシステムを利用した決済機能（以下「オンライン決済」とする）を利用して取引を行うことができます。本規約に従ってオンライン決済による取引が完了したと当社が認めた後に、当社より出品者ユーザーに対して支払いをすることにより、ユーザー双方の決済の利便性を向上することが目的です。ユーザーは、本規約に定める手続きに従って取引を行うことを了承し、オンライン決済を利用するものとします。
                    </li>
                    <li className="policy-secitem">
                      11.
                      ユーザーが本規約に従って必要な支払いを行わない場合、もしくは遅延した場合、
                      またはクレジットカードや金融機関の口座の利用が停止された場合には、当社またはオンライン決済代行会社より、当該ユーザーに通知することなく、当該ユーザーによる本サービスの利用を停止することがあります。また、未払いの支払い債務が存在している場合、当社またはオンライン決済代行会社より、未払いの支払い債務の回収を第三者に委託することができるものとします。
                    </li>
                    <li className="policy-secitem">
                      12.
                      購入者ユーザーは、オンライン決済取引により出品者ユーザーより商品を受領した場合、当社所定の手続き・方法により、速やかに受領の完了手続きを行うものとします。また、出品者ユーザーは、購入者ユーザーに対して受け渡し完了手続きを行うものとします。
                    </li>
                    <li className="policy-secitem">
                      13.
                      当社は、出品者ユーザーおよび購入者ユーザーの双方による完了手続きを確認した時点で、当該ユーザー間のオンライン決済取引が完了したものとみなします。
                      ただし、購入者ユーザー、出品者ユーザーのどちらかが完了手続きを行わない場合において、購入者ユーザーへの客観的に受け渡したことが確認できる場合やその他当社の判断で相当と認める場合、オンライン決済が完了したものとみなすことができるものとし、ユーザーはこれを了承した上でオンライン決済取引を行うものとします。
                    </li>
                    <li className="policy-secitem">
                      14.
                      <p>
                        オンライン取引においても、出品者ユーザーと購入者ユーザーの合意がある場合その他の特段の事情のある場合を除き、原則、商品の購入手続後のキャンセルはできないものとします。ただし、オンライン決済取引が完了するまでは、当社所定の手続き申請に従い、オンライン決済取引手続きをキャンセルすることができます。
                      </p>
                      <p>
                        ただし、以下に該当する事由が生じた場合には、当社所定の基準に基づき、当社の判断によりオンライン決済取引がキャンセルしたものとみなし、ユーザー双方は当社の判断を了承するものとします。
                      </p>
                      <ol>
                        <li>
                          (1)出品者ユーザーが、購入者ユーザーに対して、オンライン決済取引完了までに何ら連絡を行わないときから72時間が経過した場合、購入者ユーザーより当社に所定の手続きに従い、オンライン決済取引のキャンセルを申請した場合
                        </li>
                        <li>
                          (2)オンライン決済取引成立から所定の期間内にオンライン決済取引に至らない場合
                        </li>
                      </ol>
                    </li>
                    <li className="policy-secitem">
                      15.
                      オンライン決済取引がキャンセルされた場合には、当社所定の方法に従い、当社より購入者ユーザーに対して返金を行うものとし、出品者ユーザーおよび購入者ユーザーは予め承諾したものとします。なお、返金の際の振込手数料が発生する場合は、購入者ユーザー負担とします。
                      この場合において、すでに商品が購入者ユーザーに受け渡しがされているときでも、出品者ユーザーに対して返品の確認をすることなく、購入者ユーザーに返金を行うことができるものとします。
                    </li>
                    <li className="policy-secitem">
                      16.
                      オンライン決済取引完了後による、ユーザー間の合意やその他自由により、取引が解約された場合、両者間の責任において解決するものとし、当社は何ら責任を負わないものとする。
                    </li>
                  </ol>
                </section>
                {/* 13 */}
                <section className="policy-sec">
                  <h3 className="policy-sectit">
                    第14条（商品売買時の利用料）
                  </h3>
                  <ol className="policy-seclist">
                    <li className="policy-secitem">
                      1.
                      購入者ユーザーは、本サービスの利用に関して、当社に対して、利用料を負担することはありません。
                    </li>
                    <li className="policy-secitem">
                      2.
                      出品者ユーザーは、出品した商品の売買契約が成立した場合、本サービスの利用料として、オンライン決済を利用した場合、商品の商品代金に当社所定の手数料率を乗じた金額の利用料を支払うものとします。
                      <br />
                      ただし、購入者ユーザーが出品者ユーザーに直接支払いを行った場合、当社に対して、利用料を負担することはありません。
                    </li>
                    <li className="policy-secitem">
                      4.
                      出品者ユーザーは、オンライン決済の利用にあたり、当社所定の手続き・方法に従い、当社から出品者ユーザーに対して支払いを行う際の金融機関振込口座等の必要な情報を登録するものとします。
                    </li>
                    <li className="policy-secitem">
                      5.
                      出品者ユーザーは、本サービスにおけるオンライン決済取引を行う場合、オンライン決済取引の成立と同時に出品者ユーザーの購入者ユーザーに対する商品代金等に係る債権を当社に譲渡するものとし、購入者ユーザーはこれを予め承諾するものとします。また、当社が決済事業者又は収納代行業者等との間で締結する決済に関する加盟店契約、収納代行に関する契約その他のサービスの内容に従い、当社がさらに決済事業者又は収納代行業者等に対して購入者ユーザーに対する商品代金等の債権を譲渡することを、出品者ユーザー及び購入者ユーザーは予め承諾するものとします。また、当社と決済事業者又は収納代行業者等との間で商品代金等の債権について、別段の取り決めがある場合、この取り決めに従って商品代金等の債権の帰属が決せられることを出品者ユーザー及び購入者ユーザーは予め承諾するものとします。
                    </li>
                    <li className="policy-secitem">
                      6.
                      出品者ユーザーは、オンライン決済取引完了の場合、販売手数料（商品代金等の合計額の1割）並びに当社の定める振込手数料を負担するものとします。
                    </li>
                    <li className="policy-secitem">
                      7.
                      当社は、出品者ユーザーに対し、オンライン決済取引完了後、商品代金等の債権の譲渡に関する清算金として、商品代金等から販売手数料及び振込手数料等を控除した金額（以下「売上金」といいます。）を出品者ユーザーが当社に届け出た金融機関口座に振り込む方法で支払うものとします。
                    </li>
                    <li className="policy-secitem">
                      8.
                      出品者ユーザーは、オンライン決済取引完了時から180日以内に、当社に対して売上金の振込申請を行うことにより、当社が申請月の翌々月末に支払うものとします。取引が複数回あった場合、申請があった日までの売上金をまとめて支払うものとします。なお、売上金における各取引の詳細についてのお問合せは受け付けず、出品者ユーザーもあらかじめ承諾するものとします。
                    </li>
                    <li className="policy-secitem">
                      9.
                      オンライン決済取引において、売上金の振込申請をした後の振込申請キャンセルはできないものとします。
                    </li>
                    <li className="policy-secitem">
                      10.
                      購入者ユーザーは、決済手段に関して、当社以外に手数料またはその他費用を負担することになる場合、その費用は購入者ユーザーが負担することを予め承諾するものとします。
                    </li>
                    <li className="policy-secitem">
                      11.
                      出品者ユーザーは、振込申請に際して、当社および決済代行会社等に支払い債務（利用料や、遅延損害金など）がある場合、対等額で自動相殺により清算した後、残額を振り込むこととします。
                    </li>
                  </ol>
                </section>
                {/* 14 */}
                <section className="policy-sec">
                  <h3 className="policy-sectit">第15条（利用規約の変更）</h3>
                  <p className="policy-sectxt">
                    当社は、必要と判断した場合には、ユーザーに通知することなくいつでも本利用規約を変更することができるものとします。
                    <br />
                    なお、本利用規約の変更後、本サービスの利用を開始した場合には、当該ユーザーは変更後の規約に同意したものとみなします。
                  </p>
                </section>
                {/* 15 */}
                <section className="policy-sec">
                  <h3 className="policy-sectit">第16条（権利の帰属）</h3>
                  <ol className="policy-seclist">
                    <li className="policy-secitem">
                      1.
                      ユーザー等が本サービスに関連して自らが発信、送信又は掲載、投稿したコンテンツについて適法な権利を有していること、および投稿等が第三者の権利を侵害しないことについて保証するとともに、投稿等に関する一切の責任は、当該ユーザー等が負うものとし、当社は、それらに関して保証しないものとします。
                    </li>
                    <li className="policy-secitem">
                      2.
                      ユーザーは、当社に対し、投稿等について、サービスの宣伝、運営、研究開発及び発表等を目的として、当社及び当社の指定する者が無償にて自由に利用できるものとします。
                    </li>
                  </ol>
                </section>
                {/* 16 */}
                <section className="policy-sec">
                  <h3 className="policy-sectit">第17条（個人情報の取扱い）</h3>
                  <p className="policy-sectxt">
                    当社は、本サービスの利用によって取得する個人情報については、当社「
                    <Link
                      to="/doc/privacypolicy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      プライバシーポリシー
                    </Link>
                    」に従い適切に取り扱うものとします。
                  </p>
                </section>
                {/* 17 */}
                <section className="policy-sec">
                  <h3 className="policy-sectit">第18条（通知または連絡）</h3>
                  <p className="policy-sectxt">
                    ユーザーと当社との間の通知または連絡は、当社の定める方法によって行うものとします。
                    <br />
                    当社は、ユーザーから、当社が別途定める方式に従った変更届け出がない限り、現在登録されている連絡先が有効なものとみなして当該連絡先へ通知または連絡を行い、これらは、発信時にユーザーへ到達したものとみなします。
                  </p>
                </section>
                {/* 18 */}
                <section className="policy-sec">
                  <h3 className="policy-sectit">
                    第19条（権利義務の譲渡の禁止）
                  </h3>
                  <p className="policy-sectxt">
                    ユーザーは、当社の書面による事前の承諾なく、利用契約上の地位または本利用規約に基づく権利もしくは義務を第三者に譲渡し、または担保に供することはできません。
                  </p>
                </section>
                {/* 19 */}
                <section className="policy-sec">
                  <h3 className="policy-sectit">第20条（準拠法・裁判管轄）</h3>
                  <ol className="policy-seclist">
                    <li className="policy-secitem">
                      1. 本利用規約の解釈にあたっては、日本法を準拠法とします。
                    </li>
                    <li className="policy-secitem">
                      2.
                      本サービスに関して紛争が生じた場合には、当社の本店所在地を管轄する裁判所を専属的合意管轄とします。
                    </li>
                  </ol>
                </section>
                {/* 20 */}
                <p className="policy-txt">以上</p>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
