import { useLocation } from 'react-router';
import { ApiService } from '../../../services/api';

import '../../../react-styles/css/mypage_message.scoped.css';
import { Link } from 'react-router-dom';
import { useUserContext } from '../../../App';
import { useChatContext } from '../../chat';
import { useEffect, useState } from 'react';
import { TChatResponse } from '../../../pages/my-pages/message-view';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useParams } from 'react-router';
import { formatDateTime } from '../sell/onsale';

export type TMessage = {
  id: number;
  text: string;
  userId: number;
  userName: string;
  createdAt: string;
};

export function MessageView({ msgs }: { msgs: TChatResponse }) {
  const { state } = useLocation();
  const { user } = useUserContext();
  const { joinRoom, messages, setMessages, sendMessage } = useChatContext();
  const [error, setError] = useState('');

  useEffect(() => {
    setMessages([...msgs.messages].reverse());
    joinRoom(msgs.seller.id, msgs.customer.id, msgs.productId);
    () => {
      // leave room
    };
  }, []);

  const { id } = useParams();

  const [page, setPage] = useState(1);
  const [popup, setPopup] = useState(false);

  async function fetchMoreData() {
    const data: TChatResponse = await ApiService.getResources(
      `chat/get-chat/${id}/${page + 1}`,
      null
    );
    const newMessages = data.messages.reverse();
    const updated = [...newMessages, ...messages];
    setPage(data.page + 1);
    setMessages(updated);
  }

  async function handlePayment() {
    try {
      if (checked === 'card') {
        if (
          confirm('クレジットカードで商品の購入を進めます。よろしいですか?')
        ) {
          const { url } = await ApiService.getResources(
            //@ts-expect-error fix this
            `payment/get-link/${state.id}${
              coupon.coupon ? `/${coupon.coupon}` : ''
            }`,
            null
          );
          if (url) {
            window.location.href = url;
          }
        }
      } else {
        if (confirm('現金で商品の購入を進めます。よろしいですか？')) {
          await ApiService.postEvent('product/while-trading', null, {
            //@ts-expect-error fix this
            productId: state.id,
            isCard: false,
          });
          window.location.reload();
        }
      }
    } catch (error: any) {
      console.log(error);
      if (error?.statusCode === 500) {
        setError('500: Internal server error');
        scrollTo(0, 0);
      }
    }
  }

  const [coupon, setCoupon] = useState<{ message: string; coupon: string }>({
    message: '',
    coupon: '',
  });

  async function handleCoupon() {
    try {
      const data = await ApiService.getResources(`coupon/check/${text}`, null);
      setPopup(true);
      setCoupon({
        message: `クーポンを適用しました。`,
        coupon: text,
      });
    } catch (error: any) {
      console.log(error);
      if (error.status === 400) {
        setPopup(true);
        setCoupon({ message: 'このクーポンは適用できません', coupon: '' });
      } else {
        setError('error');
      }
    }
  }

  function handleSendMessage(e: any) {
    e.preventDefault();
    const { msg } = e.target;
    sendMessage({
      text: msg.value,
      sender: user.id,
      receiver: user.id === msgs.seller.id ? msgs.customer.id : msgs.seller.id,
      productId: msgs.productId,
    });
    e.target.msg.value = '';
  }

  const [checked, setChecked] = useState('');
  const [text, setText] = useState('');

  return (
    <section className="workspace-sec chat" id="chat">
      <div>
        <div className={`modal ${popup ? '_active' : ''}`} id="modal-area">
          <div className="modal-inner _message">
            <span className="chat-cnt">{coupon.message}</span>
            <button
              className="btn_text link-btn chat-cnt"
              onClick={() => setPopup(false)}
            >
              閉じる
            </button>
          </div>
        </div>
        <nav className="links">
          <ul>
            <li className="link">
              <Link to="/">ホーム</Link>
            </li>
            <li className="link">
              <Link to="/mypages">マイページ</Link>
            </li>
            <li className="link">
              <Link to="/mypages/selcmtdealing">メッセージ管理</Link>
            </li>
            <li className="link">
              <Link to={`/items/view/${msgs.productId}`}>
                {msgs.productTitle}
              </Link>
            </li>
            <li className="link _current">
              <p>
                「
                {user.id === msgs.customer.id
                  ? msgs.seller.name
                  : msgs.customer.name}
                」 ：メッセージ
              </p>
            </li>
          </ul>
        </nav>
        <h2 className="workspace-tit">
          <span className="chat-label dealing">取引中</span>「
          {user.id === msgs.customer.id ? msgs.seller.name : msgs.customer.name}
          」 ：メッセージ
        </h2>
        {!!msgs.cancelStatus && msgs.cancelStatus !== 0 && (
          <div className="announce_area">
            {msgs.cancelStatus === 1 ? (
              <span className="announce_message">
                取引の内容について相談がありました。
                <br />
                Amary運営よりご連絡させて頂きますのでお待ちください。
              </span>
            ) : msgs.cancelStatus === 2 ? (
              <span className="announce_message">
                日時を調整し、返品・返金を行なってください
              </span>
            ) : msgs.cancelStatus === 3 ? (
              <span className="announce_message">
                クレジットカードの返金を完了しました。
                <br />
                この取引は終了しています。
              </span>
            ) : msgs.cancelStatus === 4 ? (
              <span className="announce_message">
                クレジットカードの返金を完了しました。
              </span>
            ) : (
              msgs.cancelStatus === 5 && (
                <span className="announce_message">
                  両者同意の元、返品・返金を行なってください
                </span>
              )
            )}
          </div>
        )}
        <ul
          className="chat-list"
          id="scrollableDiv"
          style={{
            display: 'flex',
            flexDirection: 'column-reverse',
          }}
        >
          {/*Put the scroll bar always on the bottom*/}
          <InfiniteScroll
            dataLength={messages.length}
            next={fetchMoreData}
            // style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
            inverse={true} //
            hasMore={page < msgs.pageCount}
            loader={null}
            scrollableTarget="scrollableDiv"
          >
            {messages.map((item) => (
              <li
                className={`chat-item ${
                  user.id === item.userId ? '_opponent' : ''
                }`}
                key={item.id}
              >
                <h3 className="chat-username">
                  {user.id === item.userId ? (
                    `${user.name} (自分)`
                  ) : (
                    <Link to={`/users/view/${item.userId}`}>
                      {item.userName}（相手）
                    </Link>
                  )}
                </h3>
                <div className="chat-cnts">
                  <p className="chat-cnt">{item.text}</p>
                  <p className="chat-post">
                    投稿日：
                    <time dateTime={item.createdAt}>
                      {formatDateTime(item.createdAt)}
                    </time>
                  </p>
                </div>
              </li>
            ))}
          </InfiniteScroll>
        </ul>
      </div>
      <div className="chat-footer">
        <p className="__common_error_msg">{error}</p>
        {(!msgs.withReport ||
          msgs?.cancelStatus === 2 ||
          msgs?.cancelStatus === 4 ||
          msgs?.cancelStatus === 5) && (
          <form className="post" id="comment" onSubmit={handleSendMessage}>
            <div className="post-item">
              <textarea
                name="msg"
                cols={30}
                rows={6}
                placeholder="コメントを投稿する"
                maxLength={255}
                required
              ></textarea>
            </div>
            <div className="post-item submit">
              <input
                type="hidden"
                name="data[Comment][cid]"
                value="<?php echo ($userinfo['cid']); ?>"
              />
              <button
                type="submit"
                form="comment"
                name="comment"
                className="btn _small"
              >
                投稿する
              </button>
            </div>
          </form>
        )}
        {msgs.tradingStatus && (
          <div className="btn-inquiry">
            <Link to={'/items/inquiry'} state={{ id: msgs.chatId }}>
              運営に相談する
            </Link>
          </div>
        )}
        {/* {msgs.seller.id !== user.id && !msgs.tradingStatus && msgs.onSale && (
          <div className="post" id="card">
            <div className="pay_form_area">
              {(msgs.paymentMethodCard === '0' ||
                msgs.paymentMethodCard === '2') && (
                <button onClick={handleSubmit} className="btn _small pay _cash">
                  現金で支払う
                </button>
              )}
              {(msgs.paymentMethodCard === '1' ||
                msgs.paymentMethodCard === '2') && (
                <button
                  onClick={handlePayment}
                  className="btn _small pay _card"
                >
                  カードで支払う
                </button>
              )}
            </div>
          </div>
        )} */}
        {msgs.seller.id !== user.id && !msgs.tradingStatus && msgs.onSale && (
          <div className="payment">
            <h3 className="comment-tit">支払 い 方 法 を 選択 する</h3>
            <div className="choices">
              {(msgs.paymentMethodCard === '0' ||
                msgs.paymentMethodCard === '2') && (
                <div className="payment_area">
                  <input
                    type="radio"
                    name="payment"
                    id="cash"
                    checked={checked === 'cash'}
                    onChange={(e) => setChecked(e.target.value)}
                    value="cash"
                  />
                  <label className="form-unit" htmlFor="cash">
                    現金 で 支払 う
                  </label>
                </div>
              )}
              {(msgs.paymentMethodCard === '1' ||
                msgs.paymentMethodCard === '2') && (
                <div className="payment_area">
                  <input
                    type="radio"
                    name="payment"
                    id="card"
                    checked={checked === 'card'}
                    onChange={(e) => setChecked(e.target.value)}
                    value="card"
                  />
                  <label className="form-unit" htmlFor="card">
                    カードで支払う
                  </label>
                  <div className="coupon_area">
                    <input
                      type="text"
                      className="w-small"
                      name="coupon"
                      value={text}
                      onChange={(e) => setText(e.target.value)}
                      placeholder="クー ポン コー ド を 入力"
                    />
                    <button
                      className="btn _small coupon"
                      onClick={handleCoupon}
                      disabled={!text}
                    >
                      適用
                    </button>
                  </div>
                </div>
              )}
            </div>
            <button
              onClick={handlePayment}
              className="btn _middle payment"
              disabled={!checked}
            >
              この方法で支払う
            </button>
          </div>
        )}
      </div>
    </section>
  );
}
