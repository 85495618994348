import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Footer } from '../../blocks/footer';
import { Header } from '../../blocks/header';

import '../../react-styles/css/policy.scoped.css';
import { baseTitle } from '../auth/login';

export function SitePolicy() {
  useEffect(() => {
    document.title = `${baseTitle}サイトポリシー`;
  }, []);

  return (
    <>
      <Header />
      <main className="main">
        <section className="sitepolicy">
          <div className="inner">
            <nav className="links">
              <ul>
                <li className="link">
                  <Link to="/">ホーム</Link>
                </li>
                <li className="link _current">
                  <p>サイトポリシー</p>
                </li>
              </ul>
            </nav>
            <div className="policy">
              <h2 className="policy-tit">サイトポリシー</h2>
              <ul className="policy-list">
                <li className="policy-item">
                  <h3 className="policy-head">会社の使命</h3>
                  <p className="policy-cnt">
                    合同会社Amary（以下「当社」という）は、アマリー(以下「本サイト」という)を通して建設業界の余剰在庫の解消をはかり、
                    <br />
                    持続可能な開発目標に取り組むとともに、不動産テックを通じて業界のDX推進をはかることを使命としています。
                  </p>
                </li>
                <li className="policy-item">
                  <h3 className="policy-head">社会的責任</h3>
                  <p className="policy-cnt">
                    当社は、良識ある社会人としての自覚をもち社会の要請にこたえ、高い倫理感をもち、責任のある行動をします。
                  </p>
                </li>
                <li className="policy-item">
                  <h3 className="policy-head">法令遵守</h3>
                  <p className="policy-cnt">
                    当社は、企業活動に関係する法令および条例、通達、各種規則等につき、その趣旨、目的を充分に理解し、ルールを遵守します。
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
