import { TProfile } from '../../pages/profile';
import '../../react-styles/css/profile.scoped.css';
import { ReactComponent as ReactLogo } from '../../react-styles/img/common/verified.svg';

const daysList = [
  '月曜日',
  '火曜日',
  '水曜日',
  '木曜日',
  '金曜日',
  '土曜日',
  '日曜日',
  '祝祭日',
];

const getDays = (days: string) => {
  let result = '';
  const index = Array.from(days).findIndex((i) => i === '1');
  for (let i = 0; i < days.length; i++) {
    if (days[i] === '1') {
      result += (i === index ? '' : ', ') + daysList[i];
    }
  }
  return result;
};

export function Profile({ user, count }: { user: TProfile; count: number }) {
  return (
    <div className="profile">
      <div className="profile-inner">
        <div className="profile-info left">
          <h2 className="profile-name">
            <span className="_corp">{user.company?.title}</span>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {user.certification && (
                <>
                  <ReactLogo />
                  <span
                    style={{
                      margin: '0 10px 0 3px',
                      color: '#FFC215',
                      fontSize: '12px',
                    }}
                  >
                    認証済み
                  </span>
                </>
              )}
              <span className="_name">{user.name}</span>
            </div>
          </h2>
          <ul className="profile-list">
            <li className="profile-item">
              <span className="profile-itemhead">お気に入りされた商品</span>
              <span className="profile-itembody">{user.favoriteCount}</span>
            </li>
            <li className="profile-item">
              <span className="profile-itemhead">出品した商品</span>
              <span className="profile-itembody">{count}</span>
            </li>
          </ul>
        </div>
        <div className="profile-info right">
          <div className="profile-cnt top">
            <h3 className="profile-thead">ユーザープロフィール</h3>
            <table className="profile-table">
              <tbody>
                <tr className="profile-row">
                  <th className="profile-th">性別</th>
                  <td className="profile-td">{user.sex}</td>
                </tr>
                <tr className="profile-row">
                  <th className="profile-th">お住まいの地域</th>
                  <td className="profile-td">
                    {user.company &&
                      `${user.company?.prefecture} ${user.company?.city}`}
                  </td>
                </tr>
                <tr className="profile-row">
                  <th className="profile-th">自己紹介</th>
                  <td className="profile-td">{user.company?.description}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="profile-cnt bottom">
            <h3 className="profile-thead">法人プロフィール</h3>
            <table className="profile-table">
              <tbody>
                <tr className="profile-row">
                  <th className="profile-th">会社名</th>
                  <td className="profile-td">{user.company?.title}</td>
                </tr>
                <tr className="profile-row">
                  <th className="profile-th">会社住所</th>
                  <td className="profile-td">
                    {user.company &&
                      `${user.company?.prefecture} ${user.company?.city} ${user.company?.area} ${user.company?.address}`}
                  </td>
                </tr>
                {/* <tr className="profile-row">
                  <th className="profile-th">電話番号</th>
                  <td className="profile-td">000-0000-0000</td>
                </tr> */}
                <tr className="profile-row">
                  <th className="profile-th">営業時間</th>
                  <td className="profile-td">
                    {user.company?.openingHoursFrom}〜
                    {user.company?.openingHoursTo}
                  </td>
                </tr>
                <tr className="profile-row">
                  <th className="profile-th">定休日</th>
                  <td className="profile-td">
                    {user.company && getDays(user.company.weekend)}
                  </td>
                </tr>
                <tr className="profile-row">
                  <th className="profile-th">営業区分</th>
                  <td className="profile-td">{user.company?.industry}</td>
                </tr>
                <tr className="profile-row">
                  <th className="profile-th">備考</th>
                  <td className="profile-td">{user.company?.description}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
