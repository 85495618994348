import { Link } from 'react-router-dom';
import { Item, TChat } from './sell-dealing';

import '../../../react-styles/css/mypage.scoped.css';

export function SellDealed({ items }: { items: TChat[] }) {
  return (
    <section className="workspace-sec message _current" id="message">
      <h2 className="workspace-tit">メッセージ管理</h2>
      <nav className="tab">
        <ul className="tab-links">
          <li className="tab-link yellow _current">
            <Link to="/mypages/selcmtdealing" data-page="message">
              売りたい
            </Link>
          </li>
          <li className="tab-link yellow">
            <Link to="/mypages/buycmtdealing" data-page="message">
              買いたい
            </Link>
          </li>
        </ul>
      </nav>
      <div
        className="workspace-cnts message-body onsale _current"
        data-tab="onsale"
      >
        <div className="label">
          <p className="label-txt">ステータスで絞り込む：</p>
          <ul className="label-list">
            <li className="label-item dealing">
              <Link to="/mypages/selcmtdealing">取引中</Link>
            </li>
            <li className="label-item done _active">
              <Link to="/mypages/selcmtdealed">売却済</Link>
            </li>
          </ul>
        </div>
        <ul className="message-list">
          {items.map((item) => (
            <Item key={item.id} type="done" item={item} user="customer" />
          ))}
        </ul>
      </div>
      {/* 売りたい */}
    </section>
  );
}
