import { Link } from 'react-router-dom';
import { Item, TItem } from '../sell/onsale';

import '../../../react-styles/css/mypage.scoped.css';
import { ApiService } from '../../../services/api';

export function Dealing({
  currentTab,
  items,
}: {
  currentTab: string;
  items: TItem[];
}) {
  async function handleDone(id: number) {
    if (confirm('商品の受け取りを完了します。よろしいですか？')) {
      try {
        await ApiService.postEvent(`product/received/${id}`, null);
        window.location.reload();
      } catch (error) {
        console.log(error);
      }
    }
  }

  return (
    <div
      className={`workspace-cnts buy-body dealing ${
        currentTab === 'dealing' ? '_current' : ''
      }`}
      data-tab="dealing"
    >
      <ul className="buy-list">
        {items.map((item) => (
          <Item key={item.id} item={item}>
            <li className="item-part">
              <p className="item-seller">投稿者：</p>
              <Link to={`/users/view/${item.seller.id}`}>
                {item.companyName} {item.seller.name}
              </Link>
            </li>
            {item.tradingStatus === 'SENT' && (
              <li className="item-part">
                <li className="item-part">
                  <button
                    onClick={() => handleDone(item.id)}
                    className="btn _done"
                  >
                    受け取り完了
                  </button>
                </li>
              </li>
            )}
          </Item>
        ))}
      </ul>
    </div>
  );
}
