import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { PaginatorContext } from '.';
import { Category } from '../../blocks/category';
import { Footer } from '../../blocks/footer';
import { Header } from '../../blocks/header';
import { MV } from '../../blocks/mv';
import { News } from '../../blocks/news';
import { TItem } from '../../blocks/news/news-item';
import { ApiService } from '../../services/api';

export type TData = {
  data: TItem[];
  products: TItem[];
  page: number;
  pageCount: number;
  title?: string;
};

export function Search() {
  const [searchResult, setSearchResult] = useState<TData | null>(null);
  const [searchParams] = useSearchParams();
  const [currentPage, setPage] = useState(+(searchParams.get('page') || 1));
  const { id } = useParams();

  useEffect(() => {
    document.title = 'アマリー | 検索';
  }, []);

  const search = searchParams.get('query');

  async function searchData() {
    try {
      if (id) {
        const data = await ApiService.getResources(
          `product/get-products-category/${id}/${
            searchParams.get('page') || 1
          }`,
          null
        );
        setSearchResult(data);
      } else if (search) {
        const data = await ApiService.postEvent(
          `product/search/${searchParams.get('page') || 1}`,
          null,
          {
            search,
          }
        );
        setSearchResult(data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    searchData();
    setPage(+(searchParams.get('page') || 1));
  }, [searchParams, id]);

  if (!searchResult) return null;

  return (
    <>
      <Header />
      <main className="main">
        <MV />
        <PaginatorContext.Provider
          value={{
            currentPage,
            pageCount: searchResult.pageCount,
            setPage: (page: number) => setPage(page),
          }}
        >
          <News data={searchResult} isSearch q={search} isCat={!!id} />
        </PaginatorContext.Provider>
        <Category />
      </main>
      <Footer />
    </>
  );
}
