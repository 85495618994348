import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Footer } from '../../../blocks/footer';
import { Header } from '../../../blocks/header';
import eyevisi from '../../../react-styles/img/common/common-icon_eyevisi.svg';
import eyehide from '../../../react-styles/img/common/common-icon_eyehide.svg';
import { ApiService } from '../../../services/api';

import '../../../react-styles/css/register.scoped.css';
import { Link } from 'react-router-dom';
import { baseTitle } from '../login';

export function Regist() {
  const navigate = useNavigate();
  const [err, setErr] = useState('');

  useEffect(() => {
    document.title = `${baseTitle}新規会員登録`;
  }, []);

  async function handleSubmit(event: any) {
    try {
      event.preventDefault();
      const { name, email, password, accept } = event.target.elements;
      if (password[0].value !== password[1].value) {
        setErr('同じパスワードを入力してください。');
        return;
      }

      const body = {
        name: name.value,
        email: email.value,
        password: password[0].value,
      };

      const data = await ApiService.postEvent('auth/register', null, body);
      localStorage.setItem('token', data.accessToken);
      navigate('/users/end', { state: { email: email.value } });
    } catch (error: any) {
      console.log(error);
      if (error?.status === 409) {
        setErr('既に登録されているユーザーです');
      }
    }
  }

  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  return (
    <>
      <Header />
      <main className="main">
        <section className="register">
          <div className="inner">
            <nav className="links">
              <ul>
                <li className="link">
                  <Link to="/">ホーム</Link>
                </li>
                <li className="link _current">
                  <p>新規会員登録</p>
                </li>
              </ul>
            </nav>
            <h2 className="register-tit">新規会員登録</h2>
            {err && <p className="__common_error_msg">{err}</p>}
            <form onSubmit={handleSubmit} id="register">
              <div className="register-items _top">
                <div className="register-item">
                  <label htmlFor="name">
                    ユーザー名（10文字以内）<span className="_red">*</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="山田太郎"
                    maxLength={10}
                    required
                    autoComplete="new-password"
                  />
                </div>
                <div className="register-item">
                  <label htmlFor="email">
                    メールアドレス<span className="_red">*</span>
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="amary@amary.jp"
                    required
                    autoComplete="new-password"
                  />
                </div>
                <div className="register-item">
                  <label htmlFor="password">
                    パスワード（半角の数字・英字を含む 8文字〜20文字）
                    <span className="_red">*</span>
                  </label>
                  <span className="visibility">
                    <input
                      type={show ? 'text' : 'password'}
                      name="password"
                      id="password"
                      pattern="(?:(?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*"
                      minLength={8}
                      maxLength={20}
                      required
                      autoComplete="new-password"
                      onInvalid={() =>
                        setErr(
                          'パスワードは英大文字、英小文字、数字を全て含むものを設定してください'
                        )
                      }
                      onInput={() => setErr('')}
                    />
                    <img
                      onClick={() => setShow((state) => !state)}
                      src={!show ? eyevisi : eyehide}
                      alt="表示"
                      width="18"
                      height="18"
                      loading="lazy"
                    />
                  </span>
                </div>
                <div className="register-item">
                  <label>
                    パスワード（確認のため再度入力）
                    <span className="_red">*</span>
                  </label>
                  <span className="visibility">
                    <input
                      type={showConfirm ? 'text' : 'password'}
                      name="password"
                      id="confirm"
                      pattern="(?:(?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*"
                      minLength={8}
                      maxLength={100}
                      required
                      onInvalid={() =>
                        setErr(
                          'パスワードは英大文字、英小文字、数字を全て含むものを設定してください'
                        )
                      }
                      onInput={() => setErr('')}
                    />
                    <img
                      onClick={() => setShowConfirm((state) => !state)}
                      src={!showConfirm ? eyevisi : eyehide}
                      alt="表示"
                      width="18"
                      height="18"
                      loading="lazy"
                    />
                  </span>
                </div>
              </div>
              <div className="register-items _bottom">
                <div className="register-item">
                  <div className="register-accept">
                    <input type="checkbox" name="accept" id="accept" required />
                    <label htmlFor="accept">
                      アマリーの
                      <Link
                        to="/doc/terms_of_use"
                        className="register-link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        利用規約
                      </Link>
                      、
                      <Link
                        to="/doc/privacypolicy"
                        className="register-link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        プライバシーポリシー
                      </Link>
                      に同意します。
                    </label>
                  </div>
                  <div className="register-submit">
                    <button
                      type="submit"
                      form="register"
                      className="btn _bold"
                      id="submit"
                    >
                      無料会員登録
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
