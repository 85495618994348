import { formatDateTime, Item, TItem } from './onsale';

import '../../../react-styles/css/mypage.scoped.css';
import { Link } from 'react-router-dom';

export function Done({
  currentTab,
  items,
}: {
  currentTab: string;
  items: TItem[];
}) {
  return (
    <div
      className={`workspace-cnts sell-body done ${
        currentTab === 'done' ? '_current' : ''
      }`}
      data-tab="done"
    >
      <ul className="sell-list">
        {items.map((item) => (
          <Item key={item.id} item={item} isDone>
            {!item.isCanceled ? (
              <>
                <li className="item-part">
                  <p className="item-buyer">購入者：</p>
                  <p>
                    <Link to={`/users/view/${item.customer.id}`}>
                      {item.companyName} {item.customer.name}
                    </Link>
                  </p>
                </li>
                <li className="item-part">
                  <p className="item-date">成約日：</p>
                  <time dateTime="">{formatDateTime(item.tradingCreated)}</time>
                </li>
              </>
            ) : (
              <li className="item-part">
                <Link to={`/items/edit/${item.id}`} className="item-edit">
                  再掲載にする
                </Link>
              </li>
            )}
          </Item>
        ))}
        {/* 売却済商品 */}
      </ul>
    </div>
  );
}
