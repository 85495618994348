import { useEffect, useState } from 'react';
import { Footer } from '../../blocks/footer';
import { Header } from '../../blocks/header';
import { Sidebar } from '../../blocks/sidebar';
import { Workspace } from '../../blocks/workspace';
import { TItem } from '../../blocks/workspace/sell/onsale';
import { Sell } from '../../blocks/workspace/sell/sell';
import { ApiService } from '../../services/api';

import '../../react-styles/css/mypage.scoped.css';
import { useSearchParams } from 'react-router-dom';

export type TData = {
  onsale: TItem[];
  dealing: TItem[];
  done: TItem[];
};

export function MyPages() {
  const [data, setData] = useState<TData>();
  const [show, setShow] = useState(false);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const sid = searchParams.get('SID');
    const fuka = searchParams.get('FUKA');
    if (sid && fuka) {
      (async function () {
        try {
          await ApiService.postEvent('payment/check', null, {
            sid,
            fuka,
          });
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, [searchParams]);

  useEffect(() => {
    (async function () {
      try {
        const onsale = await ApiService.getResources(
          'product/get-my-products',
          null
        );
        const dealing = await ApiService.getResources(
          'product/get-trading-seller',
          null
        );
        const done = await ApiService.getResources(
          'product/get-sales-seller',
          null
        );
        setData({
          onsale,
          dealing,
          done,
        });
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  if (!data) return null;

  return (
    <>
      <Header show={show} setShow={setShow} mypage />
      <main className="main">
        <Sidebar show={show} mypage onClose={() => setShow(false)} />
        <Workspace>
          <Sell data={data} />
        </Workspace>
      </main>
      <Footer />
    </>
  );
}
